import { Helmet } from "react-helmet";
import React, { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router";

import { Form, Card, InputNumber, Switch } from "antd";
import Button from "../../common/components/Button/Button";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { updatePortal, adminGetPortal } from "../../actions/portals";
import { uploadLogo, clearFiles } from "../../actions/files";

function PortalAdminEdit(props) {
  let { portalId } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const [portal, setPortal] = useState(null);
  const [emailsInitialValues, setEmailsInitialValues] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);

  const [showExistingLogo, setShowExistingLogo] = useState(true);
  const hasCustomRatesOn = Form.useWatch("hasCustomRates", form);
  const hasWhiteGloveRatesOn = Form.useWatch("hasWhiteGloveRates", form);

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  useEffect(() => {
    dispatch(adminGetPortal(portalId));
  }, [dispatch, portalId]);

  useEffect(() => {
    if (props.portals?.portalToEdit) {
      setPortal(props.portals.portalToEdit);

      let emailValues = props.portals.portalToEdit?.emails?.map((e) => {
        return {
          email: e.email,
          emailPickUp: e.pickup || false,
          emailDelivery: e.delivery || false,
        };
      });
      setEmailsInitialValues(emailValues);
    }
  }, [props.portals]);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = (file) => {
    setShowExistingLogo(false);
    setPreviewImage(file.thumbUrl);
    setPreviewVisible(true);
  };

  const handleUpload = ({ fileList }) => {
    setFileList({ fileList });

    let uploadInput = fileList[0].originFileObj;
    let file = uploadInput;
    let fileParts = file.name.split(".");
    props.dispatch(uploadLogo(file, fileParts));
  };

  const onFinish = (values) => {
    values.portalId = portal._id;
    props.dispatch(
      updatePortal(values, null, () => {
        dispatch(adminGetPortal(portalId));
        history.push("/portals");
      })
    );
  };

  if (portal) {
    return (
      <div>
        <Helmet>
          <title>Update Portal</title>
        </Helmet>
        <div className="view-portals view-content-container">
          <Card
            title="Update Portal"
            bordered={false}
            style={{ width: "100%" }}
          >
            <Form
              form={form}
              layout="horizontal"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 10 }}
              initialValues={{
                companyTariff: portal.companyTariff,
                portalAdminDiscount: portal.portalAdminDiscount,
                companyTariffIsPercent: portal.companyTariffIsPercent || false,
                companyTariffOpenTransport: portal.companyTariffOpenTransport,
                companyTariffEnclosedTransport:
                  portal.companyTariffEnclosedTransport,
              }}
              className="portal-form"
              onFinish={onFinish}
            >
              {!portal.hasVariableCompanyTariff && (
                <Fragment>
                  <Form.Item label="Company Tariff" name="companyTariff">
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    label="Company Tariff is Percent"
                    name="companyTariffIsPercent"
                    tooltip="Disable if company tariff is a fixed dollar amount"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Fragment>
              )}

              {portal.hasVariableCompanyTariff && (
                <Fragment>
                  <Form.Item
                    label="Company Tariff (Open)"
                    name="companyTariffOpenTransport"
                  >
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    label="Company Tariff (Enclosed)"
                    name="companyTariffEnclosedTransport"
                  >
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    label="Company Tariff is Percent"
                    name="companyTariffIsPercent"
                    tooltip="Disable if company tariff is a fixed dollar amount"
                    valuePropName="checked"
                  >
                    <Switch />
                  </Form.Item>
                </Fragment>
              )}

              {portal.displayDiscountOption && (
                <Form.Item
                  label="Discount Amount"
                  name="portalAdminDiscount"
                  tooltip="Per Vehicle, Applied to Company Tariff"
                >
                  <InputNumber />
                </Form.Item>
              )}

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button label="Submit" style="dark" type="submit" />
              </div>
            </Form>
          </Card>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    files: state.files,
    portals: state.portals,
  };
};

export default connect(mapStateToProps)(PortalAdminEdit);
