import {
  UPLOAD_FILE,
  UPLOAD_LOGO,
  CLEAR_FILES,
  UNAUTHORIZE_USER,
  FILE_ERROR,
  CLEAR_FILE_ERROR,
} from "../actions/types";

export default (state = { files: [], logo: null, error: null }, action) => {
  switch (action.type) {
    case UPLOAD_LOGO:
      return { ...state, logo: action.logo };
    case UPLOAD_FILE:
      return { ...state, files: [...state.files, action.file] };
    case CLEAR_FILES:
      return { ...state, files: [] };
    case UNAUTHORIZE_USER:
      return { ...state, files: [], logo: null };
    case FILE_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_FILE_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
};
