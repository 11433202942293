import {
  GET_SETTINGS,
  UPDATE_SETTINGS,
  GET_MAKES,
  FILTER_PORTAL,
  SETTINGS_ERROR,
  CLEAR_SETTINGS_ERROR,
  UNAUTHORIZE_USER,
} from "../actions/types";

export default (state = { settings: null, makes: [], error: null }, action) => {
  switch (action.type) {
    case FILTER_PORTAL:
      return {
        ...state,
        portal: action.portal.portalId,
        portalName: action.portal.portalName,
      };
    case GET_SETTINGS:
      return { ...state, settings: action.settings };
    case UPDATE_SETTINGS:
      return { ...state, settings: action.settings };
    case GET_MAKES:
      return { ...state, makes: action.makes };
    case SETTINGS_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_SETTINGS_ERROR:
      return { ...state, error: "" };
    case UNAUTHORIZE_USER:
      return { settings: null, makes: [], error: null };
    default:
      return state;
  }
};
