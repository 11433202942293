import React, { useState, useEffect } from "react";
import { Button, Space, Table, Input, DatePicker } from "antd";

import { DateTime } from "luxon";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import Alert from "../../common/components/Alert/Alert";
import Spinner from "../../common/components/Spinner/Spinner.js";
import { getOrders, updateOrder, deleteOrder } from "../../actions/orders";

function CODTable({ settings, orders, portal, portals }) {
  const dispatch = useDispatch();

  const { Search } = Input;
  const { RangePicker } = DatePicker;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState(null);
  const [showPaidOrders, setShowPaidOrders] = useState(false);
  const [unpaidOrders, setUnpaidOrders] = useState([]);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [dateRange, setDateRange] = useState([null, null]);
  const [searchText, setSearchText] = useState("");
  const [searchTerm, setSearchTerm] = useState(null);

  const [
    showCancelOrderConfirmation,
    setShowCancelOrderConfirmation,
  ] = useState(null);

  // Sorting & filtering handled by API
  useEffect(() => {
    let { companyName, orderTableStatus } = filteredInfo;

    let criteria = {
      limit: 1000,
      skip: 0,
      portalId: settings.portal !== "all" ? settings.portal : null,
      cod: true,
      sortBy: {
        key: "pickupScheduledAt",
        desc: false,
      },
    };

    if (sortedInfo && sortedInfo.field) {
      criteria = {
        ...criteria,
        sortBy: {
          key: sortedInfo.field,
          desc: sortedInfo.order === "ascend" ? false : true,
        },
      };
    } else {
      criteria = {
        ...criteria,
        sortBy: {
          key: "uniqueId",
          desc: true,
        },
      };
    }

    if (companyName) {
      criteria = { ...criteria, portals: companyName };
    }

    if (orderTableStatus) {
      criteria = { ...criteria, orderTableStatus };
    }

    if (startDate && endDate) {
      criteria = { ...criteria, dateStart: startDate, dateEnd: endDate };
    }

    if (searchText) {
      criteria = { ...criteria, searchText: searchTerm };
    }

    if (showPaidOrders) {
      criteria = { ...criteria, paid: true };
    } else {
      criteria = { ...criteria, paid: false };
    }
    dispatch(getOrders(criteria));
  }, [
    filteredInfo,
    sortedInfo,
    startDate,
    endDate,
    searchTerm,
    portal.settings,
    showPaidOrders,
  ]);

  useEffect(() => {
    if (orders) {
      setData(orders.orders);
    }
  }, [orders]);

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  // Editing date range
  const handleDateChange = (dates) => {
    setDateRange(dates);
  };

  // Submitting date range
  const handleSubmitDates = () => {
    if (dateRange[0] && dateRange[1]) {
      setStartDate(dateRange[0].toISOString());
      setEndDate(dateRange[1].toISOString());
    }
  };

  const handleMarkAsPaid = (id, paid) => {
    dispatch(
      updateOrder(
        {
          paid,
          orderId: id,
        },
        () => {
          setUnpaidOrders(
            unpaidOrders.filter((order) => {
              return order._id !== id;
            })
          );
        }
      )
    );
  };

  const handleReset = () => {
    setFilteredInfo({});
    setSearchText("");
    setSortedInfo({});
    setDateRange([null, null]);
    setStartDate(null);
    setEndDate(null);
  };

  const handleTableChange = (pagination, filters, sorter, extra) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "uniqueId",
      key: "uniqueId",
      sorter: true,
      sortOrder: sortedInfo.field === "uniqueId" ? sortedInfo.order : null,
      render: (text, record) => (
        <Link to={`/order/${record._id}`}>{record.uniqueId}</Link>
      ),
    },
    {
      title: "Company",
      dataIndex: "companyName",
      key: "companyName",
      sorter: false,
      filters: portals.map((p) => {
        return {
          text: p.companyName,
          value: p._id,
        };
      }),
      filteredValue: filteredInfo.companyName,
      sortOrder: sortedInfo.field === "companyName" ? sortedInfo.order : null,
    },
    {
      title: "Payment Status",
      dataIndex: "paid",
      key: "paid",
      render: (value, record) => {
        return (
          <div>
            <button
              className={record.paid ? "red paid" : "paid"}
              onClick={() => handleMarkAsPaid(record._id, !record.paid)}
            >
              {record.paid ? "Mark Unpaid" : "Mark Paid"}
            </button>
            {record.status === "Pending" && (
              <button
                style={{ color: "white", marginTop: "5px" }}
                className="red"
                onClick={() => setShowCancelOrderConfirmation(record._id)}
              >
                Cancel
              </button>
            )}
          </div>
        );
      },
    },

    {
      title: "Reg #",
      dataIndex: "reg",
      key: "reg",
      filteredValue: filteredInfo.reg || null,
      filterSearch: true,
    },
    {
      title: "Status",
      dataIndex: "orderTableStatus",
      key: "orderTableStatus",
      sorter: false,
      filters: [
        {
          text: "New",
          value: "New",
        },
        {
          text: "Picked Up",
          value: "Picked Up",
        },
        {
          text: "Delivered",
          value: "Delivered",
        },
      ],
      filteredValue: filteredInfo.orderTableStatus || null,
      sortOrder:
        sortedInfo.field === "orderTableStatus" ? sortedInfo.order : null,
    },
    {
      title: "Customer",
      dataIndex: "orderTableCustomer",
      key: "orderTableCustomer",
      filterSearch: null,
      filterIcon: null,
      filterDropdown: null,
      filteredValue: filteredInfo.orderTableCustomer || null,
    },
    {
      title: "Est. Pickup",
      dataIndex: "orderTablePickupEst",
      key: "orderTablePickupEst",
      sorter: (a, b) => {
        const dateA = new Date(a.orderTablePickupEst);
        const dateB = new Date(b.orderTablePickupEst);
        return dateA - dateB;
      },
      sortOrder:
        sortedInfo.field === "orderTablePickupEst" ? sortedInfo.order : null,
      ellipsis: true,
      filteredValue: filteredInfo.orderTablePickupEst || null,
      render: (value) => {
        if (value) {
          const date = DateTime.fromISO(value);
          return date.toLocaleString({
            dateStyle: "short",
          });
        } else {
          return "";
        }
      },
    },
    // {
    //   title: "Pickup",
    //   dataIndex: "orderTablePickupActual",
    //   key: "orderTablePickupActual",
    //   filterSearch: null,
    //   filterIcon: null,
    //   filterDropdown: null,
    //   sorter: (a, b) => {
    //     const dateA = new Date(a.orderTablePickupActual);
    //     const dateB = new Date(b.orderTablePickupActual);
    //     return dateA - dateB;
    //   },
    //   sortOrder:
    //     sortedInfo.field === "orderTablePickupActual" ? sortedInfo.order : null,
    //   ellipsis: true,
    //   render: (value) => {
    //     if (value) {
    //       const date = DateTime.fromISO(value);
    //       return date.toLocaleString({
    //         dateStyle: "short",
    //       });
    //     } else {
    //       return "";
    //     }
    //   },
    // },
    {
      title: "Est. Delivery",
      dataIndex: "orderTableDeliveryEst",
      key: "orderTableDeliveryEst",
      sorter: (a, b) => {
        const dateA = new Date(a.orderTableDeliveryEst);
        const dateB = new Date(b.orderTableDeliveryEst);
        return dateA - dateB;
      },
      sortOrder:
        sortedInfo.field === "orderTableDeliveryEst" ? sortedInfo.order : null,
      ellipsis: true,
      render: (value) => {
        if (value) {
          const date = DateTime.fromISO(value);
          return date.toLocaleString({
            dateStyle: "short",
          });
        } else {
          return "";
        }
      },
    },
    // {
    //   title: "Delivery",
    //   dataIndex: "orderTableDeliveryActual",
    //   key: "orderTableDeliveryActual",
    //   sorter: (a, b) => {
    //     const dateA = new Date(a.orderTableDeliveryActual);
    //     const dateB = new Date(b.orderTableDeliveryActual);
    //     return dateA - dateB;
    //   },
    //   sortOrder:
    //     sortedInfo.field === "orderTableDeliveryActual"
    //       ? sortedInfo.order
    //       : null,
    //   ellipsis: true,
    //   render: (value) => {
    //     if (value) {
    //       const date = DateTime.fromISO(value);
    //       return date.toLocaleString({
    //         dateStyle: "short",
    //       });
    //     } else {
    //       return "";
    //     }
    //   },
    // },
  ];

  return (
    <>
      <Space
        style={{
          marginBottom: 16,
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        <Search
          className="table-search-field"
          placeholder="Search by Keyword"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onSearch={handleSearch}
          enterButton
          allowClear
        />

        <RangePicker value={dateRange} onChange={handleDateChange} />

        <Button
          style={{
            marginLeft: "-12px",
            borderRadius: "0px 2px 2px 0px",
            height: "100%",
          }}
          type="primary"
          onClick={handleSubmitDates}
        >
          Submit
        </Button>

        <Button style={{ cursor: "pointer" }} onClick={() => handleReset()}>
          Reset All Filters
        </Button>

        <Button
          style={{ cursor: "pointer" }}
          onClick={() => setShowPaidOrders(!showPaidOrders)}
        >
          Show {showPaidOrders ? "Unpaid" : "Paid"} Orders
        </Button>
      </Space>

      {showCancelOrderConfirmation && (
        <Alert
          text="Are you sure you want to cancel this order?"
          cancel={() => {
            setShowCancelOrderConfirmation(null);
          }}
          action={() => {
            dispatch(deleteOrder(showCancelOrderConfirmation));
            setShowCancelOrderConfirmation(null);
          }}
        />
      )}

      {data && (
        <Table
          className="cod-table-new"
          rowClassName={(record, index) => {
            let today = new Date();
            let pickupDate = new Date(record.orderTablePickupEst);
            let daysInMilli = pickupDate.getTime() - today.getTime();
            let days = Math.floor(daysInMilli / (1000 * 60 * 60 * 24));
            return days < 4 && !record.paid ? "highlighted" : "";
          }}
          pagination={false}
          columns={columns}
          dataSource={data}
          onChange={handleTableChange}
        />
      )}

      {!data && <Spinner />}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    settings: state.settings,
    orders: state.orders,
    portal: state.portals.portal,
    portals: state.portals.portals,
  };
};
export default connect(mapStateToProps)(CODTable);
