import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { useForm, ErrorMessage } from "react-hook-form";
import { getOrderStatus } from "../../actions/orders";
import OrderStatusDetail from "./OrderStatusDetail";

function OrderStatus(props) {
  const dispatch = useDispatch();
  const logo =
    "https://res.cloudinary.com/dq27r8cov/image/upload/v1616097775/McCollister%27s/mcc-logo-white.png";

  const [showForm, setShowForm] = useState(true);
  const { register, handleSubmit, errors } = useForm({});

  let history = useHistory();

  useEffect(() => {
    const script = document.createElement("script");
    script.async = true;

    script.src =
      "https://captivated-api.herokuapp.com/widget.js?id=f2c086ce-3ee4-11eb-a2c5-279efebdf26c";

    document.head.appendChild(script);

    document.body.appendChild(script);
  }, []);

  const onSubmit = (formData) => {
    dispatch(
      getOrderStatus(formData, () => {
        setShowForm(false);
      })
    );
  };

  return (
    <div className="order-status-container">
      {showForm && (
        <form
          className="login-form order-status-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="form-header">
            <div className="logo-container">
              <img src={logo} />
            </div>
            <h2>
              Hello and welcome to the McCollister’s Auto Logistics customer
              portal.
            </h2>
            <h4>
              Our portal will allow you access to updates on the progress of
              your auto transport order. Transport status and estimated dates
              will be updated in real time as your order progresses through to
              delivery.
            </h4>

            <h4>
              Enter your email address and Order ID below to log in. Your Order
              ID can be found on the confirmation email that was sent to you
              when confirming your order details.
            </h4>
          </div>

          {props.error && (
            <div className="order-status-form-error">{props.error}</div>
          )}

          <div className="form-input">
            <input
              placeholder="Email"
              type="text"
              name="email"
              ref={register({
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Please enter a valid email address.",
                },
              })}
            />
            <ErrorMessage errors={errors} name="email">
              {({ message }) => (
                <div className="form-response-errors">{message}</div>
              )}
            </ErrorMessage>
          </div>

          <div className="form-input">
            <input
              placeholder="Order ID"
              type="text"
              name="order_id"
              ref={register}
            />
          </div>

          <button className="form-submit" type="submit">
            Check Order Status
          </button>
        </form>
      )}

      {!showForm && props.customerOrder && (
        <OrderStatusDetail order={props.customerOrder} />
      )}
    </div>
  );
}
// }

const mapStateToProps = (state) => {
  return {
    trackedOrder: state.orders.trackedOrder,
    customerOrder: state.orders.customerOrder,
    error: state.orders.error,
  };
};

export default connect(mapStateToProps)(OrderStatus);
