import React from "react";

import { Text, View, Image, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    borderBottomWidth: 2,
    borderBottomStyle: "solid",
    alignItems: "stretch",
    marginBottom: 40,
  },
  image: {
    marginBottom: 10,
    width: 200,
    alignSelf: "center",
  },
  quoteHeader: {
    paddingTop: 10,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  quoteTitle: {
    fontSize: 20,
    alignSelf: "flex-end",
    justifySelf: "flex-start",
    textAlign: "left",
  },
  quoteNumber: {
    fontSize: 16,
    alignSelf: "flex-end",
    justifySelf: "flex-end",
  },
});

export const PDFHeader = (props) => {
  let quote = props.quote;
  let title = "Quote Summary";

  const image =
    "https://res.cloudinary.com/dq27r8cov/image/upload/v1616097775/McCollister%27s/mccollisters-auto-logistics.png";
  let logo = props.logo ? props.logo : image;

  return (
    <View style={styles.container}>
      <Image style={styles.image} source={{ uri: logo }} />
      <View style={styles.quoteHeader}>
        <Text style={styles.quoteTitle}>{title}</Text>
        <Text style={styles.quoteNumber}>Quote #{quote.uniqueId}</Text>
      </View>
    </View>
  );
};
