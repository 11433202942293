// 3rd Party Libraries
import React, { useState, useEffect, useRef } from "react";
import { FaInfoCircle } from "react-icons/fa";
import Button from "../../common/components/Button/Button";

import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import PlacesAutocomplete from "react-places-autocomplete";
import {
  useForm,
  useFieldArray,
  Controller,
  ErrorMessage,
} from "react-hook-form";

import MapboxAutocomplete from "./MapboxAutocomplete";

import { Card, Select } from "antd";

// Redux Actions
import { createQuote, updateQuote, getQuote } from "../../actions/quotes";
import { getMakes } from "../../actions/settings";

function QuoteFormEdit(props) {
  const dispatch = useDispatch();
  let history = useHistory();
  let quoteToEdit = props.quote;

  let numberOfVehicles = 1;
  if (quoteToEdit) {
    numberOfVehicles = quoteToEdit.vehicleQuotes.length;
  }
  const [showCommission, setShowCommission] = useState(true);
  const [makesList, setMakesList] = useState([]);
  const [portalCommission, setPortalCommission] = useState(0);
  const [foundPortal, setFoundPortal] = useState();
  const [isCalculating, setIsCalculating] = useState(false);

  useEffect(() => {
    if (!props.makes) {
      dispatch(getMakes());
    }
  }, []);

  useEffect(() => {
    if (props.makes) {
      let makes = props.makes;
      let makes_list = [];

      makes.forEach((item) => {
        let modelsList = [];

        item.models.forEach((model) => {
          modelsList.push({
            name: model.model,
            value: model.model,
            pricingClass: model.pricingClass,
          });
        });

        modelsList = [
          ...modelsList,
          {
            label: "Other - Sedan",
            value: "Other Sedan",
            pricingClass: "Sedan",
          },
          { label: "Other - SUV", value: "Other - SUV", pricingClass: "SUV" },
          { label: "Other - Van", value: "Other Van", pricingClass: "VAN" },
          {
            label: "Other Pickup Truck",
            value: "Other - Pickup",
            pricingClass: "Pick up 4 doors",
          },
          {
            label: "Other - Pre-1975 Classic",
            value: "Other Pre-1975 Classic",
            pricingClass: "Pick up 4 doors",
          },
        ];

        makes_list.push({
          name: item.make,
          value: item.make,
          models: modelsList,
        });
      });
      setMakesList(makes_list);
    }
  }, [props.makes]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const [numVehicles, setNumVehicles] = useState(numberOfVehicles);
  const [pickupLocationError, setPickupLocationError] = useState(null);
  const [deliveryLocationError, setDeliveryLocationError] = useState(null);
  const [showOperableToolTip, setShowOperableToolTip] = useState(null);

  const toggleOperableToolTip = (index) => {
    setShowOperableToolTip(index);
  };

  const tooltip = useRef();
  const handleClick = (e) => {
    if ((tooltip.current && tooltip.current.contains(e.target)) === false) {
      setShowOperableToolTip(false);
    }
  };

  const updateQuoteCallback = () => {
    setIsCalculating(false);
    props.toggleEditForm();
    // props.showSpinner(false);
    dispatch(getQuote(quoteToEdit._id));
  };

  const onSubmit = (formData) => {
    formData.vehicles = formData.vehicles.map((v) => {
      return {
        make: v.make.value || v.make,
        model: v.model.value || v.model,
        operable: v.operable === "No" ? false : true,
      };
    });

    setIsCalculating(true);
    formData.commission = parseInt(formData.commission);

    if (!quoteToEdit) {
      if (props.user.role === "MCAdmin") {
        formData.portalId = formData.portalId.value;
      }
      props.dispatch(
        createQuote(formData, history, () => {
          setIsCalculating(false);
        })
      );
    } else {
      formData.quoteId = quoteToEdit._id;

      dispatch(
        updateQuote(formData, () => {
          updateQuoteCallback();
        })
      );
    }
  };

  let defaultStatus,
    defaultVehicles,
    defaultCustomerFullName,
    defaultPortalId,
    defaultPickup,
    defaultDelivery,
    defaultTransportType,
    defaultUniqueId,
    defaultCommission;

  if (quoteToEdit) {
    let quoteVehicles = quoteToEdit.vehicleQuotes.map((vehicle) => {
      return {
        make: { label: vehicle.make, value: vehicle.make },
        model: {
          label: vehicle.model,
          value: vehicle.model,
          pricingClass: vehicle.pricingClass,
        },
        operable: vehicle.operableBool ? "Yes" : "No",
      };
    });

    defaultVehicles = quoteVehicles;

    defaultPortalId = {
      value: quoteToEdit.portalId,
      label: quoteToEdit.companyName,
    };
    defaultCustomerFullName = quoteToEdit.customerFullName;
    defaultPickup = quoteToEdit.pickup;
    defaultCommission = quoteToEdit.commission;
    defaultDelivery = quoteToEdit.delivery;
    defaultTransportType = {
      value: quoteToEdit.transportType,
      label: quoteToEdit.transportType,
    };
    defaultUniqueId = quoteToEdit.uniqueId;
    defaultStatus = quoteToEdit.status;
  } else {
    defaultPortalId = "Select One";
    defaultVehicles = [
      {
        make: "Select One",
        model: "Select One",
        operable: { label: "Yes", value: "Yes" },
      },
    ];
  }

  const { register, handleSubmit, errors, control, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues: {
      status: defaultStatus,
      vehicles: defaultVehicles,
      customerFullName: defaultCustomerFullName,
      portalId: defaultPortalId,
      pickup: defaultPickup,
      delivery: defaultDelivery,
      transportType: defaultTransportType,
      uniqueId: defaultUniqueId,
      commission: defaultCommission,
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "vehicles",
  });

  let portals_list = [];

  let selectedPortal = watch("portalId");

  // If MCAdmin
  if (props.portals.portals) {
    props.portals.portals.forEach((portal) => {
      if (
        portal.companyName !== "Agoyu" &&
        portal._id !== "61567b91e7be6c0018e66139"
      ) {
        portals_list.push({ name: portal.companyName, value: portal._id });
      }
    });
  }

  // Check for the displayPortalCommission settings for the selected portal
  useEffect(() => {
    let foundAPortal;

    if (props.auth.role === "MCAdmin") {
      if (props.portals && props.portals.portals.length > 0) {
        foundAPortal = props.portals.portals.find((portal) => {
          return portal._id == selectedPortal.value;
        });
      }
    } else {
      foundAPortal = props.portals.portal;
    }

    setFoundPortal(foundAPortal);
  }, [props.auth.role, selectedPortal, props.portals, props.portals.portal]);

  // Check for the displayPortalCommission settings for the selected portal
  useEffect(() => {
    if (foundPortal) {
      // Hide commission field because it is set at the company level
      if (foundPortal.displayPortalCommission) {
        setShowCommission(false);
        setPortalCommission(foundPortal.portalCommission);
        // Hide commission because it's disabled for all users
      } else if (foundPortal.displayCommissionPerVehicle === false) {
        setShowCommission(false);
      } else {
        setShowCommission(true);
      }
    }
  }, [foundPortal]);

  return (
    <Card title="Update Quote">
      <form className="quote-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="form-server-response-error">
          <h3>
            {typeof props.quotes.error !== "object" &&
              props.quotes.error &&
              props.quotes.error.length < 200}
          </h3>
        </div>
        <div className="quote-form-details">
          {props.auth.role === "MCAdmin" && (
            <div className="form-input">
              <label htmlFor="portalId">
                Company <span className="required">*</span>
              </label>
              <Controller
                control={control}
                name="portalId"
                as={
                  <Select>
                    {portals_list.map((portal) => {
                      return (
                        <Select.Option key={portal.value} value={portal.value}>
                          {portal.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                }
              />
              <ErrorMessage errors={errors} name="portalId">
                {({ message }) => (
                  <p className="form-error-message">Please select a portal.</p>
                )}
              </ErrorMessage>
            </div>
          )}

          {props.auth.role !== "MCAdmin" && props.user && (
            <input
              type="hidden"
              ref={register}
              value={props.user.portalId}
              name="portalId"
            />
          )}

          <input type="hidden" ref={register} name="status" />

          <div className="quote-form-locations-container">
            <div className="form-input">
              <label htmlFor="pickup">
                Pick Up <span className="required">*</span>
              </label>
              <Controller
                name="pickup"
                control={control}
                rules={{ required: "This field is required." }}
                as={
                  <MapboxAutocomplete
                    defaultValue={quoteToEdit?.pickup}
                    onError={(error) => setPickupLocationError(error)}
                  />
                }
                onChange={(value) => {
                  setPickupLocationError(null);
                  return value?.[0];
                }}
              />

              {pickupLocationError && (
                <p className="form-error-message">{pickupLocationError}</p>
              )}
            </div>
            <div className="form-input">
              <label htmlFor="delivery">
                Delivery <span className="required">*</span>
              </label>

              <Controller
                name="delivery"
                control={control}
                rules={{ required: "This field is required." }}
                as={
                  <MapboxAutocomplete
                    defaultValue={quoteToEdit?.delivery}
                    onError={(error) => setDeliveryLocationError(error)}
                  />
                }
                onChange={(value) => {
                  setDeliveryLocationError(null);
                  return value?.[0];
                }}
              />

              {deliveryLocationError && (
                <p className="form-error-message">{deliveryLocationError}</p>
              )}
            </div>
          </div>

          <div className="form-input">
            <label htmlFor="customerFullName">Customer Name</label>
            <input type="text" name="customerFullName" ref={register} />
          </div>

          <div className="form-input">
            <label htmlFor="transportType">Transport Type</label>

            <Controller
              control={control}
              rules={{ required: "This field is required." }}
              name="transportType"
              defaultValue="OPEN"
              as={
                <Select>
                  <Select.Option value="OPEN">Open</Select.Option>
                  <Select.Option value="ENCLOSED">Enclosed</Select.Option>
                  <Select.Option value="WHITEGLOVE">
                    Enclosed White Glove
                  </Select.Option>
                </Select>
              }
            />

            <ErrorMessage errors={errors} name="transportType">
              {({ message }) => <p className="form-error-message">{message}</p>}
            </ErrorMessage>
          </div>

          {showCommission && (
            <div className="form-input">
              <label htmlFor="commission">Commission ($ per vehicle)</label>
              <span className="currency-input">
                <input
                  type="number"
                  default="0"
                  placeholder="0"
                  name="commission"
                  ref={register}
                />
              </span>
            </div>
          )}

          {!showCommission && portalCommission > 0 && (
            <input
              type="hidden"
              value={portalCommission}
              name="commission"
              ref={register}
            />
          )}

          <input type="hidden" ref={register} name="status" />

          {props.portal && (
            <input
              type="hidden"
              ref={register}
              value={props.portal.companyTariff}
              name="companyTariff"
            />
          )}
        </div>

        <div className="quote-form-vehicles">
          {fields.map((item, index) => {
            let selectedMake;
            selectedMake = watch(`vehicles[${index}].make`);

            let models = [];

            if (selectedMake) {
              let foundMake = makesList.find(
                (make) => make.name === selectedMake
              );

              if (foundMake) {
                models = foundMake.models;
              }
            }

            return (
              <div key={item.id} className="sub-form">
                <div className="form-input">
                  <label htmlFor={`vehicles[${index}].make`}>
                    Make <span className="required">*</span>
                  </label>

                  <Controller
                    control={control}
                    name={`vehicles[${index}].make`}
                    defaultValue={item.make}
                    rules={{
                      required: true,
                      validate: (value) => value !== "Select One",
                    }}
                    as={
                      <Select>
                        {makesList.map((make) => {
                          return (
                            <Select.Option value={make.value}>
                              {make.name}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    }
                  />

                  <ErrorMessage
                    errors={errors}
                    name={`vehicles[${index}].make`}
                  >
                    {() => (
                      <p className="form-error-message">
                        Make cannot be empty.
                      </p>
                    )}
                  </ErrorMessage>
                </div>
                <div className="form-input">
                  <label htmlFor={`vehicles[${index}].model`}>
                    Model <span className="required">*</span>
                  </label>
                  {models && (
                    <div>
                      <Controller
                        as={
                          <Select>
                            {models.map((model) => {
                              return (
                                <Select.Option value={model.value}>
                                  {model.label}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        }
                        control={control}
                        rules={{ validate: (value) => value !== "Select One" }}
                        name={`vehicles[${index}].model`}
                        defaultValue={item.model}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`vehicles[${index}].model`}
                      >
                        {() => (
                          <p className="form-error-message">
                            Model cannot be empty.
                          </p>
                        )}
                      </ErrorMessage>
                    </div>
                  )}
                </div>

                <div className="form-input">
                  <label htmlFor={`vehicles[${index}].operable`}>
                    Is Vehicle Operable? <span className="required">*</span>
                    <FaInfoCircle
                      onClick={() => toggleOperableToolTip(index)}
                      className="icon-info-circle"
                    />
                  </label>
                  {showOperableToolTip === index && (
                    <div className="form-tooltip" ref={tooltip}>
                      An operable vehicle means it can brake, roll, steer and
                      start independently.
                    </div>
                  )}
                  <Controller
                    as={
                      <Select
                        options={[
                          { label: "Yes", value: "Yes" },
                          { label: "No", value: "No" },
                        ]}
                        placeholder="Select One"
                      />
                    }
                    control={control}
                    rules={{ validate: (value) => value !== "Is Operable" }}
                    name={`vehicles[${index}].operable`}
                  />

                  <ErrorMessage
                    errors={errors}
                    name={`vehicles[${index}].operable`}
                  >
                    {() => (
                      <p className="form-error-message">
                        Select if vehicle is operable.
                      </p>
                    )}
                  </ErrorMessage>
                </div>
              </div>
            );
          })}
        </div>

        <p className="required">* Required fields</p>

        <div style={{ display: "flex" }}>
          {numVehicles < 3 && (
            <Button
              label="Add Vehicle"
              style="light"
              action={() => {
                append({
                  make: "Select Make",
                  model: "Select Model",
                  operable: "Is Operable",
                });
                setNumVehicles(numVehicles + 1);
              }}
            />
          )}

          <Button
            label={
              isCalculating && !props.error ? "Calculating..." : "Update Quote"
            }
            type="submit"
            style="dark"
          />
        </div>
      </form>
    </Card>
  );
}

// Provides access to newQuote state
const mapStateToProps = (state) => {
  return {
    user: state.users.user,
    quotes: state.quotes,
    auth: state.auth,
    portals: state.portals,
    settings: state.settings.settings,
    makes: state.settings.makes,
  };
};

export default connect(mapStateToProps)(QuoteFormEdit);
