import axios from "axios";

import {
  SUBMIT_SURVEY,
  SURVEY_ERROR,
  CLEAR_SURVEY_ERROR,
  GET_SURVEY_RESULTS,
  GET_SURVEY_ORDER_RESULT,
  GET_SURVEY_PORTAL_RESULTS,
} from "./types";

import { authError } from "./auth";
import { rootUrl } from "../config/keys";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};
export const submitSurvey = (formData) => {
  return (dispatch) => {
    return axios
      .post(`${rootUrl}/survey`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(submitSurveySuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(surveyError(err.response.data.message));
          }
        } else {
          dispatch(surveyError("There was an error submitting this survey."));
        }
      });
  };
};

export const getSurveyResults = (portalId = null) => {
  return (dispatch) => {
    let uri = `${rootUrl}/surveys`;
    if (portalId) {
      uri = `${rootUrl}/surveys/${portalId}`;
    }

    return axios
      .get(uri, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getSurveyResultsSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(surveyError(err.response.data.message));
          }
        } else {
          dispatch(surveyError("There was an error getting surveyResults."));
        }
      });
  };
};

export const getSurveyOrderResult = (orderId) => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/survey/${orderId}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getSurveyOrderResultSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(surveyError(err.response.data.message));
          }
        } else {
          dispatch(
            surveyError("There was an error getting survey order result.")
          );
        }
      });
  };
};

export const getSurveyPortalResults = (portalId) => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/survey/portal/${portalId}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getSurveyPortalResultsSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(surveyError(err.response.data.message));
          }
        } else {
          dispatch(
            surveyError("There was an error getting survey portal results.")
          );
        }
      });
  };
};

export const submitSurveySuccess = (data) => ({
  type: SUBMIT_SURVEY,
  payload: data,
});

export const getSurveyResultsSuccess = (data) => ({
  type: GET_SURVEY_RESULTS,
  payload: data,
});

export const getSurveyOrderResultSuccess = (data) => ({
  type: GET_SURVEY_ORDER_RESULT,
  payload: data,
});

export const getSurveyPortalResultsSuccess = (data) => ({
  type: GET_SURVEY_PORTAL_RESULTS,
  payload: data,
});

export function surveyError(error) {
  return {
    type: SURVEY_ERROR,
    payload: error,
  };
}

export function clearSurveyError() {
  return {
    type: CLEAR_SURVEY_ERROR,
  };
}
