import React from "react";

import styles from "./Button.module.css";

const Button = ({
  label,
  action = () => {},
  style,
  type = "button",
  children,
}) => {
  return (
    <button
      className={`${styles.button} ${styles[style]}`}
      type={type}
      onClick={action}
    >
      {label}
      {children}
    </button>
  );
};

export default Button;
