import React from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { PDFPricingTableRows } from "./pdf-pricing-table-rows";
import { PDFPricingTableWhiteGlove } from "./pdf-pricing-table-white-glove";
import { PDFQuoteBulletedInfo } from "./pdf-boilerplate";
import { PDFEnclosedPricingInfo } from "./pdf-enclosed-pricing-info";

const styles = StyleSheet.create({
  mainContainer: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: 16,
    color: "#202020",
    marginBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: "#808080",
  },
});

export const PDFPricingTableContainer = (props) => {
  let quote = props.quote;

  return (
    <View style={styles.mainContainer}>
      <Text style={styles.title}>
        {quote.transportType === "WHITEGLOVE" ? "Price" : "Price Options"}
      </Text>

      {quote.transportType !== "WHITEGLOVE" && (
        <PDFPricingTableRows prices={quote.totalPricing} quote={quote} />
      )}

      {quote.transportType === "WHITEGLOVE" && (
        <PDFPricingTableWhiteGlove prices={quote.totalPricing} quote={quote} />
      )}

      <PDFQuoteBulletedInfo />

      <PDFEnclosedPricingInfo />

      {/* {quote.transportType === "ENCLOSED" && <PDFEnclosedPricingInfo />}
      {quote.transportType === "WHITEGLOVE" && <PDFEnclosedPricingInfo />} */}
    </View>
  );
};
