const SurveySinglePortal = ({ portalResults }) => {
  return (
    <div>
      {portalResults &&
        portalResults.map((data) => {
          if (data.question.isScale) {
            return (
              <div style={{ maxWidth: "500px", marginBottom: "100px" }}>
                <label>{data.question.question}</label>

                {!data.responses.length && (
                  <p style={{ fontSize: "1.4rem", marginTop: "10px" }}>
                    No Responses
                  </p>
                )}

                {data.responses.length > 0 && !isNaN(data.average) && (
                  <div style={{ marginTop: "15px", marginBottom: "15px" }}>
                    <h2
                      style={{
                        color: "#00274D",
                        fontWeight: "bold",
                        fontSize: "1.4rem",
                        fontWeight: "bold",
                      }}
                    >
                      Average Rating:
                    </h2>

                    <div
                      style={{
                        color: "#00274D",
                        fontWeight: "bold",
                        fontSize: "2.4rem",
                        fontWeight: "bold",
                      }}
                    >
                      {data.average.toFixed(1)} of 5
                      <span
                        style={{
                          fontSize: "1.4rem",
                          fontWeight: "normal",
                          marginLeft: "10px",
                        }}
                      >
                        ({data.responses.length} responses)
                      </span>
                    </div>
                  </div>
                )}

                <h2
                  style={{
                    color: "#00274D",
                    fontWeight: "bold",
                    fontSize: "1.4rem",
                    fontWeight: "bold",
                  }}
                >
                  Comments:
                </h2>
                <ul style={{ fontSize: "1.4rem" }}>
                  {data.responses &&
                    data.responses
                      .sort(
                        (a, b) =>
                          parseInt(b.orderUniqueId) - parseInt(a.orderUniqueId)
                      )
                      .map((r) => {
                        if (r.explanation) {
                          return (
                            <li>
                              {r.explanation}
                              <a
                                style={{ marginLeft: "5px" }}
                                target="_blank"
                                href={`/order/${r.order}`}
                              >
                                #{r.orderId}
                              </a>
                            </li>
                          );
                        }
                      })}
                </ul>
              </div>
            );
          }
        })}
    </div>
  );
};

export default SurveySinglePortal;
