import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    marginBottom: 3
  },

  title: {
    fontSize: 10,
    marginBottom: 3,
    color: "#505050"
  },
  car: {
    fontSize: 11,
    marginBottom: 9,
    color: "#202020"
  }
});

export const PDFVehicles = props => {
  let quote = props.quote;

  const cars = quote.vehicleQuotes.map((vehicle, i) => {
    let operability;
    if (vehicle.operable === "Yes" || vehicle.operable === "true") {
      operability = "Operable";
    } else {
      operability = "Inoperable";
    }
    return (
      <View key={i}>
        <Text style={styles.title}>
          Vehicle {i + 1}: {operability}
        </Text>
        <Text style={styles.car}>
          {vehicle.year} {vehicle.make} {vehicle.model}
        </Text>
      </View>
    );
  });
  return <View style={styles.container}>{cars}</View>;
};
