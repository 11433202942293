import React, { useState } from "react";
import styled from "styled-components";

// Components
import CarriersForm from "./CarriersForm.js";

function Carriers(props) {
  const View = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 500px) {
      width: 95%;
      margin: 5% auto;
    }
  `;

  return (
    <View>
      <CarriersForm />
    </View>
  );
}

export default Carriers;
