import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import Moment from "react-moment";
import Geocode from "react-geocode";
import Select from "react-select";
import { useParams } from "react-router";
import MapContainer from "./MapContainer.js";

import { requestDriverLocation } from "../../actions/orders";

// Components
import Spinner from "../../common/components/Spinner/Spinner.js";

// Config
import config from "../../config/keys";

function OrderStatusDetail(props) {
  const ROOT = config.rootUrl;
  const dispatch = useDispatch();

  const GoogleAPIKey = config.googleMaps;
  Geocode.setApiKey(GoogleAPIKey);
  Geocode.setLanguage("en");

  const [showRequestConfirmation, setShowRequestConfirmation] = useState(false);

  let order = props.order;

  if (props.order.driverLat && props.order.driverLong) {
    Geocode.fromLatLng(props.order.driverLat, props.order.driverLong).then(
      (response) => {
        setDriverLocation(response.results[0].formatted_address);
      },
      (error) => {
        console.error(error);
      }
    );
  }

  const handleDriverRequest = () => {
    dispatch(
      requestDriverLocation(props.order._id, () =>
        setShowRequestConfirmation(true)
      )
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // COMPONENT STATE ---------------------------------------------->
  const [recentlyUploaded, setRecentlyUploaded] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);
  const [message, setMessage] = useState("");
  const [driverLocation, setDriverLocation] = useState("Unknown");

  if (order) {
    let orderPortal, PDFlogo, PDFTotalPriceOnly;

    Geocode.setApiKey(GoogleAPIKey);
    Geocode.setLanguage("en");

    if (order.driverLat && order.driverLong) {
      Geocode.fromLatLng(order.driverLat, order.driverLong).then(
        (response) => {
          setDriverLocation(response.results[0].formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    }

    // Get address from latitude & longitude.
    if (order.driverLat && order.driverLong) {
      Geocode.fromLatLng(order.driverLat, order.driverLong).then(
        (response) => {
          setDriverLocation(response.results[0].formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    }

    let sdStatus = order.sdStatus;
    let sdStatusString;
    if (sdStatus === "new") {
      sdStatusString = "New Booking";
    } else if (sdStatus === "on_hold") {
      sdStatusString = "On Hold";
    } else if (sdStatus === "accepted") {
      sdStatusString = "Assigned Driver";
    } else if (sdStatus === "delivered") {
      sdStatusString = "Delivered";
    } else if (sdStatus === "picked_up") {
      sdStatusString = "Picked Up";
    } else if (sdStatus === "posted" || sdStatus === "posted_to_loadboard") {
      sdStatusString = "Confirmed";
    } else if (sdStatus === "order_canceled") {
      sdStatusString = "Canceled";
    } else if (sdStatus === "invoiced") {
      sdStatusString = "Delivered";
    } else if (
      sdStatus === "requests" ||
      sdStatus === "pending" ||
      sdStatus === "declined" ||
      sdStatus === "flagged" ||
      sdStatus === "paid"
    ) {
      sdStatusString = "";
    } else {
      sdStatusString = order.sdStatus;
    }

    let pickupDateTypeString;
    if (order.pickup.pickupDateType === "exact") {
      pickupDateTypeString = "Exact";
    } else if (order.pickup.pickupDateType === "not_earlier_than") {
      pickupDateTypeString = "Not Earlier Than";
    }
    if (order.pickup.pickupDateType === "estimated") {
      pickupDateTypeString = "Estimated";
    }

    let deliveryDateTypeString;
    if (order.delivery.deliveryDateType === "exact") {
      deliveryDateTypeString = "Exact";
    } else if (order.delivery.deliveryDateType === "not_earlier_than") {
      deliveryDateTypeString = "Not Earlier Than";
    }
    if (order.delivery.deliveryDateType === "estimated") {
      deliveryDateTypeString = "Estimated";
    }

    return (
      <div className="order-detail view-content-container">
        {showSpinner && <Spinner />}

        <div className="view-header">
          {props.message && (
            <div className="view-header-message">
              <h1>{props.message}</h1>
            </div>
          )}
          <h1>Order #: {order.uniqueId}</h1>
        </div>

        <div className="order-detail-container">
          {order.customer.customerFullName && (
            <div className="card">
              <h2>Customer Details</h2>
              {order.customer.customerFullName && (
                <div className="row">
                  <span className="row-label">Name:</span>
                  <span>{order.customer.customerFullName}</span>
                </div>
              )}

              {order.customer.customerPhone && (
                <div className="row">
                  <span className="row-label">Phone:</span>
                  <span>{order.customer.customerPhone}</span>
                </div>
              )}

              {order.customer.customerMobilePhone && (
                <div className="row">
                  <span className="row-label">Mobile Phone:</span>
                  <span>{order.customer.customerMobilePhone}</span>
                </div>
              )}

              {order.customer.customerAltPhone && (
                <div className="row">
                  <span className="row-label">Alternative Phone:</span>
                  <span>{order.customer.customerAltPhone}</span>
                </div>
              )}

              {order.customer.customerEmail && (
                <div className="row">
                  <span className="row-label">Email:</span>
                  <span>{order.customer.customerEmail}</span>
                </div>
              )}
            </div>
          )}

          <div className="card">
            <h2>Order Details</h2>

            <div className="row">
              <span className="row-label">Status:</span>
              <span>{sdStatusString}</span>
            </div>

            <div className="row">
              <span className="row-label">Transport Type:</span>
              <span>{order.transportType}</span>
            </div>

            <div className="row">
              <span className="row-label">Pickup Range:</span>

              <span>
                <Moment format="M/DD">{order.pickup.pickupScheduledAt}</Moment>{" "}
                {order.pickup.pickupScheduledEndsAt &&
                  pickupDateTypeString &&
                  pickupDateTypeString !== "Exact" && (
                    <span>
                      {" "}
                      -{" "}
                      <Moment format="M/DD">
                        {order.pickup.pickupScheduledEndsAt}
                      </Moment>
                    </span>
                  )}{" "}
                {pickupDateTypeString && "(" + pickupDateTypeString + ")"}
              </span>
            </div>

            <div className="row">
              <span className="row-label">Delivery Range:</span>
              <span>
                <Moment format="M/DD">
                  {order.delivery.deliveryScheduledAt}
                </Moment>
                {order.delivery.deliveryScheduledEndsAt && (
                  <span>
                    {" "}
                    -{" "}
                    <Moment format="M/DD">
                      {order.delivery.deliveryScheduledEndsAt}
                    </Moment>
                  </span>
                )}{" "}
                {deliveryDateTypeString && "(" + deliveryDateTypeString + ")"}
              </span>
            </div>
          </div>

          <div className="card">
            <h2>Vehicle Details</h2>
            {order.vehicles.map((vehicle) => {
              return (
                <div className="row" key={vehicle.model}>
                  <span className="row-label">
                    {vehicle.make} {vehicle.model}
                  </span>
                  <span key={vehicle.make}>
                    <p>
                      <span className="sub-label">VIN:</span>
                      {vehicle.vin}
                    </p>
                    <p>
                      <span className="sub-label">Year:</span>
                      {vehicle.year}
                    </p>
                    <p>
                      <span className="sub-label">Operable:</span>
                      {vehicle.operable ? "Yes" : "No"}
                    </p>
                  </span>
                </div>
              );
            })}
          </div>

          <div className="card">
            <h2>Pickup</h2>
            {order.pickup.pickupBusinessName && (
              <div className="row">
                <span className="row-label">Business Name:</span>
                <span>{order.pickup.pickupBusinessName}</span>
              </div>
            )}

            <div className="row">
              <span className="row-label">Contact Name</span>
              <span>{order.pickup.pickupContactName}</span>
            </div>

            <div className="row">
              <span className="row-label">Phone:</span>
              <span>{order.pickup.pickupPhone}</span>
            </div>

            {order.pickup.pickupMobilePhone && (
              <div className="row">
                <span className="row-label">Mobile Phone:</span>
                <span>{order.pickup.pickupMobilePhone}</span>
              </div>
            )}

            {order.pickup.pickupAltPhone && (
              <div className="row">
                <span className="row-label">Alternative Phone:</span>
                <span>{order.pickup.pickupAltPhone}</span>
              </div>
            )}

            <div className="row">
              <span className="row-label">Pickup Address:</span>
              <span>
                <p>{order.pickup.pickupAddress}</p>
                <p>
                  {order.pickup.pickupCity}, {order.pickup.pickupState}
                </p>
                <p>{order.pickup.pickupZip}</p>
              </span>
            </div>

            {order.pickup.pickupNotes && (
              <div className="row">
                <span className="row-label">Pickup Notes:</span>
                <span>{order.pickup.pickupNotes}</span>
              </div>
            )}
          </div>

          <div className="card">
            <h2>Delivery</h2>
            {order.delivery.deliveryBusinessName && (
              <div className="row">
                <span className="row-label">Business Name:</span>
                <span>{order.delivery.deliveryBusinessName}</span>
              </div>
            )}

            {!props.isOrder && (
              <div className="row">
                <span className="row-label">Contact Name</span>
                <span>{order.delivery.deliveryContactName}</span>
              </div>
            )}
            <div className="row">
              <span className="row-label">Phone:</span>
              <span>{order.delivery.deliveryPhone}</span>
            </div>

            {order.delivery.deliveryMobilePhone && (
              <div className="row">
                <span className="row-label">Mobile Phone:</span>
                <span>{order.delivery.deliveryMobilePhone}</span>
              </div>
            )}

            {order.delivery.deliveryAltPhone && (
              <div className="row">
                <span className="row-label">Alternative Phone:</span>
                <span>{order.delivery.deliveryAltPhone}</span>
              </div>
            )}

            <div className="row">
              <span className="row-label">Delivery Address:</span>
              <span>
                <p>{order.delivery.deliveryAddress}</p>
                <p>
                  {order.delivery.deliveryCity}, {order.delivery.deliveryState}
                </p>
                <p>{order.delivery.deliveryZip}</p>
              </span>
            </div>

            {order.delivery.deliveryNotes && (
              <div className="row">
                <span className="row-label">Delivery Notes:</span>
                <span className="notes-field">
                  {order.delivery.deliveryNotes}
                </span>
              </div>
            )}
          </div>

          {props.order.driverPhone && props.order.sdStatus == "picked_up" && (
            <div id="request-driver-update-card" className="card">
              <h2>Request Driver Update</h2>

              {showRequestConfirmation && (
                <div>A location request has been sent to your driver.</div>
              )}

              {props.error && <p>{props.error}</p>}

              {props.order.driverUpdated && (
                <p>
                  Last Request:{" "}
                  <Moment format="M/DD LT">{props.order.driverUpdated}</Moment>
                </p>
              )}

              {props.order.driverLat && props.order.driverLong && (
                <>
                  <p>Your driver's current location: {driverLocation}</p>

                  <div className="order-status-map-container">
                    <MapContainer
                      lat={props.order.driverLat}
                      lng={props.order.driverLong}
                    />
                  </div>
                </>
              )}

              <p>
                Please note, this is a request that will be sent to our driver.
                We require that all our drivers do not text and drive and only
                respond to location requests when safely stopped. Due to our
                commitment to safety, you may not receive an immediate response.
                For a quicker response, please feel free to call us at (888)
                819-0594 or email us at autologistics@mccollisters.com
              </p>

              <button onClick={handleDriverRequest}>
                Submit Driver Request
              </button>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
}

const mapStateToProps = (state) => {
  return {
    trackedOrder: state.orders.trackedOrder,
    error: state.orders.error,
  };
};

export default connect(mapStateToProps)(OrderStatusDetail);
