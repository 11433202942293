import axios from "axios";
import {
  CREATE_ORDER,
  GET_ORDER,
  DELETE_ORDER,
  GET_ORDER_ACTIVITIES,
  GET_ORDERS,
  TRACK_ORDER,
  GET_ORDER_STATUS,
  ORDER_ERROR,
  CLEAR_ORDER_ERROR,
  UPDATE_ORDER,
  REQUEST_DRIVER_LOCATION,
  ORDER_TABLE_FILTERS,
  CLEAR_ORDER_TABLE_FILTERS,
  EXPORT_ORDERS,
  CLEAR_CSV,
  ORDERS_ANALYTICS,
} from "./types";

import { authError } from "./auth";
import { rootUrl } from "../config/keys";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};

export const getOrders = (data, callback = null) => {
  let uri = `${rootUrl}/orders`;

  if (data && data.sortBy) {
    data.sortorder = data.sortBy && data.sortBy.desc === true ? -1 : 1;
    data.sortby = data.sortBy.key;
  } else {
    data.sortorder = -1;
    data.sortby = "uniqueId";
  }

  return (dispatch) => {
    dispatch(clearOrderError());
    return axios
      .post(uri, data, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (callback) {
          callback();
        }
        dispatch(getOrdersSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(orderError(err.response.data.message));
          }
        } else {
          dispatch(orderError("There was an error getting orders."));
        }
      });
  };
};

export const exportOrders = (data) => {
  let uri = `${rootUrl}/orders/export`;

  // let order, key;
  // if (data && data.sortBy) {
  //   order = data.sortBy && data.sortBy.desc === true ? -1 : 1;
  //   key = data.sortBy.key;
  // } else {
  //   order = -1;
  //   key = "uniqueId";
  // }

  //  let uri = `${rootUrl}/orders/export?sortby=${key}&sortorder=${order}`;
  // if (data) {
  //   if (data.portalId) {
  //     uri += `&portalId=${data.portalId}`;
  //   }
  //   if (data.skip) {
  //     uri += `&skip=${data.skip}`;
  //   }
  //   if (data.limit) {
  //     uri += `&limit=${data.limit}`;
  //   }
  //   if (data.searchText) {
  //     uri += `&searchText=${data.searchText}`;
  //   }
  //   if (data.selectedPortalId) {
  //     uri += `&selectedPortalId=${data.selectedPortalId.value}`;
  //   }
  //   if (data.dateStart) {
  //     uri += `&start=${new Date(data.dateStart).toISOString()}`;
  //   }
  //   if (data.dateEnd) {
  //     uri += `&end=${new Date(data.dateEnd).toISOString()}`;
  //   }
  // }

  return (dispatch) => {
    return axios
      .post(uri, data, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(exportOrdersSuccess(data));
        dispatch(clearOrderError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(orderError(err.response.data.message));
          }
        } else {
          dispatch(orderError("There was an error exporting orders."));
        }
      });
  };
};

export const getOrdersAnalytics = (data) => {
  let order, key;
  if (data && data.sortBy) {
    order = data.sortBy && data.sortBy.desc === true ? -1 : 1;
    key = data.sortBy.key;
  } else {
    order = -1;
    key = "uniqueId";
  }

  let uri = `${rootUrl}/orders/analytics?sortby=${key}&sortorder=${order}`;
  if (data) {
    if (data.portalId) {
      uri += `&portalId=${data.portalId}`;
    }
    if (data.skip) {
      uri += `&skip=${data.skip}`;
    }
    if (data.limit) {
      uri += `&limit=${data.limit}`;
    }
    if (data.searchText) {
      uri += `&searchText=${data.searchText}`;
    }
    if (data.selectedPortalId) {
      uri += `&selectedPortalId=${data.selectedPortalId.value}`;
    }
    if (data.dateStart) {
      uri += `&start=${new Date(data.dateStart).toISOString()}`;
    }
    if (data.dateEnd) {
      uri += `&end=${new Date(data.dateEnd).toISOString()}`;
    }
  }

  return (dispatch) => {
    return axios
      .get(uri, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(ordersAnalyticsSuccess(data));
        dispatch(clearOrderError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(orderError(err.response.data.message));
          }
        } else {
          dispatch(orderError("There was an error getting analytics."));
        }
      });
  };
};

// Get a single order
export const getOrder = (orderId) => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/order/${orderId}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getOrderSuccess(data));
        dispatch(clearOrderError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(orderError(err.response.data.message));
          }
        } else {
          dispatch(orderError("There was an error getting this order."));
        }
      });
  };
};

// Delete a single order
export const deleteOrder = (orderId) => {
  return (dispatch) => {
    return axios
      .delete(`${rootUrl}/order/${orderId}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(deleteOrderSuccess(data.orderId));
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(orderError(err.response.data.message));
          }
        } else {
          dispatch(orderError("There was an error deleting this order."));
        }
      });
  };
};

// Get a single order's activities
export const getOrderActivities = (orderId) => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/order/${orderId}/activities`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getOrderActivitiesSuccess(data));
        dispatch(clearOrderError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(orderError(err.response.data.message));
          }
        } else {
          dispatch(
            orderError("There was an error getting this order's activities.")
          );
        }
      });
  };
};

// Create an order
export const createOrder = (formData, history, callback) => {
  return (dispatch) => {
    return axios
      .post(`${rootUrl}/order`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearOrderError());
        dispatch(createOrderSuccess(data));

        if (!data.isCustomerPortal) {
          history.push(`/order/${data._id}`);
        }

        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(orderError(err.response.data.message));
          }
        } else {
          dispatch(orderError("There was an error creating this order."));
        }
      });
  };
};

// Customer track an order
export const trackOrder = (formData) => {
  return (dispatch) => {
    return axios
      .post(`${rootUrl}/order/${formData.orderId}/track`, formData)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearOrderError());
        dispatch(trackOrderSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(orderError(err.response.data.message));
          }
        } else {
          dispatch(orderError("There was an error creating this order."));
        }
      });
  };
};

// Customer get order status
export const getOrderStatus = (formData, callback) => {
  return (dispatch) => {
    dispatch(clearOrderError());
    return axios
      .post(`${rootUrl}/order/${formData.order_id}/status`, formData)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearOrderError());
        dispatch(getOrderStatusSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(orderError(err.response.data.message));
          }
        } else {
          dispatch(
            orderError(
              "Sorry! We were unable to find the status of this order."
            )
          );
        }
      });
  };
};

// Customer request driver location via Captivated
export const requestDriverLocation = (orderId, callback) => {
  return (dispatch) => {
    return axios
      .post(`${rootUrl}/order/${orderId}/location`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearOrderError());
        dispatch(requestDriverLocationSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(orderError(err.response.data.message));
          }
        } else {
          dispatch(
            orderError("Sorry! We were unable to send a request to the driver.")
          );
        }
      });
  };
};

// Update an order
export const updateOrder = (updates, callback) => {
  return (dispatch) => {
    return axios
      .put(`${rootUrl}/order`, updates, config())

      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getOrderSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(orderError(err.response.data.message));
          }
        } else {
          dispatch(orderError("There was an error updating this order."));
        }
      });
  };
};

export const acceptTerms = (data, callback) => {
  let uri = `${rootUrl}/order/terms`;

  return (dispatch) => {
    return axios
      .post(uri, data, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearOrderError());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(orderError(err.response.data.message));
          }
        } else {
          dispatch(orderError("There was an error accepting the terms."));
        }
      });
  };
};

export const exportOrdersSuccess = (data) => ({
  type: EXPORT_ORDERS,
  csv: data,
});

export const ordersAnalyticsSuccess = (data) => ({
  type: ORDERS_ANALYTICS,
  payload: data,
});

export const updateOrderSuccess = (data) => ({
  type: UPDATE_ORDER,
  updatedOrder: data,
});

export const getOrderStatusSuccess = (data) => ({
  type: GET_ORDER_STATUS,
  order: data,
});

export const getOrderActivitiesSuccess = (data) => ({
  type: GET_ORDER_ACTIVITIES,
  order: data,
});

export const getOrdersSuccess = (data) => ({
  type: GET_ORDERS,
  orders: data,
});

export const getOrderSuccess = (data) => ({
  type: GET_ORDER,
  order: data,
});

export const createOrderSuccess = (data) => ({
  type: CREATE_ORDER,
  order: data,
});

export const trackOrderSuccess = (data) => ({
  type: TRACK_ORDER,
  order: data,
});

export const requestDriverLocationSuccess = (data) => ({
  type: REQUEST_DRIVER_LOCATION,
  order: data,
});

export function orderTableFilters(data) {
  return {
    type: ORDER_TABLE_FILTERS,
    filters: data,
  };
}

export function clearOrderTableFilters() {
  return {
    type: CLEAR_ORDER_TABLE_FILTERS,
  };
}

export const deleteOrderSuccess = (data) => ({
  type: DELETE_ORDER,
  orderId: data,
});

export function orderError(error) {
  return {
    type: ORDER_ERROR,
    payload: error,
  };
}

export function clearOrderError() {
  return {
    type: CLEAR_ORDER_ERROR,
  };
}

export function clearCSV() {
  return {
    type: CLEAR_CSV,
  };
}
