import axios from "axios";
import {
  CREATE_PORTAL,
  GET_PORTALS,
  GET_PORTAL,
  UPDATE_PORTAL,
  ADMIN_GET_PORTAL,
  PORTAL_ERROR,
  CLEAR_PORTAL_ERROR,
  GET_REGIONS,
} from "./types";

import { authError } from "./auth";
import { rootUrl } from "../config/keys";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};

// Fetches ALL portals
export const getPortals = () => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/portals`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getPortalsSuccess(data));
        dispatch(clearPortalError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(portalError(err.response.data.message));
          }
        } else {
          dispatch(portalError("There was an error getting portals."));
        }
      });
  };
};

// Gets portal for this logged in user
export const getUserPortal = () => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/portal`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getPortalSuccess(data));
        dispatch(clearPortalError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(portalError(err.response.data.message));
          }
        } else {
          dispatch(portalError("There was an error getting this portal."));
        }
      });
  };
};

// Gets a portal by id
export const adminGetPortal = (portalId) => {
  let uri;

  if (!portalId || portalId === undefined) {
    uri = `${rootUrl}/portal`;
  } else {
    uri = `${rootUrl}/portal/${portalId}`;
  }
  return (dispatch) => {
    return axios
      .get(uri, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(adminGetPortalSuccess(data));
        dispatch(clearPortalError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(portalError(err.response.data.message));
          }
        } else {
          dispatch(portalError("There was an error getting this portal."));
        }
      });
  };
};

export const getPortalRegions = (portalId) => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/portal/${portalId}/regions`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getRegionsSuccess(data));
        dispatch(clearPortalError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(portalError(err.response.data.message));
          }
        } else {
          dispatch(portalError("There was an error getting portal regions."));
        }
      });
  };
};

// Creates a new portal
export const createPortal = (formData, logoUrl, callback) => {
  return (dispatch) => {
    if (logoUrl) {
      formData = { ...formData, companyLogo: logoUrl };
    }

    return axios
      .post(`${rootUrl}/portal`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(createPortalSuccess(data));
        dispatch(clearPortalError());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(portalError(err.response.data.message));
          }
        } else {
          dispatch(portalError("There was an error creating this portal."));
        }
      });
  };
};

export const updatePortal = (formData, logoUrl, callback) => {
  return (dispatch) => {
    if (logoUrl) {
      formData = { ...formData, companyLogo: logoUrl };
    }
    return axios
      .put(`${rootUrl}/portal/${formData.portalId}`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(updatePortalSuccess(data));
        dispatch(clearPortalError());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(portalError(err.response.data.message));
          }
        } else {
          dispatch(portalError("There was an error updating this portal."));
        }
      });
  };
};

export const getPortalsSuccess = (data) => ({
  type: GET_PORTALS,
  portals: data,
});

export const createPortalSuccess = (data) => ({
  type: CREATE_PORTAL,
  portal: data,
});

export const updatePortalSuccess = (data) => ({
  type: UPDATE_PORTAL,
  portal: data,
});

export function portalError(error) {
  return {
    type: PORTAL_ERROR,
    payload: error,
  };
}

export const adminGetPortalSuccess = (data) => ({
  type: ADMIN_GET_PORTAL,
  portal: data,
});

export const getPortalSuccess = (data) => ({
  type: GET_PORTAL,
  portal: data,
});

export const getRegionsSuccess = (data) => ({
  type: GET_REGIONS,
  regions: data,
});

export function clearPortalError() {
  return {
    type: CLEAR_PORTAL_ERROR,
  };
}
