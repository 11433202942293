import React from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10
  },
  text: {
    marginTop: 10,
    marginBottom: 5,
    fontSize: 10,
    color: "#b92525"
  }
});

export const PDFEnclosedPricingInfo = props => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>* Please note that for enclosed transport, in order to meet your required pick up date, we reserve the right to use a flatbed to pick up the vehicle and bring it to a secure terminal location while awaiting arrival of the enclosed transport truck.</Text>
    </View>
  );
};
