import { ADD_MESSAGE, CLEAR_MESSAGE } from '../actions/types';

export default function (state = null, action) {
    switch (action.type) {
        case ADD_MESSAGE:
            return action.message;
        case CLEAR_MESSAGE:
            return null;
        default:
            return state;
    }
}