import React, { Component } from "react";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import config from "../../config/keys";

const GoogleAPIKey = config.googleMaps;

export class MapContainer extends Component {
  render() {
    return (
      <Map
        className="order-tracker-map"
        google={this.props.google}
        zoom={10}
        initialCenter={{
          lat: this.props.lat,
          lng: this.props.lng,
        }}
      >
        <Marker onClick={this.onMarkerClick} name={"Current location"} />

        {/* <InfoWindow onClose={this.onInfoWindowClose}>
                    <div>
                        <h1>{this.state.selectedPlace.name}</h1>
                    </div>
                </InfoWindow> */}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GoogleAPIKey,
})(MapContainer);

// function MapContainer(props) {
//     return (
//         <Map google={props.google} zoom={14} initialCenter={{
//             lat: 40.854885,
//             lng: -88.081807
//         }}>

//             <Marker onClick={this.onMarkerClick}
//                 name={'Current location'} />

//             <InfoWindow onClose={this.onInfoWindowClose}>
//                 <div>
//                     <h1>{this.state.selectedPlace.name}</h1>
//                 </div>
//             </InfoWindow>
//         </Map>
//     )
// }

// export default GoogleApiWrapper({
//     apiKey: (GoogleAPIKey)
// })(MapContainer)
