import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";

import { ClearBrowserCacheBoundary } from "react-clear-browser-cache";

import PrivateRoute from "./PrivateRoute";
import MCAdminRoute from "./MCAdminRoute";
import AdminRoute from "./AdminRoute";
import Auth from "../views/Auth/Auth";

import Sidebar from "../common/components/Sidebar/Sidebar";
import BookOrder from "../views/BookOrder/BookOrder";

import PortalMCAdminForm from "../views/Portals/PortalMCAdminForm";
import PortalMCAdminEdit from "../views/Portals/PortalMCAdminEdit";
import PortalAdminEdit from "../views/Portals/PortalAdminEdit";

import SurveyResults from "../views/Survey/SurveyResults";

import UserDetail from "../views/UserDetail/UserDetail";
import UsersForm from "../views/Users/UsersForm";

import COD from "../views/COD/COD";

import Account from "../views/Account/Account";

import TrackOrder from "../views/TrackOrder/TrackOrder";
import OrderStatus from "../views/OrderStatus/OrderStatus";

import Survey from "../views/Survey/Survey.js";

import Carriers from "../views/Carriers/Carriers";

import Spinner from "../common/components/Spinner/Spinner";
const Terms = lazy(() => import("../views/Terms/Terms"));
const Quotes = lazy(() => import("../views/Quotes/Quotes"));
const Orders = lazy(() => import("../views/Orders/Orders"));
const Reports = lazy(() => import("../views/Reports/Reports"));
const Users = lazy(() => import("../views/Users/Users"));
const Settings = lazy(() => import("../views/Settings/Settings"));
const Portals = lazy(() => import("../views/Portals/Portals"));
const QuoteDetail = lazy(() => import("../views/QuoteDetail/QuoteDetail"));
const QuoteDetailPublic = lazy(() =>
  import("../views/QuoteDetail/QuoteDetailPublic")
);
const OrderDetail = lazy(() => import("../views/OrderDetail/OrderDetail"));

const QuoteForm = lazy(() => import("../views/Quotes/QuoteForm"));
const QuoteFormPublic = lazy(() => import("../views/Quotes/QuoteFormPublic"));
const QuoteFormEdit = lazy(() => import("../views/Quotes/QuoteFormEdit"));

const containerStyle = {
  backgroundImage:
    'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://res.cloudinary.com/dq27r8cov/image/upload/v1693438322/McCollister%27s/background.jpg")',
};

function AppRouter(props) {
  return (
    <Router>
      <div className="app">
        <div className="app-container">
          {props.auth.authenticated && <Sidebar />}

          <div className="view-container" style={containerStyle}>
            <ClearBrowserCacheBoundary
              auto
              fallback={<Spinner />}
              duration={60000}
            >
              <Suspense fallback={<div></div>}>
                <Switch>
                  <Route path="/track-order">
                    <TrackOrder />
                  </Route>

                  <PrivateRoute
                    path="/quote/:quoteId/edit"
                    auth={props.auth}
                    component={QuoteFormEdit}
                  />

                  <Route
                    path="/quote/public/:quoteId/book"
                    component={BookOrder}
                  />

                  <Route path="/terms/:orderId/:uniqueId" component={Terms} />
                  <Route path="/terms" component={Terms} />

                  <Route
                    path="/quote/public/:quoteId"
                    component={QuoteDetailPublic}
                  />

                  <PrivateRoute
                    path="/quote/:quoteId"
                    auth={props.auth}
                    component={QuoteDetail}
                  />

                  <Route path="/quote" component={QuoteFormPublic} />

                  <Route auth={props.auth} path="/order-status">
                    <OrderStatus />
                  </Route>

                  <Route path="/carriers">
                    <Carriers />
                  </Route>

                  <Route path="/customer-survey/:id">
                    <Survey />
                  </Route>

                  <Route path="/customer-survey">
                    <Survey />
                  </Route>

                  <Route path="/forgot-password">
                    <Auth form="forgotPassword" />
                  </Route>
                  <Route path="/reset-password/:token">
                    <Auth form="resetPassword" />
                  </Route>
                  <Route path="/auth">
                    <Auth form="auth" />
                  </Route>
                  <Route path="/login">
                    <Auth form="login" />
                  </Route>

                  <MCAdminRoute
                    path="/survey/:id"
                    auth={props.auth}
                    component={SurveyResults}
                  />

                  <AdminRoute
                    path="/survey"
                    auth={props.auth}
                    component={SurveyResults}
                  />

                  <MCAdminRoute
                    path="/portals/new"
                    auth={props.auth}
                    component={PortalMCAdminForm}
                  />
                  <AdminRoute
                    path="/portal/:portalId/settings"
                    auth={props.auth}
                    component={PortalAdminEdit}
                  />
                  <MCAdminRoute
                    path="/portal/:portalId"
                    auth={props.auth}
                    component={PortalMCAdminEdit}
                  />
                  <MCAdminRoute
                    path="/portals"
                    auth={props.auth}
                    component={Portals}
                  />
                  <AdminRoute
                    path="/user/:userId"
                    auth={props.auth}
                    component={UserDetail}
                  />

                  <Route path="/users/new-user" component={UsersForm} />
                  <Route path="/users/new" component={UsersForm} />

                  <AdminRoute
                    path="/users"
                    auth={props.auth}
                    component={Users}
                  />
                  <PrivateRoute
                    path="/get-quote"
                    auth={props.auth}
                    component={QuoteForm}
                  />
                  <PrivateRoute
                    auth={props.auth}
                    path="/reports"
                    component={Reports}
                  />

                  <PrivateRoute
                    path="/quotes"
                    auth={props.auth}
                    component={Quotes}
                  />
                  <PrivateRoute
                    path="/orders/book/:quoteId"
                    auth={props.auth}
                    component={BookOrder}
                  />
                  <PrivateRoute
                    path="/order/:orderId"
                    auth={props.auth}
                    component={OrderDetail}
                  />
                  <PrivateRoute
                    path="/orders"
                    auth={props.auth}
                    component={Orders}
                  />
                  <MCAdminRoute
                    path="/settings"
                    auth={props.auth}
                    component={Settings}
                  />
                  <MCAdminRoute path="/cod" auth={props.auth} component={COD} />

                  <PrivateRoute
                    path="/account"
                    auth={props.auth}
                    component={Account}
                  />
                  <Route path="/">
                    <Redirect to="/login" />
                  </Route>
                </Switch>
              </Suspense>
            </ClearBrowserCacheBoundary>
          </div>
        </div>
        {/* <Footer /> */}
      </div>
    </Router>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AppRouter);
