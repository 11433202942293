import { useState } from "react";
import Button from "../../common/components/Button/Button";

const SurveyOpenEnds = ({ portals }) => {
  const [showOpenEnds, setShowOpenEnds] = useState(false);

  let responses = [];

  for (const portal of portals) {
    portal.portalResults.forEach((result) => {
      if (result.explanation) {
        responses.push({
          text: result.explanation,
          orderId: result.order,
          orderUniqueId: result.orderId,
          portal: portal.companyName,
        });
      }
    });
  }

  return (
    <div style={{ marginBottom: "50px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBottom: "15px",
        }}
      >
        <Button
          label={`${showOpenEnds ? "Hide" : "Show"} Open Ended Responses`}
          style="dark"
          action={() => setShowOpenEnds(!showOpenEnds)}
        />
      </div>

      {showOpenEnds && responses && (
        <ul>
          {responses
            .sort(
              (a, b) => parseInt(b.orderUniqueId) - parseInt(a.orderUniqueId)
            )
            .map((r) => {
              return (
                <li>
                  {r.text}{" "}
                  <a
                    style={{ marginLeft: "5px" }}
                    target="_blank"
                    href={`/order/${r.orderId}`}
                  >
                    #{r.orderUniqueId}
                  </a>
                </li>
              );
            })}
        </ul>
      )}
    </div>
  );
};

export default SurveyOpenEnds;
