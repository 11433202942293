import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";

import { Form, Card, Input, Select } from "antd";

import Button from "../../common/components/Button/Button";

import { MaskedInput } from "antd-mask-input";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createUser } from "../../actions/users";

function UsersForm(props) {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const [portalsList, setPortalsList] = useState([]);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    if (props.portals && props.portals.portals) {
      setPortalsList(
        props.portals.portals.map((p) => {
          return <Select.Option value={p._id}>{p.companyName}</Select.Option>;
        })
      );
    }
  }, [props.portals]);

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const onFinish = (values) => {
    if (props.auth?.role !== "MCAdmin" && props.auth.portalId) {
      values.portalId = props.auth.portalId;
    }

    props.dispatch(
      createUser(values, () => {
        history.push("/users");
      })
    );
  };

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div>
      <Helmet>
        <title>Create New User</title>
      </Helmet>
      <div className="view-users view-content-container">
        <Card
          title="Create New User"
          bordered={false}
          style={{ width: "100%" }}
        >
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 10 }}
            className="user-form"
            validateMessages={validateMessages}
            onFinish={onFinish}
          >
            {props.auth?.role === "MCAdmin" && (
              <Form.Item label="Company" name="portalId">
                <Select>{portalsList}</Select>
              </Form.Item>
            )}

            <Form.Item label="First Name" name="firstName" required>
              <Input />
            </Form.Item>

            <Form.Item label="Last Name" name="lastName" required>
              <Input />
            </Form.Item>

            <Form.Item label="Role" name="role" required>
              <Select>
                <Select.Option value="User">User</Select.Option>
                <Select.Option value="Admin">Admin</Select.Option>
                <Select.Option value="MCAdmin">
                  McCollister's Admin
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Email"
              name="email"
              rules={[{ type: "email", required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Temporary Password" name="password" required>
              <Input.Password />
            </Form.Item>

            <Form.Item label="Phone" name="phone">
              <MaskedInput mask={"(000) 000-0000"} />
            </Form.Item>

            <Form.Item label="Mobile Phone" name="mobilePhone">
              <MaskedInput mask={"(000) 000-0000"} />
            </Form.Item>
            {props.auth.error && (
              <p class="auth-error-message">{props.auth.error}</p>
            )}

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button label="Submit" style="dark" type="submit" />

              <Button
                label="Reset"
                style="light"
                type="button"
                action={onReset}
              />
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    files: state.files,
    portals: state.portals,
    users: state.users,
  };
};

export default connect(mapStateToProps)(UsersForm);
