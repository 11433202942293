import {
  SUBMIT_SURVEY,
  SURVEY_ERROR,
  CLEAR_SURVEY_ERROR,
  GET_SURVEY_RESULTS,
  GET_SURVEY_ORDER_RESULT,
  GET_SURVEY_PORTAL_RESULTS,
} from "../actions/types";

export default (state = { error: null }, action) => {
  switch (action.type) {
    case SUBMIT_SURVEY:
      return { ...state, survey: action.payload, error: null };
    case SURVEY_ERROR:
      return { ...state, error: action.payload };
    case GET_SURVEY_RESULTS:
      return { ...state, results: action.payload, error: null };
    case GET_SURVEY_ORDER_RESULT:
      return { ...state, order: action.payload, error: null };
    case GET_SURVEY_PORTAL_RESULTS:
      return { ...state, portal: action.payload, error: null };
    case CLEAR_SURVEY_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
};
