// 3rd Party Libraries
import { createStore, combineReducers, applyMiddleware } from "redux";
import { persistStore, persistReducer, autoRehydrate } from "redux-persist";
import thunk from "redux-thunk";
import logger from "redux-logger";
import jwt_decode from "jwt-decode";
import storage from "redux-persist/lib/storage";

// Reducers
import alertsReducer from "../reducers/alert";
import authReducer from "../reducers/auth";
import filesReducer from "../reducers/files";
import portalsReducer from "../reducers/portals";
import usersReducer from "../reducers/users";
import quotesReducer from "../reducers/quotes";
import ordersReducer from "../reducers/orders";
import settingsReducer from "../reducers/settings";
import messageReducer from "../reducers/message";
import emailsReducer from "../reducers/emails";
import reportsReducer from "../reducers/reports";
import surveysReducer from "../reducers/surveys";
import filtersReducer from "../reducers/filters";

// Actions
import { getPortals, getUserPortal } from "../actions/portals";
import { getUser, getUsers, getPortalUsers } from "../actions/users";
import { getSettings, getMakes } from "../actions/settings";

// Creates the Redux store with reducers and middleware
const rootReducer = combineReducers({
  auth: authReducer,
  files: filesReducer,
  message: messageReducer,
  portals: portalsReducer,
  users: usersReducer,
  quotes: quotesReducer,
  orders: ordersReducer,
  settings: settingsReducer,
  alerts: alertsReducer,
  emails: emailsReducer,
  reports: reportsReducer,
  surveys: surveysReducer,
  filters: filtersReducer,
});

const persistConfig = {
  key: "root",
  whitelist: ["settings"],
  storage,
};

const pReducer = persistReducer(persistConfig, rootReducer);
let middleware;

if (process.env.NODE_ENV === "production") {
  middleware = applyMiddleware(thunk);
} else {
  middleware = applyMiddleware(thunk, logger);
}

const store = createStore(pReducer, middleware);
const persistor = persistStore(store);

// If page is refreshed and token still in local storage, re-authorize user to keep user data in state
const token = localStorage.getItem("token");

store.dispatch(getSettings());

if (token) {
  let decodedToken = jwt_decode(token);
  store.dispatch({
    type: "AUTHORIZE_USER",
    userId: decodedToken.userId,
    role: decodedToken.role,
    portalId: decodedToken.portalId,
  });

  store.dispatch(getMakes());
  store.dispatch(getUser());
  store.dispatch(getUserPortal());

  if (decodedToken.role === "MCAdmin") {
    store.dispatch(getPortals());
    store.dispatch(getUsers());
  } else if (decodedToken.role.toLowerCase() === "admin") {
    store.dispatch(getPortalUsers());
  }
}

export { persistor, store };
