import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  getSurveyOrderResult,
  getSurveyResults,
  getSurveyPortalResults,
} from "../../actions/survey";
import SurveyChart from "./SurveyChart";
import SurveyOpenEnds from "./SurveyOpenEnds";
import Button from "../../common/components/Button/Button";
import SurveySingleOrder from "./SurveySingleOrder";
import SurveySinglePortal from "./SurveySinglePortal";

import { Card, Select } from "antd";
import users from "../../reducers/users";

function SurveyResults({ surveys, portals, auth, users }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showSingleOrder, setShowSingleOrder] = useState(false);
  const [orderId, setOrderId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [portalOptions, setPortalOptions] = useState([]);
  const [selectedPortal, setSelectedPortal] = useState(null);
  const [loading, setLoading] = useState(false);
  const [portalResults, setPortalResults] = useState(null);

  useEffect(() => {
    if (!auth) {
      return;
    }

    if (auth && auth.role !== "MCAdmin") {
      setSelectedPortal(auth.portalId || users.user.portalId);
    } else {
      setSelectedPortal(null);
    }
  }, [auth]);

  useEffect(() => {
    setLoading(true);

    if (auth && auth.role === "MCAdmin") {
      dispatch(getSurveyResults());
    } else if (selectedPortal) {
      dispatch(getSurveyPortalResults(selectedPortal));
    }
  }, []);

  // Fetch list of portals to display in dropdown
  useEffect(() => {
    if (portals.portals) {
      let defaultValue = { value: null, label: "All Portals" };

      let options = portals.portals.map((portal) => {
        return { value: portal._id, label: portal.companyName };
      });

      setPortalOptions([defaultValue, ...options]);
    }
  }, [portals]);

  const selectPortal = (portal) => {
    setSelectedPortal(portal);
  };

  useEffect(() => {
    if (selectedPortal == null && auth.role == "MCAdmin") {
      setPortalResults(null);
      setShowSingleOrder(false);
      dispatch(getSurveyResults());
    } else {
      setShowSingleOrder(false);
      dispatch(getSurveyPortalResults(selectedPortal));
    }
  }, [selectedPortal]);

  useEffect(() => {
    if (surveys.portal) {
      setPortalResults(surveys.portal);
    }
  }, [surveys.portal]);

  // Stop loading once results are available
  useEffect(() => {
    if (surveys.results || surveys.portal || surveys.order) {
      setLoading(false);
    }
  }, [surveys.results, surveys.portal, surveys.order]);

  // For single order results:
  useEffect(() => {
    if (id) {
      dispatch(getSurveyOrderResult(id));
    }
  }, [id]);

  useEffect(() => {
    console.log(surveys.order);
    if (surveys.order && surveys.order[0]) {
      setShowSingleOrder(true);
      setOrderId(surveys.order[0].orderId || surveys.order[0]?.order);
    }
  }, [surveys.order]);

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  const handleSubmit = (e) => {
    setShowSingleOrder(true);
    e.preventDefault();

    console.log(searchInput);

    if (!isNaN(parseInt(searchInput))) {
      dispatch(getSurveyOrderResult(searchInput));
      setSearchInput("");
      setOrderId(searchInput);
      setErrorMessage(null);
    } else {
      setErrorMessage("Enter a valid order ID.");
    }
  };

  return (
    <div>
      <Helmet>
        <title>Survey Results</title>
      </Helmet>
      <div id="survey" className="view-content-container">
        <div className="view-header" style={{ textAlign: "left" }}>
          <h1>Customer Satisfaction Surveys</h1>
        </div>

        <Card title="Results" bordered={false} style={{ width: "100%" }}>
          <div className="survey-results-options">
            {auth?.role === "MCAdmin" && (
              <div className="form-input">
                <label htmlFor="enclosedMarkup">Filter by portal</label>
                <Select
                  style={{ width: "200px" }}
                  showSearch
                  value={selectedPortal || "All"}
                  onChange={selectPortal}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={portalOptions}
                />
              </div>
            )}

            <div className="form-input">
              <label htmlFor="orderId">Search by order ID #</label>
              <form
                onSubmit={handleSubmit}
                style={{ display: "flex", margin: "0 auto" }}
              >
                <input
                  type="text"
                  style={{ width: "200px" }}
                  placeholder="Enter ID (e.g. 160200) "
                  value={searchInput}
                  onChange={handleSearchChange}
                />
                <button
                  type="submit"
                  style={{
                    height: "40px",
                    color: "white",
                    marginLeft: "5px",
                  }}
                >
                  Search
                </button>
              </form>
            </div>
          </div>

          {errorMessage && (
            <div
              style={{
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "50px",
              }}
            >
              {errorMessage}
            </div>
          )}

          {surveys.error && showSingleOrder && (
            <div
              style={{
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: "50px",
              }}
            >
              {surveys.error}
            </div>
          )}

          {showSingleOrder && surveys.order && !surveys.error && (
            <SurveySingleOrder orderId={orderId} results={surveys.order} />
          )}

          {surveys.order?.length == 0 && (
            <div style={{ fontWeight: "bold", textAlign: "center" }}>
              No Results
            </div>
          )}
          {showSingleOrder && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "25px",
              }}
            >
              <Button
                label="Show All"
                style="light"
                action={() => {
                  setShowSingleOrder(false);
                  setOrderId(null);
                }}
              />
            </div>
          )}
          {loading && (
            <div
              style={{
                textAlign: "center",
                marginTop: "30px",
                marginBottom: "30px",
              }}
            >
              Loading survey results...
            </div>
          )}
          {/* Show responses for single portal  */}
          {portalResults && !showSingleOrder && (
            <SurveySinglePortal portalResults={portalResults} />
          )}

          {/* Show all results in bar chart */}
          {!showSingleOrder &&
            !portalResults &&
            surveys?.results &&
            surveys.results?.map((data) => {
              return (
                <div>
                  <SurveyChart data={data} />
                  {data.question?.hasExplanation && portals.portals && (
                    <SurveyOpenEnds
                      portals={data.responses}
                      portalsList={portals.portals}
                    />
                  )}
                </div>
              );
            })}
        </Card>
      </div>
    </div>
  );
}

// Provides access to newQuote state
const mapStateToProps = (state) => {
  return {
    surveys: state.surveys,
    auth: state.auth,
    portals: state.portals,
    users: state.users,
  };
};

export default connect(mapStateToProps)(SurveyResults);
