import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { unauthorizeUser } from "../../../actions/auth";
import { filterPortal, getSettings } from "../../../actions/settings";
import { getQuotes } from "../../../actions/quotes";
import { getUsersByPortal, getUsers } from "../../../actions/users";
import { getOrders, clearCSV } from "../../../actions/orders";

import {
  FaBars,
  FaColumns,
  FaUserFriends,
  FaCalculator,
  FaCalendar,
  FaCar,
  FaCogs,
  FaUserCircle,
  FaMoneyBill,
} from "react-icons/fa";

import { RiSurveyFill } from "react-icons/ri";

import { Select } from "antd";

import { IoBarChartSharp } from "react-icons/io5";

const { Option } = Select;

function Sidebar(props) {
  const dispatch = useDispatch();

  const logo =
    "https://res.cloudinary.com/dq27r8cov/image/upload/v1616098001/McCollister%27s/autovista_logo_1.png";
  const [collapsed, setCollapsed] = useState(false);
  const [portals, setPortals] = useState([]);

  let history = useHistory();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(getSettings());
    setLoading(false);
  }, []);

  useEffect(() => {
    if (props.portals.portals) {
      setPortals(props.portals.portals);
    }
  }, [props.portals]);

  const handleSignOut = () => {
    dispatch(unauthorizeUser(() => history.push(`/login`)));
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    if (!props.settings.portal) {
      props.dispatch(filterPortal({ portalId: "all", portalName: "all" }));
    }
  }, [props.settings]);

  const selectPortal = (portalId) => {
    if (portalId === "all") {
      props.dispatch(filterPortal({ portalId: "all", portalName: "all" }));
      dispatch(getQuotes());
      dispatch(getOrders());
      dispatch(getUsers());
      return;
    }

    const selectedPortal = portals.find((portal) => portal._id === portalId);

    if (selectedPortal) {
      const companyName = selectedPortal.companyName;

      props.dispatch(clearCSV());

      const limit = 1500;
      const skip = 0;

      props.dispatch(filterPortal({ portalId, portalName: companyName }));
      props.dispatch(getOrders({ limit, skip, portalId }));
      props.dispatch(getQuotes({ limit, skip, portalId }));
      //props.dispatch(getReports({ portalId }));
      props.dispatch(getUsersByPortal(portalId));
    }
  };

  let sidebarClass = collapsed ? "collapsed" : "open";

  let portalLogo;
  if (props.auth && props.user.portal && props.user.portal?.companyLogo) {
    portalLogo = props.user.portal?.companyLogo;
  } else {
    portalLogo = logo;
  }
  return (
    <div
      className={`${
        loading
          ? "sidebar " + sidebarClass + "loading"
          : "sidebar " + sidebarClass
      }`}
    >
      <div className="sidebar-header">
        {portalLogo && (
          <img
            className="sidebar-logo"
            src={portalLogo}
            alt="AutoVista Portal Logo"
          />
        )}
        <button className="menu-button" onClick={toggleSidebar}>
          <FaBars />
        </button>
      </div>
      {props.user && (
        <ul className="sidebar-menu">
          <li className="sidebar-item" key="get-quote">
            <NavLink to="/get-quote" activeStyle={{ fontWeight: "bold" }}>
              <div className="sidebar-button-link">
                <span className="button-icon">
                  <FaCalculator />
                </span>
                <span className="sidebar-label">Get Quote</span>
              </div>
            </NavLink>
          </li>

          {props.auth.role === "MCAdmin" && (
            <div>
              <p
                style={{
                  fontSize: "12px",
                  marginBottom: "2px",
                  marginTop: "0px",
                }}
              >
                View As:
              </p>

              {portals && (
                <Select
                  value={props.settings.portal || "all"}
                  onChange={selectPortal}
                  style={{ width: 200, marginBottom: "15px" }}
                  placeholder="Select Portal"
                >
                  <Option key="all" value="all">
                    All (Administrator)
                  </Option>
                  {portals.map((portal) => (
                    <Option key={portal._id} value={portal._id}>
                      {portal.companyName}
                    </Option>
                  ))}
                </Select>
              )}

              <li className="sidebar-item" key="account">
                <NavLink to="/account">
                  <span className="sidebar-icon">
                    <FaUserCircle />
                  </span>
                  <span className="sidebar-label">Account</span>
                </NavLink>
              </li>

              {props.auth.role === "MCAdmin" &&
                (!props.settings.portal ||
                  props.settings.portal === "all" ||
                  props.settings.portal === "5e98963fed92842f43fadc53") && (
                  <li className="sidebar-item" key="cod">
                    <NavLink to="/cod">
                      <span className="sidebar-icon">
                        <FaMoneyBill />
                      </span>
                      <span className="sidebar-label">COD</span>
                    </NavLink>
                  </li>
                )}

              {props.auth?.role === "MCAdmin" && (
                <li className="sidebar-item" key="orders">
                  <NavLink to="/orders">
                    <span className="sidebar-icon">
                      <FaCalendar />
                    </span>
                    <span className="sidebar-label">Orders</span>
                  </NavLink>
                </li>
              )}

              {(props.settings.portal === undefined ||
                props.settings.portal === "all" ||
                props.settings.portal === "5e98963fed92842f43fadc53") && (
                <li className="sidebar-item" key="portals">
                  <NavLink to="/portals">
                    <span className="sidebar-icon">
                      <FaColumns />
                    </span>
                    <span className="sidebar-label">Portals</span>
                  </NavLink>
                </li>
              )}
            </div>
          )}

          {props.portal && props.auth.role !== "MCAdmin" && (
            <li className="sidebar-item" key="account">
              <NavLink to="/account">
                <span className="sidebar-icon">
                  <FaUserCircle />
                </span>
                <span className="sidebar-label">Account</span>
              </NavLink>
            </li>
          )}

          {props.auth?.role !== "MCAdmin" && (
            <li className="sidebar-item" key="orders">
              <NavLink to="/orders">
                <span className="sidebar-icon">
                  <FaCalendar />
                </span>
                <span className="sidebar-label">Orders</span>
              </NavLink>
            </li>
          )}

          <li className="sidebar-item" key="quotes">
            <NavLink to="/quotes">
              <span className="sidebar-icon">
                <FaCar />
              </span>
              <span className="sidebar-label">Quotes</span>
            </NavLink>
          </li>

          {props.auth.role === "MCAdmin" && (
            <li className="sidebar-item" key="reports">
              <NavLink to="/reports">
                <span className="sidebar-icon">
                  <IoBarChartSharp />
                </span>
                <span className="sidebar-label">Reports</span>
              </NavLink>
            </li>
          )}

          {props.auth.role !== "User" && (
            <li className="sidebar-item" key="survey">
              <NavLink to="/survey">
                <span className="sidebar-icon">
                  <RiSurveyFill />
                </span>
                <span className="sidebar-label">Surveys</span>
              </NavLink>
            </li>
          )}

          {(props.auth.role === "MCAdmin" ||
            (props.auth.role && props.auth.role.toLowerCase() === "admin")) && (
            <li className="sidebar-item" key="users">
              <NavLink to="/users">
                <span className="sidebar-icon">
                  <FaUserFriends />
                </span>
                <span className="sidebar-label">Users</span>
              </NavLink>
            </li>
          )}

          {props.auth?.role === "Admin" && (
            <li className="sidebar-item" key="portal-settings">
              <NavLink to={`/portal/${props.auth.portalId}/settings`}>
                <span className="sidebar-icon">
                  <FaCogs />
                </span>
                <span className="sidebar-label">Settings</span>
              </NavLink>
            </li>
          )}

          {props.auth?.role === "MCAdmin" &&
            props.user?.isSuperAdmin &&
            (!props.settings.portal ||
              props.settings.portal === "all" ||
              props.settings.portal === "5e98963fed92842f43fadc53") && (
              <li className="sidebar-item" key="settings">
                <NavLink to="/settings">
                  <span className="sidebar-icon">
                    <FaCogs />
                  </span>
                  <span className="sidebar-label">Settings</span>
                </NavLink>
              </li>
            )}

          <li key="sign-out">
            <span
              className="sidebar-label sidebar-link"
              onClick={handleSignOut}
            >
              Sign Out
            </span>
          </li>
        </ul>
      )}
    </div>
  );
}

// Provides access to newQuote state
const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.users.user,
    portal: state.portals.portal,
    portals: state.portals,
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(Sidebar);
