import React from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import { GoogleOAuthProvider } from "@react-oauth/google";
import TagManager from "react-gtm-module";

import * as serviceWorker from "./serviceWorker";

const tagManagerConfig = {
  gtmId: "GTM-WJJPG2",
};

TagManager.initialize(tagManagerConfig);

ReactDOM.render(
  <GoogleOAuthProvider clientId="1004603581648-2eqij9qrdj0e3n41p02l5v1hmpspipe2.apps.googleusercontent.com">
    <App />
  </GoogleOAuthProvider>,

  document.getElementById("root")
);

serviceWorker.unregister();
