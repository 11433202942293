import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";

import { Form, Card, Input, Select } from "antd";
import Button from "../../common/components/Button/Button";
import { MaskedInput } from "antd-mask-input";
import { connect, useDispatch } from "react-redux";
import Alert from "../../common/components/Alert/Alert";
import { useHistory } from "react-router-dom";

import {
  getUser,
  updateUser,
  updateUserStatus,
  updateUserSuccess,
} from "../../actions/users";

function UsersFormEdit(props) {
  let { userId } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setUser] = useState(null);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    dispatch(getUser(userId));
  }, []);

  useEffect(() => {
    if (props.users.userToEdit) {
      setUser(props.users.userToEdit);
    }
  }, [props.users]);

  const [portalsList, setPortalsList] = useState([]);

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  useEffect(() => {
    if (props.portals && props.portals.portals) {
      setPortalsList(
        props.portals.portals.map((p) => {
          return <Select.Option value={p._id}>{p.companyName}</Select.Option>;
        })
      );
    }
  }, [props.portals]);

  const onFinish = (values) => {
    values.userId = user._id;
    props.close();
    props.dispatch(updateUser(values, null));
  };

  if (user) {
    return (
      <>
        <Helmet>
          <title>Update User</title>
        </Helmet>
        <div className="view-users view-content-container">
          <Card title="Update User" bordered={false} style={{ width: "100%" }}>
            <Form
              form={form}
              layout="horizontal"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 10 }}
              className="user-form"
              validateMessages={validateMessages}
              onFinish={onFinish}
              initialValues={{
                portalId: user.portalId,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phone: user.phone,
                mobilePhone: user.mobilePhone,
                role: user.role,
              }}
            >
              <Form.Item label="Company" name="portalId">
                <Select>{portalsList}</Select>
              </Form.Item>

              <Form.Item label="First Name" name="firstName">
                <Input />
              </Form.Item>

              <Form.Item label="Last Name" name="lastName">
                <Input />
              </Form.Item>

              <Form.Item label="Role" name="role">
                <Select>
                  <Select.Option value="User">User</Select.Option>
                  <Select.Option value="Admin">Admin</Select.Option>
                  <Select.Option value="MCAdmin">
                    McCollister's Admin
                  </Select.Option>
                </Select>
              </Form.Item>

              <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
                <Input />
              </Form.Item>

              <Form.Item label="Temporary Password" name="password">
                <Input.Password />
              </Form.Item>

              {props.users.error && typeof props.users.error !== "object" && (
                <div className="form-server-response-error">
                  <h3>{props.users.error}</h3>
                </div>
              )}

              <Form.Item label="Phone" name="phone">
                <MaskedInput mask={"(000) 000-0000"} />
              </Form.Item>

              <Form.Item label="Mobile Phone" name="mobilePhone">
                <MaskedInput mask={"(000) 000-0000"} />
              </Form.Item>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button label="Submit" style="dark" type="submit" />

                <Button
                  label="Delete User"
                  style="delete"
                  type="button"
                  action={() => setShowAlert(true)}
                />
              </div>
            </Form>
          </Card>
        </div>

        {showAlert && (
          <Alert
            text="Are you sure you want to delete this user?"
            cancel={() => {
              setShowAlert(false);
            }}
            action={() => {
              dispatch(
                updateUser({ userId, status: "Archived" }, () => {
                  history.push("/users");
                })
              );

              setShowAlert(false);
            }}
          />
        )}
      </>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    portals: state.portals,
    users: state.users,
  };
};

export default connect(mapStateToProps)(UsersFormEdit);
