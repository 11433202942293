import axios from "axios";
import {
  CREATE_QUOTE,
  REMOVE_QUOTE,
  UPDATE_QUOTE,
  GET_QUOTES,
  GET_QUOTE,
  QUOTE_ERROR,
  CLEAR_QUOTE_ERROR,
} from "./types";

import { authError } from "./auth";
import { rootUrl } from "../config/keys";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};

export const getQuotes = (data, callback) => {
  if (data && data.sortBy) {
    data.sortorder = data.sortBy && data.sortBy.desc === true ? -1 : 1;
    data.sortby = data.sortBy.key;
  } else {
    data.sortorder = -1;
    data.sortby = "uniqueId";
  }

  let uri = `${rootUrl}/quotes`;

  return (dispatch) => {
    dispatch(clearQuoteError());
    return axios
      .post(uri, data, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (callback) {
          callback();
        }
        dispatch(getQuotesSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(quoteError(err.response.data.message));
          }
        } else {
          dispatch(quoteError("There was an error getting quotes."));
        }
      });
  };
};

// Get a single quote
export const getQuote = (quoteId) => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/quote/${quoteId}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getQuoteSuccess(data));
        dispatch(clearQuoteError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(quoteError(err.response.data.message));
          }
        } else {
          dispatch(quoteError("There was an error getting this quote."));
        }
      });
  };
};

export const getQuotePublic = (quoteId) => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/quote/public/${quoteId}/app`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getQuoteSuccess(data));
        dispatch(clearQuoteError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(quoteError(err.response.data.message));
          }
        } else {
          dispatch(quoteError("There was an error getting this quote."));
        }
      });
  };
};

// Create a new quote
export const createQuote = (formData, history, callback) => {
  return (dispatch) => {
    dispatch(clearQuoteError());
    return axios
      .post(`${rootUrl}/quote`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (callback) {
          callback();
        }

        dispatch(createQuoteSuccess(data));

        let quoteId;
        if (data._id) {
          quoteId = data._id;
        } else {
          quoteId = data.id;
        }

        if (!data.isCustomerPortal) {
          history.push(`/quote/${quoteId}`);
        } else {
          history.push(`/quote/public/${quoteId}`);
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(quoteError(err.response.data.message));
          }
        } else {
          dispatch(
            quoteError(
              "Whoops! We seem to be having an issue calculating your quote. Please email us at autologistics@mccollisters.com and we will be happy to assist."
            )
          );
        }
      });
  };
};

// Create a new quote for customer (does not require API auth)
export const createQuotePublic = (formData, history, callback) => {
  return (dispatch) => {
    dispatch(clearQuoteError());
    return axios
      .post(`${rootUrl}/quote/public`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (callback) {
          callback();
        }

        dispatch(createQuoteSuccess(data));

        let quoteId;
        if (data._id) {
          quoteId = data._id;
        } else {
          quoteId = data.id;
        }

        if (!data.isCustomerPortal) {
          history.push(`/quote/${quoteId}`);
        } else {
          history.push(`/quote/public/${quoteId}`);
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(quoteError(err.response.data.message));
          }
        } else {
          dispatch(
            quoteError(
              "Whoops! We seem to be having an issue calculating your quote. Please email us at autologistics@mccollisters.com and we will be happy to assist."
            )
          );
        }
      });
  };
};

export const updateTransportOptions = (formData, history, callback) => {
  return (dispatch) => {
    dispatch(clearQuoteError());
    return axios
      .post(`${rootUrl}/quote/transport`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (callback) {
          callback();
        }

        dispatch(createQuoteSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(quoteError(err.response.data.message));
          }
        } else {
          dispatch(
            quoteError(
              "Whoops! We seem to be having an issue calculating your quote. Please email us at autologistics@mccollisters.com and we will be happy to assist."
            )
          );
        }
      });
  };
};

export const publicCreateQuote = (formData, history) => {
  return (dispatch) => {
    dispatch(clearQuoteError());
    return axios
      .post(`${rootUrl}/public/quote`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(createQuoteSuccess(data));
        let quoteId;
        if (data._id) {
          quoteId = data._id;
        } else {
          quoteId = data.id;
        }
        history.push(`/quote/public/${quoteId}`);
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(quoteError(err.response.data.message));
          }
        } else {
          dispatch(
            quoteError(
              "Whoops! We seem to be having an issue calculating your quote. Please email us at autologistics@mccollisters.com and we will be happy to assist."
            )
          );
        }
      });
  };
};

// export const updateQuoteStatus = (quoteId, status, callback = null) => {
//   return (dispatch) => {
//     return axios
//       .put(
//         `${rootUrl}/quote/${quoteId}/status`,
//         { status },
//         {
//           headers: { Authorization: "JWT " + localStorage.getItem("token") },
//         }
//       )
//       .then((response) => {
//         return response.data;
//       })
//       .then((data) => {
//         dispatch(updateQuoteSuccess(data));
//         dispatch(clearQuoteError());
//         if (callback) {
//           callback();
//         }
//       })
//       .catch((err) => {
//         if (err && err.response) {
//           if (err.response.status === 401) {
//             dispatch(authError(err.response.data.message));
//           } else {
//             dispatch(quoteError(err.response.data.message));
//           }
//         } else {
//           dispatch(
//             quoteError("There was an error getting updating this quote.")
//           );
//         }
//       });
//   };
// };

export const updateQuote = (updates, callback) => {
  return (dispatch) => {
    return axios
      .put(`${rootUrl}/quote`, updates, config())

      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(updateQuoteSuccess(data));
        dispatch(clearQuoteError());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(quoteError(err.response.data.message));
          }
        } else {
          dispatch(
            quoteError("There was an error getting updating this quote.")
          );
        }
      });
  };
};

export const createQuoteSuccess = (data) => ({
  type: CREATE_QUOTE,
  quote: data,
});

export const updateQuoteSuccess = (data) => ({
  type: UPDATE_QUOTE,
  updatedQuote: data,
});

export const removeQuote = (quoteId) => ({
  type: REMOVE_QUOTE,
  quoteId: quoteId,
});

export const getQuoteSuccess = (data) => ({
  type: GET_QUOTE,
  quote: data,
});

export const getQuotesSuccess = (data) => ({
  type: GET_QUOTES,
  quotes: data,
});

export function quoteError(error) {
  return {
    type: QUOTE_ERROR,
    payload: error,
  };
}

export function clearQuoteError() {
  return {
    type: CLEAR_QUOTE_ERROR,
  };
}
