import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { Form, Input, Button } from "antd";

import { forgotPassword, authError } from "../../actions/auth";

function ForgotPasswordForm(props) {
  const [success, setSuccess] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  if (props.auth.authenticated) {
    history.push("/get-quote");
  }

  const onFinish = (values) => {
    let isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      values.email
    );

    if (!isValidEmail) {
      dispatch(authError("Please enter a valid email address."));
      return;
    }

    props.dispatch(
      forgotPassword(values, () => {
        setSuccess(true);
      })
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="auth-form"
      onFinish={onFinish}
    >
      {success && (
        <p style={{ textAlign: "center" }}>
          Please check your email for a link to reset your password.
        </p>
      )}

      {!success && (
        <>
          <Form.Item label="Email" name="email">
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              style={{
                borderRadius: "3px",
                cursor: "pointer",
                width: "100%",
              }}
              type="primary"
              htmlType="submit"
            >
              Request Password Reset
            </Button>
          </Form.Item>
        </>
      )}
      <div className="auth-links-container">
        <Link to="/login" onClick={() => props.returnToLogin()}>
          Log In
        </Link>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ForgotPasswordForm);
