import React, { useState, useEffect } from "react";
import { MdOutlineGarage } from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import { getQuotePublic } from "../../actions/quotes";
import Spinner from "../../common/components/Spinner/Spinner.js";
import { getSettings } from "../../actions/settings.js";

import Alert from "../../common/components/Alert/Alert.js";
import { PDFLink } from "../../common/components/PDFDownload/PDFDownload";

function QuoteDetailPublic({ quote, settings, isOrder }) {
  const { quoteId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [showSpinner, setShowSpinner] = useState(false);
  const [confirmation, setConfirmation] = useState({});
  const [numVehicles, setNumVehicles] = useState();
  const [discount, setDiscount] = useState();

  useEffect(() => {
    dispatch(getQuotePublic(quoteId));
  }, [quoteId]);

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (quote) {
      setNumVehicles(quote.vehicleQuotes.length);

      if (quote.portalAdminDiscount > 0) {
        setDiscount(quote.portalAdminDiscount);
      } else {
        setDiscount(0);
      }
    }
  }, [quote]);

  if (quote) {
    let quotePortal, PDFlogo;

    if (quotePortal && quotePortal.displayMCLogo) {
      PDFlogo =
        "https://res.cloudinary.com/dq27r8cov/image/upload/v1616097775/McCollister%27s/mccollisters-auto-logistics.png";
    } else if (quote.companyLogo) {
      PDFlogo = quote.companyLogo;
    } else {
      PDFlogo =
        "https://res.cloudinary.com/dq27r8cov/image/upload/v1616097775/McCollister%27s/mccollisters-auto-logistics.png";
    }

    const handleBookOrder = () => {
      history.push(`/quote/public/${quote._id}/book`);
    };

    const renderVehiclePrices = (quote, calculatedQuotes) => {
      if (!calculatedQuotes) {
        return <p></p>;
      }

      let discount = quote.portalAdminDiscount || 0;

      const displayPriceWithDiscount = (price, discount) => {
        return (
          <>
            {discount > 0 && (
              <span className="strikethrough">
                ${Math.ceil(discount + price)}
              </span>
            )}
            ${Math.ceil(price)}
          </>
        );
      };

      if (quote.transportType !== "WHITEGLOVE") {
        return (
          <>
            <div className="table-item table-double-item">
              {/* 1-Day Pickup */}
              <div className="open-price">
                {displayPriceWithDiscount(
                  calculatedQuotes[0].openTransportPortal,
                  discount
                )}
              </div>
              <div className="encl-price">
                {displayPriceWithDiscount(
                  calculatedQuotes[0].enclosedTransportPortal,
                  discount
                )}
              </div>
            </div>

            {/* 3-Day Pickup */}
            <div className="table-item table-double-item">
              <div className="open-price">
                {displayPriceWithDiscount(
                  calculatedQuotes[1].openTransportPortal,
                  discount
                )}
              </div>
              <div className="encl-price">
                {displayPriceWithDiscount(
                  calculatedQuotes[1].enclosedTransportPortal,
                  discount
                )}
              </div>
            </div>

            {/* 5-Day Pickup */}
            <div className="table-item table-double-item">
              <div className="open-price">
                {displayPriceWithDiscount(
                  calculatedQuotes[2].openTransportPortal,
                  discount
                )}
              </div>
              <div className="encl-price">
                {displayPriceWithDiscount(
                  calculatedQuotes[2].enclosedTransportPortal,
                  discount
                )}
              </div>
            </div>

            {/* 7-Day Pickup */}
            <div className="table-item table-double-item">
              <div className="open-price">
                {displayPriceWithDiscount(
                  calculatedQuotes[3].openTransportPortal,
                  discount
                )}
              </div>
              <div className="encl-price">
                {displayPriceWithDiscount(
                  calculatedQuotes[3].enclosedTransportPortal,
                  discount
                )}
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="table-item">
              {displayPriceWithDiscount(
                calculatedQuotes[0].totalPortal,
                discount
              )}
            </div>
          </>
        );
      }
    };

    const TotalItem = ({ quote, index }) => (
      <div className="table-item total-item table-double-item">
        <div className="open-price">
          ${quote.totalPricing[index].totalOpenTransportPortal}
        </div>
        <div className="encl-price">
          ${quote.totalPricing[index].totalEnclosedTransportPortal}
        </div>
      </div>
    );

    const TotalItems = ({ quote }) => (
      <>
        {[1, 3, 5, 7].map((index) => (
          <TotalItem key={index} quote={quote} index={index.toString()} />
        ))}
      </>
    );

    const transportTypeValue = () => {
      if (quote.transportType === "ENCLOSED") {
        return (
          <span>
            Enclosed (Gold) <MdOutlineGarage />
          </span>
        );
      } else if (quote.transportType === "WHITEGLOVE") {
        return (
          <span>
            Enclosed White Glove (Platinum) <MdOutlineGarage />
          </span>
        );
      } else {
        return <span>Open (Silver)</span>;
      }
    };

    return (
      <div className="quote-detail view-content-container">
        {confirmation.message && (
          <Alert
            text={confirmation.message}
            action={confirmation.clickHandler}
          />
        )}

        {showSpinner && <Spinner />}

        <div className="view-header">
          <h1>QUOTE: #{quote.uniqueId}</h1>
        </div>

        <div className="quote-detail-container">
          <div className="quote-detail-info">
            <p>Thank you for the opportunity to serve you.</p>
            <p>All of the pricing options below include:</p>
            <ul>
              <li>Door-to-door service for both pick up and delivery.</li>
              <li>
                Guaranteed on time delivery (rental car provided in event of
                driver caused delay on delivery).
              </li>
              <li>Insurance coverage included.</li>
              <li>All taxes and fees are included in this price quote.</li>
              {quote.transportType === "ENCLOSED" && (
                <li className="color-red">
                  Please note that for enclosed transport, in order to meet your
                  required pick up date, we reserve the right to use a flatbed
                  to pick up the vehicle and bring it to a secure terminal
                  location while awaiting arrival of the enclosed transport
                  truck.
                </li>
              )}
            </ul>
            {quote.transportType === "WHITEGLOVE" && (
              <p>
                Final pickup scheduling will be coordinated by McCollister
                customer service team next business day​.
              </p>
            )}
          </div>

          {settings && (
            <div className="pickup-delivery-details">
              <div className="row">
                <span className="row-label">Customer:</span>
                <span>{quote.customerFullName}</span>
              </div>

              <div className="row">
                <span className="row-label">Email:</span>
                <span>{quote.customerEmail}</span>
              </div>

              <div className="row">
                <span className="row-label">Phone:</span>
                <span>{quote.customerPhone}</span>
              </div>

              <div className="row">
                <span className="row-label">Miles:</span>
                <span>{quote.miles}</span>
              </div>

              <div className="row">
                <span className="row-label">Transport Type:</span>
                {transportTypeValue()}
              </div>

              {quote.transitTime && !isNaN(parseInt(quote.transitTime[0])) && (
                <div className="row">
                  <span className="row-label">Transit Time:</span>
                  <span>
                    {quote.transitTime[0]} - {quote.transitTime[1]} Days
                  </span>
                </div>
              )}

              <div className="row">
                <span className="row-label">Pickup:</span>
                <span>{quote.pickup}</span>
              </div>

              <div className="row">
                <span className="row-label">Delivery:</span>
                <span>{quote.delivery}</span>
              </div>

              <div className="row full-row">
                <div className="pricing-detail">
                  {quote.transportType !== "WHITEGLOVE" && (
                    <div
                      className={`table table-header pricing-detail-table ${quote.transportType ===
                        "WHITEGLOVE" && "white-glove"}`}
                    >
                      <div className="table-item"></div>

                      {quote.transportType !== "WHITEGLOVE" &&
                        settings.serviceLevels.map((serviceLevel) => {
                          return (
                            <div key={serviceLevel.name} className="table-item">
                              {serviceLevel.name}
                            </div>
                          );
                        })}
                    </div>
                  )}

                  {quote.transportType !== "WHITEGLOVE" && (
                    <div
                      className={`table table-row pricing-detail-table ${quote.transportType ===
                        "WHITEGLOVE" && "hide-white-glove"}`}
                    >
                      <div className="table-item"></div>
                      <div className="table-item table-double-item">
                        <div>
                          <strong>Open</strong>
                        </div>
                        <div>
                          <strong>Encl.</strong>
                        </div>
                      </div>
                      <div className="table-item table-double-item">
                        <div>
                          <strong>Open</strong>
                        </div>
                        <div>
                          <strong>Encl.</strong>
                        </div>
                      </div>
                      <div className="table-item table-double-item">
                        <div>
                          <strong>Open</strong>
                        </div>
                        <div>
                          <strong>Encl.</strong>
                        </div>
                      </div>
                      <div className="table-item table-double-item">
                        <div>
                          <strong>Open</strong>
                        </div>
                        <div>
                          <strong>Encl.</strong>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className={`table table-row pricing-detail-table ${quote.transportType ===
                      "WHITEGLOVE" && "white-glove"}`}
                  >
                    <div className="table-item">Total</div>

                    {quote.transportType !== "WHITEGLOVE" &&
                      settings.serviceLevels.map((serviceLevel) => {
                        let preDiscountOpen, preDiscountEncl;

                        let openTotalPrice =
                          quote.totalPricing[serviceLevel.value]
                            .totalOpenTransportPortal;
                        let enclTotalPrice =
                          quote.totalPricing[serviceLevel.value]
                            .totalEnclosedTransportPortal;

                        if (discount) {
                          preDiscountOpen =
                            openTotalPrice + discount * numVehicles;
                          preDiscountEncl =
                            enclTotalPrice + discount * numVehicles;
                        }

                        return (
                          <div
                            key={serviceLevel.value}
                            className="table-item table-double-item"
                          >
                            <div className="open-price">
                              {preDiscountOpen && (
                                <span className="strikethrough">
                                  ${Math.ceil(preDiscountOpen)}
                                </span>
                              )}
                              ${Math.ceil(openTotalPrice)}
                            </div>
                            <div className="encl-price">
                              {preDiscountEncl && (
                                <span className="strikethrough">
                                  ${preDiscountEncl}
                                </span>
                              )}
                              ${Math.ceil(enclTotalPrice)}
                            </div>
                          </div>
                        );
                      })}

                    {quote.transportType === "WHITEGLOVE" && (
                      <div className="table-item">
                        {discount > 0 && (
                          <span className="strikethrough">
                            $
                            {Math.ceil(
                              quote.totalPricing[1].totalPortal +
                                discount * numVehicles
                            )}
                          </span>
                        )}
                        ${Math.ceil(quote.totalPricing[1].totalPortal)}
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="action-row">
                {!isOrder && (
                  <button className="form-submit" onClick={handleBookOrder}>
                    Book Order
                  </button>
                )}

                {!isOrder && quote && (
                  <button className="form-submit">
                    <PDFLink
                      className="download-link"
                      quote={quote}
                      logo={PDFlogo}
                    />
                  </button>
                )}
              </div>
            </div>
          )}

          {settings && settings && (
            <div className="quote-detail-vehicles">
              <div
                className={`table table-header quote-detail-table ${quote.transportType ===
                  "WHITEGLOVE" && "white-glove"}`}
              >
                <div className="table-item">Make</div>
                <div className="table-item">Model</div>
                <div className="table-item">Class</div>
                <div className="table-item">Operable</div>

                {quote.transportType === "WHITEGLOVE" && (
                  <div className="table-item">Quote</div>
                )}

                {quote.transportType !== "WHITEGLOVE" &&
                  settings &&
                  settings &&
                  settings.serviceLevels.map((serviceLevel) => {
                    return (
                      <div key={serviceLevel.name} className="table-item">
                        {serviceLevel.name}
                      </div>
                    );
                  })}
              </div>

              <div
                className={`table table-header quote-detail-table ${quote.transportType ===
                  "WHITEGLOVE" && "white-glove"}`}
              >
                <div
                  style={{ borderBottom: "none" }}
                  className="table-item"
                ></div>
                <div
                  style={{ borderBottom: "none" }}
                  className="table-item"
                ></div>
                <div
                  style={{ borderBottom: "none" }}
                  className="table-item"
                ></div>
                <div
                  style={{ borderBottom: "none" }}
                  className="table-item"
                ></div>

                {quote.transportType !== "WHITEGLOVE" &&
                  settings &&
                  settings &&
                  settings.serviceLevels.map((serviceLevel) => {
                    return (
                      <div
                        key={serviceLevel.name}
                        className="table-item table-double-item"
                      >
                        <div>
                          <strong>Open</strong>
                        </div>
                        <div>
                          <strong>Encl.</strong>
                        </div>
                      </div>
                    );
                  })}
              </div>

              {quote.vehicleQuotes.map((vehicle, i) => {
                console.log(vehicle.calculatedQuotes);
                return (
                  <div
                    key={vehicle.model + vehicle.make + i}
                    className={`table table-row quote-detail-table ${quote.transportType ===
                      "WHITEGLOVE" && "white-glove"}`}
                  >
                    <div className="table-item">{vehicle.make}</div>
                    <div className="table-item">{vehicle.model}</div>
                    <div className="table-item">{vehicle.pricingClass}</div>
                    <div className="table-item">
                      {vehicle.operableBool ? "Yes" : "No"}
                    </div>

                    {renderVehiclePrices(quote, vehicle.calculatedQuotes)}
                  </div>
                );
              })}
              {quote.vehicleQuotes.length > 1 && (
                <div
                  className={`table quote-detail-table ${quote.transportType ===
                    "WHITEGLOVE" && "white-glove"}`}
                >
                  <div className="table-item"></div>
                  <div className="table-item"></div>
                  <div className="table-item"></div>
                  <div className="table-item">
                    <strong>Total:</strong>
                  </div>

                  {quote.transportType === "WHITEGLOVE" && (
                    <>
                      <div className="table-item total-item">
                        ${Math.ceil(quote.totalPricing["1"].totalPortal)}
                      </div>
                    </>
                  )}

                  {quote.transportType !== "WHITEGLOVE" && (
                    <TotalItems quote={quote} />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    portal: state.portals.portal,
    portals: state.portals.portals,
    quote: state.quotes.quote,
    quotes: state.quotes,
    settings: state.settings.settings,
  };
};

export default connect(mapStateToProps)(QuoteDetailPublic);
