import React from "react";

import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
  },
  title: {
    textDecoration: "underline",
    marginTop: 10,
    marginBottom: 5,
    fontSize: 10,
    color: "#505050",
  },
  text: {
    marginTop: 10,
    marginBottom: 5,
    fontSize: 10,
    color: "#505050",
  },
  bulleteditems: {
    fontSize: 10,
    color: "#505050",
  },
});

export const PDFQuoteBulletedInfo = (props) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>All Pricing Includes:</Text>
      <Text style={styles.bulleteditems}>
        • Door-to-door service when applicable
      </Text>
      <Text style={styles.bulleteditems}>
        • Coordinated pickup and delivery
      </Text>
      <Text style={styles.bulleteditems}>
        • Taxes and fees included in your AutoVista quote
      </Text>

      <Text style={styles.text}>Call for Assistance: (888) 819-0594</Text>
    </View>
  );
};
