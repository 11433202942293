import React, { useState } from "react";
import { Helmet } from "react-helmet";
import SurveyForm from "./SurveyForm.js";

function Survey(props) {
  const [formComplete, setFormComplete] = useState(false);
  const logo =
    "https://res.cloudinary.com/dq27r8cov/image/upload/v1616097775/McCollister%27s/mcc-logo-white.png";

  return (
    <div>
      <Helmet>
        <title>Customer Survey</title>
      </Helmet>
      <div id="survey" className="view-content-container">
        <div className="view-header">
          <img className="login-logo" src={logo} alt="logo" />

          <h1>Customer Satisfaction Survey</h1>
        </div>
        {!formComplete && (
          <SurveyForm formComplete={() => setFormComplete(true)} />
        )}

        {formComplete && (
          <div className="thank-you-container">
            Thank you for your feedback!
          </div>
        )}
      </div>
    </div>
  );
}

export default Survey;
