import axios from "axios";

import {
  UPLOAD_FILE,
  CLEAR_FILES,
  UPLOAD_LOGO,
  ADD_ORDER_FILES,
  REMOVE_ORDER_FILE,
  FILE_ERROR,
  CLEAR_FILE_ERROR,
} from "./types";

import { authError } from "./auth";
import { rootUrl } from "../config/keys";

const config = {
  headers: { Authorization: "JWT " + localStorage.getItem("token") },
};

export const uploadFile = (file, fileParts, callback) => {
  let fileRoot = fileParts[0];
  let fileName = fileParts[0] + Date.now();
  let fileType = fileParts[1];
  let fileKey = fileParts[0] + Date.now();

  return (dispatch) => {
    return axios
      .post(`${rootUrl}/sign_s3`, {
        fileName: fileName,
        fileType: fileType,
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        var signedRequest = data.data.signedRequest;
        var url = data.data.url;

        var options = {
          headers: {
            "Content-Type": fileType,
          },
        };

        axios
          .put(signedRequest, file, options, url)
          .then((result) => {
            dispatch(uploadFileSuccess(fileRoot, fileKey, url));
            if (callback) {
              callback();
            }
          })
          .catch((error) => {
            console.log(error);
            dispatch(fileError("There was an error uploading this file."));
          });
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(fileError(err.response.data.message));
          }
        } else {
          dispatch(fileError("There was an error uploading this file."));
        }
      });
  };
};

// https://blog.logrocket.com/handling-images-with-cloudinary-in-react/

export const uploadLogo = (file, fileParts) => {
  let fileName = fileParts[0];
  let fileType = fileParts[1];

  return (dispatch) => {
    const formData = new FormData();
    formData.append("file", file);
    // replace this with your upload preset name
    formData.append("upload_preset", "saho5twn");
    const options = {
      method: "POST",
      body: formData,
    };

    return fetch(
      "https://api.Cloudinary.com/v1_1/dq27r8cov/image/upload",
      options
    )
      .then((res) => res.json())
      .then((res) => {
        dispatch(uploadLogoSuccess(res.secure_url));
      })
      .catch((err) => console.log(err));
  };
};

export const addOrderFiles = (orderId, files) => {
  return (dispatch) => {
    return axios
      .put(`${rootUrl}/order/${orderId}/files`, files, config)
      .then((response) => {
        dispatch(addOrderFilesSuccess(response.data));
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  };
};

export const removeOrderFile = (orderId, url) => {
  return (dispatch) => {
    return axios
      .put(
        `${rootUrl}/order/${orderId}/file`,
        { url },
        {
          headers: { Authorization: "JWT " + localStorage.getItem("token") },
        }
      )
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearFileError());
        dispatch(removeOrderFileSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(fileError(err.response.data.message));
          }
        } else {
          dispatch(fileError("There was an error removing this file."));
        }
      });
  };
};

export const clearFiles = () => ({
  type: CLEAR_FILES,
});

export const uploadFileSuccess = (fileRoot, fileKey, url) => ({
  type: UPLOAD_FILE,
  file: {
    name: fileRoot,
    key: fileKey,
    url,
  },
});

export const uploadLogoSuccess = (logo) => ({
  type: UPLOAD_LOGO,
  logo,
});

export const addOrderFilesSuccess = (data) => ({
  type: ADD_ORDER_FILES,
  order: data,
});

export const removeOrderFileSuccess = (data) => ({
  type: REMOVE_ORDER_FILE,
  order: data,
});

export function fileError(error) {
  return {
    type: FILE_ERROR,
    payload: error,
  };
}

export function clearFileError() {
  return {
    type: CLEAR_FILE_ERROR,
  };
}
