import React, { memo, useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink
} from "@react-pdf/renderer";

import { PDFHeader } from "./pdf-header";
import { PDFQuoteDetails } from "./pdf-quote-details";
import { PDFPricingTableContainer } from "./pdf-table";
import { PDFVehicles } from "./pdf-vehicles";

const styles = StyleSheet.create({
  mainpage: {
    fontFamily: "Helvetica",
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 30
  },

  container: {
    flex: 1,
    flexDirection: "row"
  },

  leftColumn: {
    flexDirection: "column",
    width: 180,
    paddingRight: 15
  },
  title: {
    fontSize: 16,
    color: "#202020",
    marginBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: "#808080"
  },
  subTitle: {
    fontSize: 14,
    color: "#202020",
    marginBottom: 15,
    borderBottomWidth: 1,
    borderBottomColor: "#808080"
  },
  tableTitle: {
    fontSize: 16,
    textAlign: "left"
  },
  footer: {
    fontSize: 12,
    fontFamily: "Helvetica",
    textAlign: "center",
    marginTop: 25,
    paddingTop: 5,
    borderWidth: 3,
    borderColor: "#DCDCDC",
    borderStyle: "dashed"
  }
});

const PDFLinkFirst = props => {
  let quote = props.quote;
  let logo = props.logo;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(false);
    setLoading(true);
    return () => setLoading(false);
  }, []);

  return (
    <>
      {loading && (
        <PDFDownloadLink
          document={
            <Document>
              <Page size="A4" style={styles.mainpage}>
                <PDFHeader
                  quote={quote} logo={logo}
                />
                <View style={styles.container}>
                  <View style={styles.leftColumn}>
                    <Text style={styles.title}>Quote Details</Text>
                    <PDFQuoteDetails
                      quote={quote}
                    />
                    <Text style={styles.subTitle}>Vehicles</Text>
                    <PDFVehicles quote={quote} />
                  </View>
                  <PDFPricingTableContainer
                    quote={quote}
                  />
                </View>
              </Page>
            </Document>
          }
          fileName="autoquote.pdf"
        >
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download PDF"
          }
        </PDFDownloadLink>
      )}
    </>
  );
};

export const PDFLink = memo(PDFLinkFirst);
