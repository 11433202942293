import axios from "axios";
import {
  GET_EMAIL_TEMPLATES,
  SEND_EMAIL,
  GET_EMAIL_LOGS,
  EMAIL_ERROR,
  CLEAR_EMAIL_ERROR,
} from "./types";

import { authError } from "./auth";
import { rootUrl } from "../config/keys";

const config = {
  headers: { Authorization: "JWT " + localStorage.getItem("token") },
};

export const getEmailLogs = (orderId) => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/emails/logs/${orderId}`, config)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearEmailError());
        dispatch(getEmailLogsSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(emailError(err.response.data.message));
          }
        } else {
          dispatch(emailError("There was an error getting email logs."));
        }
      });
  };
};

export const getEmailTemplates = () => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/emails`, config)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearEmailError());
        dispatch(getEmailsSuccess(data));
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(emailError(err.response.data.message));
          }
        } else {
          dispatch(emailError("There was an error getting email templates."));
        }
      });
  };
};

export const sendEmail = (formData, callback) => {
  return (dispatch) => {
    let templateName = formData.templateId;

    if (!templateName) {
      callback();
      return;
    }
    let uri = `${rootUrl}/email`;

    switch (templateName) {
      case "Order Transferee":
        formData.emailTemplate = "order-transferee";
        break;
      case "Carriers":
        formData.emailTemplate = "carriers";
        break;
      case "Customer Order Reminder":
        formData.emailTemplate = "customer-order-reminder";
        break;
      case "Order Transferee Signature":
        formData.emailTemplate = "order-transferee-signature";
        break;
      case "Order Agent":
        formData.emailTemplate = "order-agent";
        break;
      case "Order OA":
        formData.emailTemplate = "order-oa";
        break;
      case "Order Client":
        formData.emailTemplate = "order-client";
        break;
      case "Order Track":
        formData.emailTemplate = "order-track";
        break;
      case "Pickup Confirmation":
        formData.emailTemplate = "order-pickup-confirmation";
        break;
      case "Delivery Confirmation":
        formData.emailTemplate = "order-delivery-confirmation";
        break;
      case "Survey Notification":
        formData.emailTemplate = "survey-notification";
        break;
      case "Payment Request":
        formData.emailTemplate = "order-payment-request";
        break;
      default:
        return;
    }

    return axios
      .post(uri, formData, config)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearEmailError());
        dispatch(sendEmailSuccess(data));
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(emailError(err.response.data.message));
          }
        } else {
          dispatch(emailError("There was an error sending this email."));
        }
      });
  };
};

export const getEmailsSuccess = (data) => ({
  type: GET_EMAIL_TEMPLATES,
  templates: data,
});

export const getEmailLogsSuccess = (data) => ({
  type: GET_EMAIL_LOGS,
  payload: data,
});

export function emailError(error) {
  return {
    type: EMAIL_ERROR,
    payload: error,
  };
}

export function clearEmailError() {
  return {
    type: CLEAR_EMAIL_ERROR,
  };
}

export const sendEmailSuccess = (data) => ({
  type: SEND_EMAIL,
  payload: data,
});
