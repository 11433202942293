import React from "react";
import { Route, Redirect } from 'react-router-dom';

const MCAdminRoute = ({ component: Component, auth, ...rest }) => {
    if (auth && auth.authenticated && auth.role === "MCAdmin") {
        return (
            <Route {...rest}><Component /></Route>
        )
    } else {
        return <Redirect to="/login" />
    }
}


export default MCAdminRoute;
