import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";

import LoginForm from "./LoginForm";
import AuthForm from "./AuthForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";

const Auth = (props) => {
  const [form, setForm] = useState("login");
  const [email, setEmail] = useState(null);
  const McCollistersLogo =
    "https://res.cloudinary.com/dq27r8cov/image/upload/v1616097775/McCollister%27s/mcc-logo-white.png";
  const LogoDark =
    "https://res.cloudinary.com/dq27r8cov/image/upload/v1616098001/McCollister%27s/autovista_logo_2.png";

  useEffect(() => {
    if (props.form) {
      setForm(props.form);
    }
  }, []);

  return (
    <div className="auth-wrapper">
      <Helmet>
        <title>AutoVista</title>
      </Helmet>
      <div className="auth-card">
        <img className="auth-logo" src={LogoDark} alt="logo" />
        {form === "login" && (
          <LoginForm
            setEmail={setEmail}
            goToForgotPassword={() => setForm("forgotPassword")}
            goToAuth={() => setForm("auth")}
          />
        )}
        {form === "auth" && (
          <AuthForm
            email={email}
            goToForgotPassword={() => setForm("forgotPassword")}
            returnToLogin={() => setForm("login")}
          />
        )}
        {form === "forgotPassword" && (
          <ForgotPasswordForm returnToLogin={() => setForm("login")} />
        )}
        {form === "resetPassword" && (
          <ResetPasswordForm returnToLogin={() => setForm("login")} />
        )}
      </div>
      <div className="auth-branding">
        <div className="auth-branding-logos">
          <img src={McCollistersLogo} alt="AutoVista Portal Logo" />
        </div>
      </div>
    </div>
  );
};

export default Auth;
