import {
  CREATE_QUOTE,
  GET_QUOTES,
  UPDATE_QUOTE,
  GET_QUOTE,
  QUOTE_ERROR,
  CLEAR_QUOTE_ERROR,
  UNAUTHORIZE_USER,
} from "../actions/types";

export default (
  state = { quotes: [], quoteToEdit: null, error: null, request: null },
  action
) => {
  switch (action.type) {
    case GET_QUOTE:
      return {
        ...state,
        quotes: [...state.quotes],
        quote: action.quote,
        quoteToEdit: action.quote,
      };
    case CREATE_QUOTE:
      return { ...state, quotes: [...state.quotes, action.quote] };
    case GET_QUOTES:
      return {
        ...state,
        quotes: action.quotes.quotes,
        quoteCount: action.quotes.quoteCount,
        request: action.quotes.request,
      };
    case UPDATE_QUOTE:
      let quotes = state.quotes;
      let updatedQuotes = quotes.map((quote) => {
        if (quote._id === action.updatedQuote._id) {
          return action.updatedQuote;
        } else {
          return quote;
        }
      });
      return {
        ...state,
        quotes: updatedQuotes,
      };
    case QUOTE_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_QUOTE_ERROR:
      return { ...state, error: "" };
    case UNAUTHORIZE_USER:
      return { quotes: [], error: null };
    default:
      return state;
  }
};
