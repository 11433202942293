import { Helmet } from "react-helmet";
import React, { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router";
import {
  MinusCircleFilled,
  PlusCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";

import {
  Form,
  Card,
  Input,
  Row,
  Col,
  Divider,
  Select,
  InputNumber,
  Switch,
  Upload,
  Checkbox,
} from "antd";

import Button from "../../common/components/Button/Button";

import { MaskedInput } from "antd-mask-input";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  updatePortal,
  adminGetPortal,
  getPortalRegions,
} from "../../actions/portals";
import { uploadLogo, clearFiles } from "../../actions/files";

function PortalMCAdminEdit(props) {
  let { portalId } = useParams();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [dealershipList, setDealershipList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [portal, setPortal] = useState(null);
  const [emailsInitialValues, setEmailsInitialValues] = useState([]);

  const [showExistingLogo, setShowExistingLogo] = useState(true);

  const hasWhiteGloveOverride = Form.useWatch("hasWhiteGloveOverride", form);
  const hasVariableCompanyTariff = Form.useWatch(
    "hasVariableCompanyTariff",
    form
  );
  const hasCustomRatesOn = Form.useWatch("hasCustomRates", form);

  const isDealership = Form.useWatch("isDealership", form);
  const region = Form.useWatch("region", form);

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  useEffect(() => {
    dispatch(adminGetPortal(portalId));
    dispatch(getPortalRegions("all"));
  }, []);

  useEffect(() => {
    if (props.portals) {
      let currentPortal = props.portals.portalToEdit || props.portals.portal;
      setPortal(currentPortal);

      let emailValues =
        currentPortal.emails &&
        currentPortal.emails.map((e) => {
          return {
            email: e.email,
            emailPickUp: e.pickup || false,
            emailDelivery: e.delivery || false,
          };
        });
      setEmailsInitialValues(emailValues);

      form.setFieldsValue({
        region: currentPortal.region
          ? {
              value: currentPortal.region._id,
              label: currentPortal.region.name,
            }
          : null,
        companyName: currentPortal.companyName,
        isDealership: currentPortal.isDealership,
        parentPortal: currentPortal.parentPortal || null,
        companyAddress: currentPortal.companyAddress,
        companyCity: currentPortal.companyCity,
        companyState: currentPortal.companyState,
        companyZip: currentPortal.companyZip,
        companyPhone: currentPortal.companyPhone,
        companyFax: currentPortal.companyFax,
        contactFullName: currentPortal.contactFullName,
        contactEmail: currentPortal.contactEmail,
        contactPhone: currentPortal.contactPhone,
        contactMobilePhone: currentPortal.contactMobilePhone,
        emails: emailValues,
        discount: currentPortal.discount,
        companyTariff: currentPortal.companyTariff,
        companyTariffIsPercent: currentPortal.companyTariffIsPercent || false,
        displayMCLogo: currentPortal.displayMCLogo,
        displayAgent: currentPortal.displayAgent,
        displayPaymentType: currentPortal.displayPaymentType,
        displayCommissionPerVehicle: currentPortal.displayCommissionPerVehicle,
        displayDiscountOption: currentPortal.displayDiscountOption,
        displayPortalCommission: currentPortal.displayPortalCommission,

        // Custom Rates Fields
        hasCustomRates: currentPortal.hasCustomRates,
        m1to250: currentPortal.customRates?.mileage["1-250"],
        m251to500: currentPortal.customRates?.mileage["251-500"],
        m501to750: currentPortal.customRates?.mileage["501-750"],
        m751to1000: currentPortal.customRates?.mileage["751-1000"],
        m1001to1250: currentPortal.customRates?.mileage["1001-1250"],
        m1251to1500: currentPortal.customRates?.mileage["1251-1500"],
        m1501to1750: currentPortal.customRates?.mileage["1501-1750"],
        m1751to2000: currentPortal.customRates?.mileage["1751-2000"],
        m2001to2500: currentPortal.customRates?.mileage["2001-2500"],
        m2501to3000: currentPortal.customRates?.mileage["2501-3000"],
        m3001to3500: currentPortal.customRates?.mileage["3001-3500"],
        suvClassSurcharge: currentPortal.customRates?.suvClassSurcharge,
        vanClassSurcharge: currentPortal.customRates?.vanClassSurcharge,
        pickUp4DoorClassSurcharge:
          currentPortal.customRates?.pickUp4DoorClassSurcharge,
        enclosedSurcharge: currentPortal.customRates?.enclosedSurcharge,
        enclosedSurchargeOver1500:
          currentPortal.customRates?.enclosedSurchargeOver1500,
        fuelSurcharge: currentPortal.customRates?.fuelSurcharge,
        hasVariableCompanyTariff: currentPortal.hasVariableCompanyTariff,
        companyTariffOpenTransport: currentPortal.companyTariffOpenTransport,
        companyTariffEnclosedTransport:
          currentPortal.companyTariffEnclosedTransport,
        hasWhiteGloveOverride: currentPortal.hasWhiteGloveOverride,
        whiteGloveOverride: currentPortal.whiteGloveOverride,
        orderVolume: currentPortal.availableReports?.includes("orderVolume"),
        avgCostByDistance: currentPortal.availableReports?.includes(
          "avgCostByDistance"
        ),
        avgCostByDealership: currentPortal.availableReports?.includes(
          "avgCostByDealership"
        ),
        portalQuoteExpirationDays: currentPortal.portalQuoteExpirationDays,
      });
    }
  }, [props.portals]);

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = (file) => {
    setShowExistingLogo(false);
    setPreviewImage(file.thumbUrl);
    setPreviewVisible(true);
  };

  const handleUpload = ({ fileList }) => {
    setFileList({ fileList });

    let uploadInput = fileList[0].originFileObj;
    let file = uploadInput;
    let fileParts = file.name.split(".");
    props.dispatch(uploadLogo(file, fileParts));
  };

  const onFinish = (values) => {
    values.portalId = portal._id;
    props.dispatch(
      updatePortal(values, props.files.logo, () => {
        dispatch(adminGetPortal(portalId));
        history.push("/portals");
      })
    );
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  // const onReset = () => {
  //   form.resetFields();
  // };

  let states_list = [];
  let states = [
    "AL",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FM",
    "FL",
    "GA",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MH",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PW",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  states.forEach((item) => {
    states_list.push(<Select.Option value={item}>{item}</Select.Option>);
  });

  useEffect(() => {
    if (props.portals && props.portals.portals) {
      let currentPortal = props.portals.portalToEdit || props.portals.portal;
      let dl = props.portals.portals.filter((p) => {
        return p.isDealership === true;
      });

      setDealershipList(
        dl.map((d) => {
          if (d._id !== props.portals.portal._id) {
            return <Select.Option value={d._id}>{d.companyName}</Select.Option>;
          }
        })
      );

      // if (currentPortal && currentPortal.parentPortal) {
      //   let foundDealership = dl.find((d) => {
      //     return d._id === currentPortal.parentPortal;
      //   });

      //   if (foundDealership && foundDealership[0]) {
      //     setParentPortalInitialValue({
      //       label: foundDealership[0].companyName,
      //       value: foundDealership[0]._id,
      //     });
      //   }
      // }

      // if (currentPortal && currentPortal.region) {
      //   setRegionInitialValue({
      //     value: currentPortal.region._id,
      //     label: currentPortal.region.name,
      //   });
      // }
    }

    if (props.portals.regions) {
      setRegionList(
        props.portals.regions.map((r) => {
          return <Select.Option value={r._id}>{r.name}</Select.Option>;
        })
      );
    }
  }, [props.portals]);

  if (portal) {
    return (
      <div>
        <Helmet>
          <title>Update Portal</title>
        </Helmet>
        <div className="view-portals view-content-container">
          <Card
            title="Update Portal"
            bordered={false}
            style={{ width: "100%" }}
          >
            <Form
              form={form}
              layout="horizontal"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 10 }}
              className="portal-form"
              onFinish={onFinish}
            >
              <Divider>Company Details</Divider>
              <Form.Item
                label="Company Name"
                name="companyName"
                required
                // tooltip="This is a required field"
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Dealership"
                name="isDealership"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              {isDealership && (
                <Form.Item label="Parent Dealership" name="parentPortal">
                  <Select>
                    <Select.Option value={null}>None</Select.Option>
                    {dealershipList}
                  </Select>
                </Form.Item>
              )}

              {isDealership && (
                <Form.Item label="Region" name="region">
                  <Select>
                    <Select.Option value={null}>None</Select.Option>
                    {regionList}
                    <Select.Option value="other">Other</Select.Option>
                  </Select>
                </Form.Item>
              )}

              {isDealership && region === "other" && (
                <Form.Item label="Region (Other)" name="regionOther">
                  <Input />
                </Form.Item>
              )}

              <Form.Item label="Address" name="companyAddress">
                <Input />
              </Form.Item>
              <Form.Item label="City" name="companyCity">
                <Input />
              </Form.Item>
              <Form.Item label="State" name="companyState">
                <Select>{states_list}</Select>
              </Form.Item>
              <Form.Item label="Zip" name="companyZip">
                <Input />
              </Form.Item>
              <Form.Item label="Company Phone" name="companyPhone">
                <MaskedInput mask={"(000) 000-0000"} />
              </Form.Item>
              <Form.Item label="Company Fax" name="companyFax">
                <MaskedInput mask={"(000) 000-0000"} />
              </Form.Item>
              <Divider>Company Contact</Divider>

              <Form.Item label="Contact Full Name" name="contactFullName">
                <Input />
              </Form.Item>
              <Form.Item label="Contact Email" name="contactEmail">
                <Input />
              </Form.Item>
              <Form.Item label="Contact Phone" name="contactPhone">
                <MaskedInput mask={"(000) 000-0000"} />
              </Form.Item>
              <Form.Item label="Contact Mobile" name="contactMobilePhone">
                <MaskedInput mask={"(000) 000-0000"} />
              </Form.Item>
              <Divider>Notification Contacts</Divider>
              <Form.List name="emails">
                {(fields, { add, remove }, { errors }) => (
                  <>
                    {fields.map((field, index) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        key={field.key}
                      >
                        <Form.Item
                          name={[index, "email"]}
                          validateTrigger={["onChange", "onBlur"]}
                          rules={[
                            {
                              type: "email",
                              message: "Please enter a valid email address.",
                            },
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="Email Address"
                            style={{
                              maxWidth: "300px",
                              marginBottom: "5px",
                            }}
                          />
                        </Form.Item>

                        <Form.Item
                          style={{ marginBottom: "0px" }}
                          name={[index, "emailPickUp"]}
                          valuePropName="checked"
                        >
                          <Checkbox
                            style={{
                              width: "125px",
                              marginBottom: "5px",
                            }}
                          >
                            Pick Up
                          </Checkbox>
                        </Form.Item>

                        <Form.Item
                          name={[index, "emailDelivery"]}
                          valuePropName="checked"
                        >
                          <Checkbox
                            style={{
                              width: "125px",
                              marginBottom: "5px",
                            }}
                          >
                            Delivery
                          </Checkbox>
                        </Form.Item>

                        <MinusCircleFilled
                          onClick={() => remove(field.name)}
                          style={{
                            fontSize: "20px",
                            color: "#00274c",
                          }}
                        />
                      </div>
                    ))}

                    <Form.Item
                      style={{ display: "flex", justifyContent: "center" }}
                    >
                      <Button
                        label="Add Email"
                        style="light"
                        action={() => add()}
                      >
                        <PlusCircleFilled
                          style={{
                            fontSize: "20px",
                            color: "#fff",
                          }}
                        />
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>

              <Divider>Portal Settings</Divider>

              <Form.Item
                label="Discount Amount"
                tooltip="Set by McCollister's - per vehicle, applied to base rate"
                name="discount"
              >
                <InputNumber />
              </Form.Item>

              <Form.Item
                label="Has Variable Company Tariff"
                name="hasVariableCompanyTariff"
                tooltip="Portal admins can set company tariff by transportType"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              {!hasVariableCompanyTariff && (
                <Form.Item label="Company Tariff" name="companyTariff">
                  <InputNumber />
                </Form.Item>
              )}

              {hasVariableCompanyTariff && (
                <Fragment>
                  <Form.Item
                    label="Company Tariff (Open)"
                    name="companyTariffOpenTransport"
                  >
                    <InputNumber />
                  </Form.Item>

                  <Form.Item
                    label="Company Tariff (Enclosed)"
                    name="companyTariffEnclosedTransport"
                  >
                    <InputNumber />
                  </Form.Item>
                </Fragment>
              )}

              <Form.Item
                label="Tariff is Percent"
                name="companyTariffIsPercent"
                tooltip="Disable if company tariff is a fixed dollar amount"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="White Glove Override"
                name="hasWhiteGloveOverride"
                tooltip="Overrides per mile multiplier in Settings"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              {hasWhiteGloveOverride && (
                <Form.Item
                  label="White Glove Override Amount"
                  tooltip="$ Per Mile"
                  name="whiteGloveOverride"
                >
                  <InputNumber />
                </Form.Item>
              )}

              <Form.Item
                label="Days Until Quotes Expire"
                name="portalQuoteExpirationDays"
              >
                <InputNumber />
              </Form.Item>

              {portal.companyLogo && showExistingLogo && (
                <div
                  style={{
                    marginBottom: "25px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <img
                    style={{
                      width: "200px",
                    }}
                    src={portal.companyLogo}
                  />
                </div>
              )}

              <Form.Item
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                name="upload"
                valuePropName="fileList"
                getValueFromEvent={normFile}
              >
                <Upload
                  beforeUpload={() => false}
                  name="logo"
                  onChange={() => {
                    setShowExistingLogo(false);
                    handleUpload();
                  }}
                  listType="picture"
                >
                  <Button label="Click to Upload" style="light">
                    <UploadOutlined
                      style={{
                        fontSize: "20px",
                        color: "#fff",
                      }}
                    />
                  </Button>
                </Upload>
              </Form.Item>

              <Divider>Portal Display</Divider>

              <Form.Item
                label="McCollister's Logo in PDFs"
                name="displayMCLogo"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Agent Name"
                name="displayAgent"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Billing vs. COD Option"
                name="displayPaymentType"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Commission Per Vehicle"
                name="displayCommissionPerVehicle"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Discount Option"
                name="displayDiscountOption"
                valuePropName="checked"
                tooltip="Set by portal admins"
              >
                <Switch />
              </Form.Item>

              <Form.Item
                label="Portal-Wide Commission"
                name="displayPortalCommission"
                valuePropName="checked"
                tooltip="Set by portal admins"
              >
                <Switch />
              </Form.Item>

              <Divider>Custom Rate Sheet</Divider>

              <Form.Item
                label="Use Custom Rate Sheet"
                name="hasCustomRates"
                valuePropName="checked"
                tooltip="Set by portal admins"
              >
                <Switch />
              </Form.Item>

              {hasCustomRatesOn && (
                <Input.Group className="custom-rate-sheet-fields">
                  <Row gutter={0}>
                    <Col span={12} xs={12}>
                      <Form.Item name="m1to250" label="1-250 mi">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12}>
                      <Form.Item name="m251to500" label="251-500 mi">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12}>
                      <Form.Item name="m501to750" label="501-750 mi">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12}>
                      <Form.Item name="m751to1000" label="751-1000 mi">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12} xs={12}>
                      <Form.Item name="m1001to1250" label="1001-1250 mi">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12}>
                      <Form.Item name="m1251to1500" label="1251-1500 mi">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12}>
                      <Form.Item name="m1501to1750" label="1501-1750 mi">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12}>
                      <Form.Item name="m1751to2000" label="1751-2000 mi">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12} xs={12}>
                      <Form.Item name="m2001to2500" label="2001-2500 mi">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12}>
                      <Form.Item name="m2501to3000" label="2501-3000 mi">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12}>
                      <Form.Item name="m3001to3500" label="3001-3500 mi">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12}>
                      <Form.Item name="suvClassSurcharge" label="SUV Surcharge">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12} xs={12}>
                      <Form.Item name="vanClassSurcharge" label="Van Surcharge">
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12}>
                      <Form.Item
                        name="pickUp4DoorClassSurcharge"
                        label="PU 4-Door Surcharge"
                      >
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12}>
                      <Form.Item
                        name="enclosedSurcharge"
                        label="Enclosed Surcharge"
                      >
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={12}>
                      <Form.Item
                        name="enclosedSurchargeOver1500"
                        label="Encl. Surch. >1500mi"
                      >
                        <InputNumber
                          formatter={(value) =>
                            `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }
                          parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12} xs={12}>
                      <Form.Item
                        name="fuelSurcharge"
                        label="Fuel Surcharge (%)"
                      >
                        <InputNumber />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
              )}

              <Divider>Reports Available</Divider>
              <Form.Item
                label="Order Volume"
                name="orderVolume"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="Average Cost by Distance"
                name="avgCostByDistance"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>
              <Form.Item
                label="Average Cost by Dealership"
                name="avgCostByDealership"
                valuePropName="checked"
              >
                <Switch />
              </Form.Item>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button label="Submit" style="dark" type="submit" />

                {/* <Button
                  label="Reset"
                  style="light"
                  type="button"
                  action={onReset}
                /> */}
              </div>
            </Form>
          </Card>
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    files: state.files,
    portals: state.portals,
  };
};

export default connect(mapStateToProps)(PortalMCAdminEdit);
