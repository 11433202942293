import { useEffect, useState } from "react";
import { Bar } from "@ant-design/plots";

const SurveyChart = ({ data }) => {
  const values = [];

  data.responses.forEach((response) => {
    values.push({
      portal: response.companyName,
      rating: parseFloat(response.average.toFixed(2)),
    });
  });

  values.sort((a, b) => b.rating - a.rating);

  values.unshift({
    portal: "Overall",
    rating: parseFloat(data.average.toFixed(2)),
  });

  const config = {
    data: values,
    xField: "rating",
    yField: "portal",
    seriesField: "portal",
    label: {
      position: "middle",
      style: {
        fill: "#fff",
        fontSize: 14,
      },
    },
    barWidthRatio: 0.8,
    color: ({ portal }) => {
      return portal === "Overall" ? "#00274c" : "#238ac9";
    },
    legend: false,
  };

  let barStyle = { minHeight: "500px", marginTop: "25px" };

  return (
    <div style={{ margin: "50px auto", maxWidth: "500px" }}>
      <label>{data.question?.question}</label>
      {data.question?.isScale && <Bar style={barStyle} {...config} />}
    </div>
  );
};

export default SurveyChart;
