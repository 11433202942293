import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useForm, ErrorMessage } from "react-hook-form";
import Geocode from "react-geocode";
import { trackOrder } from "../../actions/orders";
import MapContainer from "./MapContainer.js";

import config from "../../config/keys";

function TrackOrder(props) {
  const GoogleAPIKey = config.googleMaps;

  const dispatch = useDispatch();
  const [driverLocation, setDriverLocation] = useState("Unknown");
  const [showForm, setShowForm] = useState(true);
  const { register, handleSubmit, errors } = useForm({});

  const onSubmit = (formData) => {
    setShowForm(false);
    dispatch(trackOrder(formData));
  };

  Geocode.setApiKey(GoogleAPIKey);
  Geocode.setLanguage("en");

  // Get address from latidude & longitude.
  if (props.trackedOrder) {
    if (
      props.trackedOrder.order.driverLat &&
      props.trackedOrder.order.driverLong
    ) {
      Geocode.fromLatLng(
        props.trackedOrder.order.driverLat,
        props.trackedOrder.order.driverLong
      ).then(
        (response) => {
          setDriverLocation(response.results[0].formatted_address);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  return (
    <div className="track-order-container view-content-container">
      <div className="view-header">
        <h1>Track Your Order</h1>
      </div>
      <div className="track-order-results">
        {!showForm && !props.trackedOrder && (
          <div>A location request has been sent to your driver.</div>
        )}

        {props.trackedOrder &&
          props.trackedOrder.order.driverLat &&
          props.trackedOrder.order.driverLong && (
            <div>
              <p>Your driver's current location: {driverLocation}</p>
              <div className="track-order-map-container">
                <MapContainer
                  lat={props.trackedOrder.order.driverLat}
                  lng={props.trackedOrder.order.driverLong}
                />
              </div>
            </div>
          )}

        {props.error && (
          <div>
            <p>{props.error}</p>
            <p>
              Please try again or contact us at{" "}
              <a href="mailto:autologistics@mccollisters.com">
                autologistics@mccollisters.com
              </a>{" "}
              for assistance
            </p>
          </div>
        )}
      </div>

      {showForm && (
        <form className="track-order-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form-header">
            <h3>
              Enter your email address and Order ID to request your vehicle's
              current location. Your Order ID can be found on the confirmation
              email originally sent to you.
            </h3>
          </div>

          <div className="form-fields">
            <div className="form-input">
              <input
                placeholder="Email"
                type="text"
                name="email"
                ref={register({
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Please enter a valid email address.",
                  },
                })}
              />
              <ErrorMessage errors={errors} name="email">
                {({ message }) => (
                  <div className="form-response-errors">{message}</div>
                )}
              </ErrorMessage>
            </div>

            <div className="form-input">
              <input
                placeholder="Order ID"
                type="text"
                name="order_id"
                ref={register}
              />
            </div>

            <button className="form-submit" type="submit">
              Request Update
            </button>
          </div>

          <div className="form-footer">
            <p>
              Please note, this is a request that will be sent to our driver. We
              require that all our drivers do not text and drive and only
              responds to location requests when safely stopped. Due to our
              commitment to safety, you may not receive an immediate response.
              For a quicker response, please feel free to call us at (888)
              819-0594 or email us at autologistics@mccollisters.com
            </p>
          </div>
        </form>
      )}
    </div>
  );
}
// }

const mapStateToProps = (state) => {
  return {
    trackedOrder: state.orders.trackedOrder,
    error: state.orders.error,
  };
};

export default connect(mapStateToProps)(TrackOrder);
