import React from "react";
import AppRouter from "./routers/AppRouter";

import "./common/styles/global.scss";
import "antd/dist/antd.css";

import { Provider } from "react-redux";
import { store } from "./store/configureStore";

export default () => {
  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};
