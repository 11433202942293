import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    marginBottom: 13,
  },
  title: {
    fontSize: 11,
    color: "#808080",
  },
  quoteDetails: {
    fontSize: 12,
    color: "#505050",
    marginTop: 3,
    marginBottom: 6,
  },
});

export const PDFQuoteDetails = (props) => {
  let quote = props.quote;

  let transportType = quote.transportType;
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Miles</Text>
      <Text style={styles.quoteDetails}>{quote.miles}</Text>

      {quote.transportType === "WHITEGLOVE" && (
        <>
          <Text style={styles.title}>Transport Type</Text>
          <Text style={styles.quoteDetails}>WHITEGLOVE</Text>
        </>
      )}

      <Text style={styles.title}>Transit Time</Text>
      {quote.transitTime && (
        <Text style={styles.quoteDetails}>
          {quote.transitTime[0]} - {quote.transitTime[1]} Days
        </Text>
      )}

      <Text style={styles.title}>Pickup Location</Text>

      <Text style={styles.quoteDetails}>{quote.pickup}</Text>
      <Text style={styles.title}>Deliver Location</Text>

      <Text style={styles.quoteDetails}>{quote.delivery}</Text>
    </View>
  );
};
