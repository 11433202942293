import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { BsStarFill } from "react-icons/bs";

import { submitSurvey } from "../../actions/survey";

function SurveyForm(props) {
  const { register, handleSubmit, errors } = useForm();

  const { id } = useParams();

  const onSubmit = (formData) => {
    props.dispatch(submitSurvey(formData, props.formComplete(true)));
  };

  const [communications, setCommunications] = useState(0);
  const [driver, setDriver] = useState(0);
  const [recommend, setRecommend] = useState(0);

  const [communicationsRatingLegend, setCommunicationsRatingLegend] = useState(
    ""
  );
  const [driverRatingLegend, setDriverRatingLegend] = useState("");
  const [recommendRatingLegend, setRecommendRatingLegend] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    if (driver == 1) {
      setDriverRatingLegend("Poor Experience");
    } else if (driver == 2) {
      setDriverRatingLegend("Below Average Experience");
    } else if (driver == 3) {
      setDriverRatingLegend("Average Experience");
    } else if (driver == 4) {
      setDriverRatingLegend("Good Experience");
    } else if (driver == 5) {
      setDriverRatingLegend("Excellent Experience");
    }
  }, [driver]);

  useEffect(() => {
    if (communications == 1) {
      setCommunicationsRatingLegend("Poor Experience");
    } else if (communications == 2) {
      setCommunicationsRatingLegend("Below Average Experience");
    } else if (communications == 3) {
      setCommunicationsRatingLegend("Average Experience");
    } else if (communications == 4) {
      setCommunicationsRatingLegend("Good Experience");
    } else if (communications == 5) {
      setCommunicationsRatingLegend("Excellent Experience");
    }
  }, [communications]);

  useEffect(() => {
    if (recommend == 1) {
      setRecommendRatingLegend("I would not recommend your company.");
    } else if (recommend == 2) {
      setRecommendRatingLegend(
        "You need to work on some things before I could recommend you."
      );
    } else if (recommend == 3) {
      setRecommendRatingLegend("I would potentially recommend you.");
    } else if (recommend == 4) {
      setRecommendRatingLegend("I would feel good about recommending you.");
    } else if (recommend == 5) {
      setRecommendRatingLegend(
        "You guys were awesome!  I would highly recommend you."
      );
    }
  }, [recommend]);

  return (
    <form id="survey-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="survey-question">
        <input type="hidden" name="id" value={id} ref={register} />
        <p>
          How would you rate your overall experience in communications with our
          customer service team during your auto transport?
        </p>
        <div
          onChange={(e) => setCommunications(e.target.value)}
          class="rating-inputs"
        >
          <label
            className={`star ${communications > 0 ? "active" : ""}`}
            htmlFor="comm-1"
            title="1 star"
          >
            <BsStarFill />
            <input
              name="communications"
              id="comm-1"
              type="radio"
              value="1"
              ref={register}
            />
          </label>

          <label
            className={`star ${communications > 1 ? "active" : ""}`}
            htmlFor="comm-2"
            title="2 stars"
          >
            <BsStarFill />
            <input
              name="communications"
              id="comm-2"
              type="radio"
              value="2"
              ref={register}
            />
          </label>

          <label
            className={`star ${communications > 2 ? "active" : ""}`}
            htmlFor="comm-3"
            title="3 stars"
          >
            <BsStarFill />
            <input
              name="communications"
              id="comm-3"
              type="radio"
              value="3"
              ref={register}
            />
          </label>

          <label
            className={`star ${communications > 3 ? "active" : ""}`}
            htmlFor="comm-4"
            title="4 stars"
          >
            <BsStarFill />
            <input
              name="communications"
              id="comm-4"
              type="radio"
              value="4"
              ref={register}
            />
          </label>

          <label
            className={`star ${communications > 4 ? "active" : ""}`}
            htmlFor="comm-5"
            title="5 stars"
          >
            <BsStarFill />
            <input
              name="communications"
              id="comm-5"
              type="radio"
              value="5"
              ref={register}
            />
          </label>

          <p className="rating-legend">{communicationsRatingLegend}</p>
        </div>

        <div class="rating-details">
          <label htmlFor="driverDetail">
            Please explain your score. <span>(Optional)</span>
          </label>
          <textarea name="communicationsDetail" ref={register} />
        </div>
      </div>

      <div className="survey-question">
        <p>
          How would you rate your overall experience with the driver at both
          pickup and delivery?
        </p>

        <div onChange={(e) => setDriver(e.target.value)} class="rating-inputs">
          <label
            className={`star ${driver > 0 ? "active" : ""}`}
            htmlFor="driver-1"
            title="1 star"
          >
            <BsStarFill />
            <input
              name="driver"
              id="driver-1"
              type="radio"
              value="1"
              ref={register}
            />
          </label>

          <label
            className={`star ${driver > 1 ? "active" : ""}`}
            htmlFor="driver-2"
            title="2 stars"
          >
            <BsStarFill />
            <input
              name="driver"
              id="driver-2"
              type="radio"
              value="2"
              ref={register}
            />
          </label>

          <label
            className={`star ${driver > 2 ? "active" : ""}`}
            htmlFor="driver-3"
            title="3 stars"
          >
            <BsStarFill />
            <input
              name="driver"
              id="driver-3"
              type="radio"
              value="3"
              ref={register}
            />
          </label>

          <label
            className={`star ${driver > 3 ? "active" : ""}`}
            htmlFor="driver-4"
            title="4 stars"
          >
            <BsStarFill />
            <input
              name="driver"
              id="driver-4"
              type="radio"
              value="4"
              ref={register}
            />
          </label>

          <label
            className={`star ${driver > 4 ? "active" : ""}`}
            htmlFor="driver-5"
            title="5 stars"
          >
            <BsStarFill />
            <input
              name="driver"
              id="driver-5"
              type="radio"
              value="5"
              ref={register}
            />
          </label>

          <p className="rating-legend">{driverRatingLegend}</p>
        </div>

        <div class="rating-details">
          <label htmlFor="driverDetail">
            Please explain your score. <span>(Optional)</span>
          </label>
          <textarea name="driverDetail" ref={register} />
        </div>
      </div>

      <div className="survey-question">
        <p>
          How likely would you be to recommend our company to a friend or
          colleague looking to transport their vehicle(s)?
        </p>

        <div
          onChange={(e) => {
            setRecommend(e.target.value);
          }}
          class="rating-inputs"
        >
          <label
            className={`star ${recommend > 0 ? "active" : ""}`}
            htmlFor="rec-1"
            title="1 star"
          >
            <BsStarFill />
            <input
              name="recommend"
              id="rec-1"
              type="radio"
              value="1"
              ref={register}
            />
          </label>

          <label
            className={`star ${recommend > 1 ? "active" : ""}`}
            htmlFor="rec-2"
            title="2 stars"
          >
            <BsStarFill />
            <input
              name="recommend"
              id="rec-2"
              type="radio"
              value="2"
              ref={register}
            />
          </label>

          <label
            className={`star ${recommend > 2 ? "active" : ""}`}
            htmlFor="rec-3"
            title="3 stars"
          >
            <BsStarFill />
            <input
              name="recommend"
              id="rec-3"
              type="radio"
              value="3"
              ref={register}
            />
          </label>

          <label
            className={`star ${recommend > 3 ? "active" : ""}`}
            htmlFor="rec-4"
            title="4 stars"
          >
            <BsStarFill />
            <input
              name="recommend"
              id="rec-4"
              type="radio"
              value="4"
              ref={register}
            />
          </label>

          <label
            className={`star ${recommend > 4 ? "active" : ""}`}
            htmlFor="rec-5"
            title="5 stars"
          >
            <BsStarFill />
            <input
              name="recommend"
              id="rec-5"
              type="radio"
              value="5"
              ref={register}
            />
          </label>

          <p className="rating-legend">{recommendRatingLegend}</p>
        </div>

        <div class="rating-details">
          <label htmlFor="recommendDetail">
            Please explain your score. <span>(Optional)</span>
          </label>
          <textarea name="recommendDetail" ref={register} />
        </div>
      </div>

      <div className="survey-question">
        <label htmlFor="additionalFeedback">
          We strive for a 5 star experience for all our our customers and value
          any additional feedback, suggestions and comments below.{" "}
          <span>(Optional)</span>
        </label>
        <textarea name="additionalFeedback" ref={register} />
      </div>
      <input class="submit-button" type="submit" />
    </form>
  );
}

// Provides access to newQuote state
const mapStateToProps = (state) => {
  return {
    user: state.users.user,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(SurveyForm);
