import { ADD_MESSAGE, CLEAR_MESSAGE } from './types';

export function addMessage(message) {
    return {
        type: ADD_MESSAGE,
        message
    };
}

export function clearMessage() {
    return {
        type: CLEAR_MESSAGE
    };
}
