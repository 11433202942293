import { REPORTS_ERROR, CLEAR_REPORTS_ERROR } from "../actions/types";

export default (state = { reports: [], error: null }, action) => {
  switch (action.type) {
    case "DAILY_REPORTS":
      return { ...state, daily: action.payload };
    case "DAILY_USER_REPORTS":
      return { ...state, dailyUser: action.payload };
    case "ALL_TIME_REPORTS":
      return { ...state, allTime: action.payload };
    case "ANNUAL_REPORTS":
      return { ...state, annual: action.payload };
    case "PORTAL_REPORTS":
      return { ...state, portal: action.payload };
    case "COMMISSION_REPORTS":
      return { ...state, commission: action.payload };
    case "ONTIME_REPORTS":
      return { ...state, ontime: action.payload };
    case REPORTS_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_REPORTS_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
};
