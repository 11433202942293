import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import Alert from "../../common/components/Alert/Alert.js";
import { Card, Row, Col } from "antd";
import Button from "../../common/components/Button/Button";
import { adminGetUser, updateUserStatus } from "../../actions/users";
import UserFormEdit from "../Users/UsersFormEdit.js";

function UserDetail({ user }) {
  const dispatch = useDispatch();
  let { userId } = useParams();
  let history = useHistory();

  const [confirmation, setConfirmation] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const showRemoveConfirmation = (e) => {
    e.preventDefault();
    setConfirmation("Are you sure you want to remove this user?");
  };

  const handleRemoveUser = () => {
    setConfirmation(false);
    dispatch(
      updateUserStatus(userId, "Archived", () => history.push("/users"))
    );
  };

  useEffect(() => {
    if (userId) {
      dispatch(adminGetUser(userId));
    }
  }, [dispatch, userId]);

  if (user && user._id === userId) {
    return (
      <div className="view-content-container">
        {confirmation && (
          <Alert text={confirmation} action={handleRemoveUser} />
        )}

        {!editMode && (
          <Card title="User Details" bordered={false}>
            <Row gutter={16} style={{ maxWidth: "500px", margin: "0 auto" }}>
              <Col span={12}>
                <div style={{ fontWeight: "bold" }}>
                  <p>Company</p>
                  <p>First Name</p>
                  <p>Last Name</p>
                  <p>Role</p>
                  <p>Email</p>
                  <p>Phone</p>
                  <p>Mobile Phone</p>
                </div>
              </Col>
              <Col span={12}>
                <div>
                  <p>{user.companyName}</p>
                  <p>{user.firstName}</p>
                  <p>{user.lastName}</p>
                  <p>{user.role}</p>
                  <p>{user.email}</p>
                  <p>{user.phone}</p>
                  <p>{user.mobilePhone}</p>
                </div>
              </Col>
            </Row>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              <Button
                label="Edit User"
                style="dark"
                action={() => setEditMode(true)}
              />
            </div>
          </Card>
        )}

        {editMode && (
          <UserFormEdit
            close={() => setEditMode(false)}
            userToEdit={user}
            showRemoveConfirmation={showRemoveConfirmation}
          />
        )}
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.users.userToEdit,
  };
};

export default connect(mapStateToProps)(UserDetail);
