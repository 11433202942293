import React from "react";

export default function Spinner(props) {
  return (
    <div
      className={
        props.small
          ? "spinner-container spinner-small-container"
          : "spinner-container"
      }
    >
      <div className="spinner"></div>
    </div>
  );
}
