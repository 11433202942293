import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { MaskedInput } from "antd-mask-input";
import { Form, Card, Input } from "antd";

import { getUser, updateUser } from "../../actions/users";
import Button from "../../common/components/Button/Button";

function Account(props) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [user, setUser] = useState(props.user);
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [message, setMessage] = useState("");

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  useEffect(() => {
    dispatch(getUser(props.auth.userId));
  }, [props.auth]);

  useEffect(() => {
    if (props.users.user) {
      setUser(props.users.user);
    }
  }, [props.users]);

  const togglePasswordFields = () => {
    setShowPasswordFields(!showPasswordFields);
  };

  const onFinish = (values) => {
    values.userId = user._id;
    values.portalId = user.portalId;
    values.status = "Active";
    dispatch(
      updateUser(values, () => {
        setMessage("Account updated successfully.");
      })
    );
  };

  if (user && user.firstName) {
    return (
      <>
        <Helmet>
          <title>Account</title>
        </Helmet>
        <div className="account-container view-content-container">
          <Card title="Account" bordered={false} style={{ width: "100%" }}>
            <Form
              form={form}
              layout="horizontal"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 10 }}
              className="account-form"
              onFinish={onFinish}
              requiredMark={false}
              initialValues={{
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                phone: user.phone,
                mobilePhone: user.mobilePhone,
                role: user.role,
              }}
            >
              <p style={{ textAlign: "center" }} className="form-message">
                {message}
              </p>

              <Form.Item label="First Name" name="firstName">
                <Input />
              </Form.Item>

              <Form.Item label="Last Name" name="lastName">
                <Input />
              </Form.Item>

              <Form.Item label="Email" name="email" rules={[{ type: "email" }]}>
                <Input />
              </Form.Item>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "30px",
                }}
              >
                <Button
                  label="Change Password"
                  style="light"
                  type="button"
                  action={togglePasswordFields}
                />
              </div>

              {showPasswordFields && (
                <>
                  <Form.Item label="Current Password" name="password">
                    <Input.Password />
                  </Form.Item>

                  <Form.Item label="New Password" name="newPassword">
                    <Input.Password />
                  </Form.Item>

                  {props.auth.error && (
                    <p class="auth-error-message">{props.auth.error}</p>
                  )}

                  <Form.Item
                    label="Confirm Password"
                    name="confirm"
                    dependencies={["newPassword"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (
                            !value ||
                            getFieldValue("newPassword") === value
                          ) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Passwords must match.")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>
                </>
              )}

              <Form.Item label="Phone" name="phone">
                <MaskedInput mask={"(000) 000-0000"} />
              </Form.Item>

              <Form.Item label="Mobile" name="mobilePhone">
                <MaskedInput mask={"(000) 000-0000"} />
              </Form.Item>

              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button label="Submit" style="dark" type="submit" />
              </div>
            </Form>
          </Card>
        </div>
      </>
    );
  } else {
    return (
      <div>
        <Helmet>
          <title>Account</title>
        </Helmet>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    portals: state.portals.portals,
    users: state.users,
  };
};

export default connect(mapStateToProps)(Account);
