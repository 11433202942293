import axios from "axios";
import {
  GET_USER,
  GET_USERS,
  CREATE_USER,
  UPDATE_USER,
  ADMIN_GET_USER,
  USER_ERROR,
  CLEAR_USER_ERROR,
} from "./types";

import { authError } from "./auth";
import { rootUrl } from "../config/keys";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};

// Gets logged in user
export const getUser = () => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/user`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getUserSuccess(data));
        dispatch(clearUserError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(userError(err.response.data.message));
          }
        } else {
          dispatch(userError("There was an error getting this user."));
        }
      });
  };
};

// Get another user
export const adminGetUser = (userId) => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/user/${userId}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(adminGetUserSuccess(data));
        dispatch(clearUserError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(userError(err.response.data.message));
          }
        } else {
          dispatch(userError("There was an error getting this user."));
        }
      });
  };
};

export const getUsers = (query = "") => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/users${query}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (data) {
          dispatch(getUsersSuccess(data));
          dispatch(clearUserError());
        }
      })
      .catch((err) => {
        console.log("err in getAllUsers", err);
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(userError(err.response.data.message));
          }
        } else {
          dispatch(userError("There was an error getting users."));
        }
      });
  };
};

// Get Users for this Portal - based on admin ID
export const getPortalUsers = () => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/admin/users`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getUsersSuccess(data));
        dispatch(clearUserError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(userError(err.response.data.message));
          }
        } else {
          dispatch(userError("There was an error getting users."));
        }
      });
  };
};

// Get Users for this Portal (used for sidebar filtering)
export const getUsersByPortal = (portalId) => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/users/${portalId}`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getUsersSuccess(data));
        dispatch(clearUserError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(userError(err.response.data.message));
          }
        } else {
          dispatch(userError("There was an error getting users."));
        }
      });
  };
};

// Create a single User
export const createUser = (formData, callback) => {
  return (dispatch) => {
    return axios
      .post(`${rootUrl}/admin/user`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(createUserSuccess(data));
        dispatch(clearUserError());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(userError(err.response.data.message));
          }
        } else {
          dispatch(userError("There was an error creating this user."));
        }
      });
  };
};

// Admin update a single User
export const adminUpdateUser = (formData, callback) => {
  return (dispatch) => {
    return axios
      .put(`${rootUrl}/user/${formData.userId}`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(updateUserSuccess(data));
        dispatch(clearUserError());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(userError(err.response.data.message));
          }
        } else {
          dispatch(userError("There was an error updating this user."));
        }
      });
  };
};

export const updateUser = (formData, callback) => {
  return (dispatch) => {
    return axios
      .put(`${rootUrl}/user/${formData.userId}`, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(updateUserSuccess(data));
        dispatch(clearUserError());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(userError(err.response.data.message));
          }
        } else {
          dispatch(userError("There was an error updating this user."));
        }
      });
  };
};

export const updateUserStatus = (userId, status, callback) => {
  return (dispatch) => {
    return axios
      .put(`${rootUrl}/user/${userId}/status`, { status }, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(updateUserSuccess(data));
        dispatch(clearUserError());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(userError(err.response.data.message));
          }
        } else {
          dispatch(userError("There was an error updating this user."));
        }
      });
  };
};

export const createUserSuccess = (data) => ({
  type: CREATE_USER,
  user: data,
});

export const adminGetUserSuccess = (data) => ({
  type: ADMIN_GET_USER,
  user: data,
});

export const updateUserSuccess = (data) => ({
  type: UPDATE_USER,
  user: data,
});

export const getUsersSuccess = (data) => ({
  type: GET_USERS,
  users: data,
});

export function userError(error) {
  return {
    type: USER_ERROR,
    payload: error,
  };
}

export function clearUserError() {
  return {
    type: CLEAR_USER_ERROR,
  };
}

export const getUserSuccess = (data) => ({
  type: GET_USER,
  user: data,
});
