import React, { useState, useEffect } from "react";
import { Prompt } from "react-router";
import { MdOutlineGarage } from "react-icons/md";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { useHistory, Link } from "react-router-dom";
import moment from "moment-timezone";

import {
  getQuote,
  updateQuote,
  getQuotes,
  updateTransportOptions,
} from "../../actions/quotes";
import Spinner from "../../common/components/Spinner/Spinner.js";
import QuoteFormEdit from "../Quotes/QuoteFormEdit";
import { getSettings } from "../../actions/settings.js";

import Alert from "../../common/components/Alert/Alert.js";
import { PDFLink } from "../../common/components/PDFDownload/PDFDownload";

import { Divider } from "antd";
import PricingDetail from "./PricingDetail";

function QuoteDetail({ quotes, auth, settings, isOrder, portals, portal }) {
  const { quoteId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [quotePortal, setQuotePortal] = useState(null);
  // const [PDFlogo, setPDFlogo] = useState(null);
  const [quote, setQuote] = useState(null);
  const [quoteLocalDate, setQuoteLocalDate] = useState("");
  const [showSaveButtons, setShowSaveButtons] = useState(true);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [confirmation, setConfirmation] = useState({});
  const [quoteStatus, setQuoteStatus] = useState();
  const [discarding, setDiscarding] = useState(false);
  const [numVehicles, setNumVehicles] = useState(1);

  useEffect(() => {
    if (quote?.status === "Saved") {
      setShowSaveButtons(false);
    }

    if (quote) {
      setNumVehicles(quote.vehicleQuotes.length);

      if (auth.role === "MCAdmin") {
        setQuotePortal(
          portals.find((portal) => {
            return portal._id == quote.portalId;
          })
        );
      } else {
        setQuotePortal(portal);
      }
    }
  }, [quote]);

  const runWhiteGlove = () => {
    let formData = { transportType: "WHITEGLOVE", quoteId: quote._id };

    dispatch(
      updateTransportOptions(formData, history, () => {
        dispatch(getQuote(quoteId));
      })
    );
  };

  const runRegularQuote = () => {
    let formData = { transportType: "OPEN", quoteId: quote._id };

    dispatch(
      updateTransportOptions(formData, history, () => {
        dispatch(getQuote(quoteId));
      })
    );
  };

  let PDFlogo =
    "https://periscopemccollisters.s3.amazonaws.com/mccollisters-auto-logistics";

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (quote) {
      const quoteIsoDate = quote.createdAt;
      setQuoteLocalDate(
        moment(quoteIsoDate)
          .tz(moment.tz.guess())
          .format("MM/DD/YY h:mmA z")
      );
    }
  }, [quote]);

  useEffect(() => {
    dispatch(getQuote(quoteId));
  }, [quoteId]);

  useEffect(() => {
    if (quotes.quoteToEdit) {
      setQuote(quotes.quoteToEdit);
    } else if (quotes.quote) {
      setQuote(quotes.quote);
    }
  }, [quotes]);

  if (quote && quotePortal) {
    if (quotePortal.displayMCLogo) {
      PDFlogo =
        "https://periscopemccollisters.s3.amazonaws.com/mccollisters-auto-logistics";
    } else if (quotePortal.companyLogo) {
      PDFlogo = quotePortal.companyLogo;
    }
  }

  const handleSaveQuote = () => {
    if (!quote) {
      return;
    }

    dispatch(
      updateQuote(
        { status: "Saved" },
        quote._id,
        setConfirmation({
          type: "Save",
          message: "Quote has been saved.",
          clickHandler: () => {
            setConfirmation({});
            setShowSaveButtons(false);

            getQuotes({
              sortby: "uniqueId",
              limit: settings.portal === "all" || !settings.portal ? 150 : 5000,
              skip: 0,
              portalId: settings.portal !== "all" ? settings.portal : null,
            });
          },
        })
      )
    );
  };

  const showRemoveConfirmation = () => {
    setDiscarding(true);

    setConfirmation({
      type: "Remove",
      message: "Are you sure you want to remove this quote?",
      clickHandler: handleRemoveQuote,
    });
  };

  const handleRemoveQuote = () => {
    setConfirmation(false);

    dispatch(
      updateQuote({ status: "Archived", quoteId: quote._id }, () =>
        history.push("/quotes")
      )
    );
  };

  const toggleEditForm = () => {
    setShowEditForm(!showEditForm);
  };

  const handleBookOrder = () => {
    if (!quote) {
      return;
    }

    history.push(`/orders/book/${quote._id}`);
  };

  const transportTypeValue = () => {
    if (!quote) {
      return;
    }
    if (quote.transportType === "ENCLOSED") {
      return (
        <span>
          Enclosed (Gold) <MdOutlineGarage />
        </span>
      );
    } else if (quote.transportType === "WHITEGLOVE") {
      return (
        <span>
          Enclosed White Glove (Platinum) <MdOutlineGarage />
        </span>
      );
    } else {
      return <span>Open (Silver)</span>;
    }
  };

  const renderVehiclePrices = (quote, calculatedQuotes) => {
    if (!calculatedQuotes) {
      return <p></p>;
    }

    let commission = quote.commission || 0;
    let discount = quote.portalAdminDiscount || 0;

    const displayPriceWithDiscount = (price, discount) => {
      return (
        <>
          {discount > 0 && (
            <span className="strikethrough">
              ${discount + price - commission}
            </span>
          )}
          ${Math.ceil(price - commission)}
        </>
      );
    };

    if (quote.transportType !== "WHITEGLOVE") {
      return (
        <>
          <div className="table-item table-double-item">
            {/* 1-Day Pickup */}
            <div
              className={
                quote.transportType === "OPEN"
                  ? "open-price bold"
                  : "open-price"
              }
            >
              {displayPriceWithDiscount(
                calculatedQuotes[0].openTransportPortal,
                discount
              )}
            </div>
            <div
              className={
                quote.transportType === "ENCLOSED"
                  ? "encl-price bold"
                  : "encl-price"
              }
            >
              {displayPriceWithDiscount(
                calculatedQuotes[0].enclosedTransportPortal,
                discount
              )}
            </div>
          </div>

          {/* 3-Day Pickup */}
          <div className="table-item table-double-item">
            <div
              className={
                quote.transportType === "OPEN"
                  ? "open-price bold"
                  : "open-price"
              }
            >
              {displayPriceWithDiscount(
                calculatedQuotes[1].openTransportPortal,
                discount
              )}
            </div>
            <div
              className={
                quote.transportType === "ENCLOSED"
                  ? "encl-price bold"
                  : "encl-price"
              }
            >
              {displayPriceWithDiscount(
                calculatedQuotes[1].enclosedTransportPortal,
                discount
              )}
            </div>
          </div>

          {/* 5-Day Pickup */}
          <div className="table-item table-double-item">
            <div
              className={
                quote.transportType === "OPEN"
                  ? "open-price bold"
                  : "open-price"
              }
            >
              {displayPriceWithDiscount(
                calculatedQuotes[2].openTransportPortal,
                discount
              )}
            </div>
            <div
              className={
                quote.transportType === "ENCLOSED"
                  ? "encl-price bold"
                  : "encl-price"
              }
            >
              {displayPriceWithDiscount(
                calculatedQuotes[2].enclosedTransportPortal,
                discount
              )}
            </div>
          </div>

          {/* 7-Day Pickup */}
          <div className="table-item table-double-item">
            <div
              className={
                quote.transportType === "OPEN"
                  ? "open-price bold"
                  : "open-price"
              }
            >
              {displayPriceWithDiscount(
                calculatedQuotes[3].openTransportPortal,
                discount
              )}
            </div>
            <div
              className={
                quote.transportType === "ENCLOSED"
                  ? "encl-price bold"
                  : "encl-price"
              }
            >
              {displayPriceWithDiscount(
                calculatedQuotes[3].enclosedTransportPortal,
                discount
              )}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="table-item">
            {displayPriceWithDiscount(
              calculatedQuotes[0].whiteGloveTransportPortal,
              discount
            )}
          </div>
        </>
      );
    }
  };

  const TotalItem = ({ quote, index }) => (
    <div className="table-item total-item table-double-item">
      <div
        className={
          quote.transportType === "OPEN" ? "open-price bold" : "open-price"
        }
      >
        $
        {Math.ceil(
          quote.totalPricing[index]?.totalOpenTransportPortal -
            quote.commission * numVehicles
        )}
      </div>
      <div
        className={
          quote.transportType === "ENCLOSED" ? "encl-price bold" : "encl-price"
        }
      >
        $
        {Math.ceil(
          quote.totalPricing[index]?.totalEnclosedTransportPortal -
            quote.commission * numVehicles
        )}
      </div>
    </div>
  );

  const TotalItems = ({ quote }) => (
    <>
      {[1, 3, 5, 7].map((index) => (
        <TotalItem key={index} quote={quote} index={index.toString()} />
      ))}
    </>
  );

  if (quote) {
    return (
      <div className="quote-detail view-content-container">
        <Prompt
          message={(location, action) => {
            if (quote) {
              if (
                quote.status == "Unsaved" &&
                !discarding &&
                showSaveButtons === true &&
                !location.pathname.startsWith("/orders/book")
              ) {
                return `Your quote has not been saved. Are you sure you want to leave the page?`;
              }
            } else if (quoteStatus) {
              if (
                quoteStatus == "Unsaved" &&
                !discarding &&
                showSaveButtons === true &&
                !location.pathname.startsWith("/orders/book")
              ) {
                return `Your quote has not been saved. Are you sure you want to leave the page?`;
              }
            }
          }}
        />

        {confirmation.message && (
          <Alert
            text={confirmation.message}
            action={confirmation.clickHandler}
          />
        )}
        {showSpinner && showEditForm && <Spinner />}

        <div className="view-header">
          <h1>QUOTE: #{quote.uniqueId}</h1>
        </div>

        <div className="quote-detail-container">
          {!showEditForm && (
            <div className="quote-detail-info">
              <p>Thank you for the opportunity to serve you.</p>
              <p>All Pricing Includes:</p>
              <ul style={{ marginTop: "10px" }}>
                <li>Door-to-door service when applicable</li>
                <li>Coordinated pickup and delivery</li>
                <li>Taxes and fees included in your AutoVista quote</li>
              </ul>

              {quote.transportType === "WHITEGLOVE" && (
                <p>
                  Final pickup scheduling will be coordinated by McCollister
                  customer service team next business day​.
                </p>
              )}

              <Divider>
                Call For Assistance: <a href="tel:8888190594">(888) 819-0594</a>
              </Divider>
            </div>
          )}

          {!showEditForm && settings && (
            <div className="pickup-delivery-details">
              <div className="action-row save-cancel">
                {showSaveButtons && !isOrder && (
                  <button
                    className="form-submit green"
                    onClick={handleSaveQuote}
                  >
                    Save
                  </button>
                )}

                {!isOrder && showSaveButtons && (
                  <button
                    className="form-submit red"
                    onClick={showRemoveConfirmation}
                  >
                    Discard Quote
                  </button>
                )}
              </div>

              <div className="row">
                <span className="row-label">Created:</span>
                <span>{quoteLocalDate}</span>
              </div>

              <div className="row">
                <span className="row-label">Customer:</span>
                <span>{quote.customerFullName}</span>
              </div>

              <div className="row">
                <span className="row-label">Miles:</span>
                <span>{quote.miles}</span>
              </div>

              <div className="row">
                <span className="row-label">Transport Type:</span>
                {transportTypeValue()}
              </div>

              {quote.transitTime && !isNaN(parseInt(quote.transitTime[0])) && (
                <div className="row">
                  <span className="row-label">Transit Time:</span>
                  <span>
                    {quote.transitTime[0]} - {quote.transitTime[1]} Days
                  </span>
                </div>
              )}

              <div className="row">
                <span className="row-label">Pickup:</span>
                <span>{quote.pickup}</span>
              </div>

              <div className="row">
                <span className="row-label">Delivery:</span>
                <span>{quote.delivery}</span>
              </div>

              <div className="row full-row">
                <PricingDetail quote={quote} settings={settings} />
              </div>

              <div className="action-row">
                {!isOrder && (
                  <button onClick={toggleEditForm} className="default">
                    Edit
                  </button>
                )}

                {!isOrder && quote.status === "Saved" && (
                  <button
                    className="form-submit red"
                    onClick={showRemoveConfirmation}
                  >
                    Delete
                  </button>
                )}

                {!isOrder && (
                  <button className="form-submit" onClick={handleBookOrder}>
                    Book Order
                  </button>
                )}

                {!isOrder && quote && (
                  <button className="form-submit">
                    <PDFLink
                      className="download-link"
                      quote={quote}
                      logo={PDFlogo}
                    />
                  </button>
                )}
              </div>

              {quote.transportType !== "WHITEGLOVE" && (
                <div style={{ textAlign: "center", width: "100%" }}>
                  <button
                    onClick={() => runWhiteGlove()}
                    className="button-link"
                  >
                    Click for Platinum (white glove) pricing
                  </button>
                </div>
              )}

              {quote.transportType === "WHITEGLOVE" && (
                <div style={{ textAlign: "center", width: "100%" }}>
                  <button
                    onClick={() => runRegularQuote()}
                    className="button-link"
                  >
                    Click for McCollister's Auto Brokerage pricing
                  </button>
                </div>
              )}
            </div>
          )}

          {!showEditForm && settings && settings && (
            <div className="quote-detail-vehicles">
              <div
                className={`table table-header quote-detail-table ${quote.transportType ===
                  "WHITEGLOVE" && "white-glove"}`}
              >
                <div className="table-item">Make</div>
                <div className="table-item">Model</div>
                <div className="table-item">Class</div>
                <div className="table-item">Operable</div>

                {quote.transportType === "WHITEGLOVE" && (
                  <div className="table-item">Quote</div>
                )}

                {quote.transportType !== "WHITEGLOVE" &&
                  settings &&
                  settings &&
                  settings.serviceLevels.map((serviceLevel) => {
                    return (
                      <div key={serviceLevel.name} className="table-item">
                        {serviceLevel.name}
                      </div>
                    );
                  })}
              </div>

              <div
                className={`table table-header quote-detail-table ${quote.transportType ===
                  "WHITEGLOVE" && "white-glove"}`}
              >
                <div
                  style={{ borderBottom: "none" }}
                  className="table-item"
                ></div>
                <div
                  style={{ borderBottom: "none" }}
                  className="table-item"
                ></div>
                <div
                  style={{ borderBottom: "none" }}
                  className="table-item"
                ></div>
                <div
                  style={{ borderBottom: "none" }}
                  className="table-item"
                ></div>

                {quote.transportType !== "WHITEGLOVE" &&
                  settings &&
                  settings &&
                  settings.serviceLevels.map((serviceLevel) => {
                    return (
                      <div
                        key={serviceLevel.name}
                        className="table-item table-double-item"
                      >
                        <div
                          className={
                            quote.transportType === "OPEN" ? "bold" : ""
                          }
                        >
                          Open
                        </div>
                        <div
                          className={
                            quote.transportType === "ENCLOSED" ? "bold" : ""
                          }
                        >
                          Encl.
                        </div>
                      </div>
                    );
                  })}
              </div>

              {quote.vehicleQuotes.map((vehicle, i) => {
                return (
                  <div
                    key={vehicle.model + vehicle.make + i}
                    className={`table table-row quote-detail-table ${quote.transportType ===
                      "WHITEGLOVE" && "white-glove"}`}
                  >
                    <div className="table-item">{vehicle.make}</div>
                    <div className="table-item">{vehicle.model}</div>
                    <div className="table-item">{vehicle.pricingClass}</div>
                    <div className="table-item">
                      {vehicle.operableBool ? "Yes" : "No"}
                    </div>

                    {renderVehiclePrices(quote, vehicle.calculatedQuotes)}
                  </div>
                );
              })}

              {quote.vehicleQuotes.length > 1 && (
                <div
                  className={`table quote-detail-table ${quote.transportType ===
                    "WHITEGLOVE" && "white-glove"}`}
                >
                  <div className="table-item"></div>
                  <div className="table-item"></div>
                  <div className="table-item"></div>
                  <div className="table-item">
                    <strong>Total:</strong>
                  </div>

                  {quote.transportType === "WHITEGLOVE" && (
                    <>
                      <div className="table-item total-item test">
                        $
                        {Math.ceil(
                          quote.totalPricing["1"]
                            ?.totalWhiteGloveTransportPortal
                        )}
                      </div>
                    </>
                  )}

                  {quote.transportType !== "WHITEGLOVE" && (
                    <TotalItems quote={quote} />
                  )}
                </div>
              )}
            </div>
          )}

          {showEditForm && (
            <QuoteFormEdit
              // showSpinner={setShowSpinner}
              toggleEditForm={toggleEditForm}
              quote={quote}
            />
          )}
        </div>
      </div>
    );
  } else {
    return <div></div>;
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    portal: state.portals.portal,
    portals: state.portals.portals,
    quotes: state.quotes,
    settings: state.settings.settings, // update
  };
};

export default connect(mapStateToProps)(QuoteDetail);
