import {
  CREATE_ORDER,
  GET_ORDERS,
  GET_ORDER,
  DELETE_ORDER,
  REMOVE_ORDER_FILE,
  TRACK_ORDER,
  ORDER_ERROR,
  CLEAR_ORDER_ERROR,
  UPDATE_ORDER,
  GET_ORDER_STATUS,
  REQUEST_DRIVER_LOCATION,
  UNAUTHORIZE_USER,
  ORDER_TABLE_FILTERS,
  CLEAR_ORDER_TABLE_FILTERS,
  EXPORT_ORDERS,
  CLEAR_CSV,
  ORDERS_ANALYTICS,
} from "../actions/types";

export default (
  state = { orders: [], error: null, filters: null, totals: null },
  action
) => {
  switch (action.type) {
    case CREATE_ORDER:
      return {
        ...state,
        orders: [...state.orders, action.order],
        order: action.order,
      };
    case GET_ORDER:
      return { ...state, order: action.order };
    case DELETE_ORDER:
      return {
        ...state,
        orders: state.orders.filter((order) => {
          return order._id !== action.orderId;
        }),
      };
    case EXPORT_ORDERS:
      return { ...state, csv: action.csv };
    case ORDERS_ANALYTICS:
      return { ...state, totals: action.payload };
    case CLEAR_CSV:
      return { ...state, csv: "", totals: null };
    case GET_ORDERS:
      return {
        ...state,
        orders: action.orders.orders,
        orderCount: action.orders.orderCount,
      };
    case REMOVE_ORDER_FILE:
      return { ...state, order: action.order };
    case TRACK_ORDER:
      return { ...state, trackedOrder: action.order };
    case REQUEST_DRIVER_LOCATION:
      return { ...state, trackedOrder: action.order };
    case GET_ORDER_STATUS:
      return { ...state, customerOrder: action.order };
    case ORDER_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_ORDER_ERROR:
      return { ...state, error: "" };
    case ORDER_TABLE_FILTERS:
      return { ...state, filters: action.filters };
    case CLEAR_ORDER_TABLE_FILTERS:
      return { ...state, filters: null, totals: null };
    case UPDATE_ORDER:
      let orders = state.orders;
      let updatedOrders = orders.map((order) => {
        if (order._id === action.updatedOrder._id) {
          return action.updatedOrder;
        } else {
          return order;
        }
      });
      return { ...state, orders: updatedOrders, order: action.updatedOrder };
    case UNAUTHORIZE_USER:
      return { orders: [], error: null, filters: null, totals: null };
    default:
      return state;
  }
};
