import React, { useState, useEffect } from "react";

function PricingDetail({ quote, settings }) {
  const isWhiteGlove = quote.transportType === "WHITEGLOVE";
  const isOpen = quote.transportType === "OPEN";

  const commission = quote.commission || 0;

  const numVehicles = quote.vehicleQuotes.length;
  const totalCommission = quote.commission * numVehicles;

  const discount =
    quote.portalAdminDiscount > 0 ? quote.portalAdminDiscount : 0;

  const [oneDayTotalCompanyTariff, setOneDayTotalCompanyTariff] = useState(
    null
  );
  const [threeDayTotalCompanyTariff, setThreeDayTotalCompanyTariff] = useState(
    null
  );
  const [fiveDayTotalCompanyTariff, setFiveDayTotalCompanyTariff] = useState(
    null
  );
  const [sevenDayTotalCompanyTariff, setSevenDayTotalCompanyTariff] = useState(
    null
  );

  const [
    oneDayTotalCompanyTariffOpen,
    setOneDayTotalCompanyTariffOpen,
  ] = useState(null);
  const [
    threeDayTotalCompanyTariffOpen,
    setThreeDayTotalCompanyTariffOpen,
  ] = useState(null);
  const [
    fiveDayTotalCompanyTariffOpen,
    setFiveDayTotalCompanyTariffOpen,
  ] = useState(null);
  const [
    sevenDayTotalCompanyTariffOpen,
    setSevenDayTotalCompanyTariffOpen,
  ] = useState(null);

  const [
    oneDayTotalCompanyTariffEnclosed,
    setOneDayTotalCompanyTariffEnclosed,
  ] = useState(null);
  const [
    threeDayTotalCompanyTariffEnclosed,
    setThreeDayTotalCompanyTariffEnclosed,
  ] = useState(null);
  const [
    fiveDayTotalCompanyTariffEnclosed,
    setFiveDayTotalCompanyTariffEnclosed,
  ] = useState(null);
  const [
    sevenDayTotalCompanyTariffEnclosed,
    setSevenDayTotalCompanyTariffEnclosed,
  ] = useState(null);

  useEffect(() => {
    if (quote) {
      let oneDay = 0;
      let threeDay = 0;
      let fiveDay = 0;
      let sevenDay = 0;

      let oneDayEnclosed = 0;
      let threeDayEnclosed = 0;
      let fiveDayEnclosed = 0;
      let sevenDayEnclosed = 0;

      let oneDayOpen = 0;
      let threeDayOpen = 0;
      let fiveDayOpen = 0;
      let sevenDayOpen = 0;

      // If there is a company tariff
      if (
        !quote.portal.hasVariableCompanyTariff &&
        quote.vehicleQuotes[0].calculatedQuotes[0].companyTariff
      ) {
        quote.vehicleQuotes.forEach((q) => {
          oneDay += parseInt(q.calculatedQuotes[0].companyTariff);
          threeDay += parseInt(q.calculatedQuotes[1].companyTariff);
          fiveDay += parseInt(q.calculatedQuotes[2].companyTariff);
          sevenDay += parseInt(q.calculatedQuotes[3].companyTariff);
        });
      } else if (quote.portal.hasVariableCompanyTariff) {
        quote.vehicleQuotes.forEach((q) => {
          oneDayEnclosed += parseInt(
            q.calculatedQuotes[0].companyTariffEnclosed
          );
          threeDayEnclosed += parseInt(
            q.calculatedQuotes[1].companyTariffEnclosed
          );
          fiveDayEnclosed += parseInt(
            q.calculatedQuotes[2].companyTariffEnclosed
          );
          sevenDayEnclosed += parseInt(
            q.calculatedQuotes[3].companyTariffEnclosed
          );

          oneDayOpen += parseInt(q.calculatedQuotes[0].companyTariffOpen);
          threeDayOpen += parseInt(q.calculatedQuotes[1].companyTariffOpen);
          fiveDayOpen += parseInt(q.calculatedQuotes[2].companyTariffOpen);
          sevenDayOpen += parseInt(q.calculatedQuotes[3].companyTariffOpen);
        });
      }

      setOneDayTotalCompanyTariff(oneDay);
      setThreeDayTotalCompanyTariff(threeDay);
      setFiveDayTotalCompanyTariff(fiveDay);
      setSevenDayTotalCompanyTariff(sevenDay);

      setOneDayTotalCompanyTariffEnclosed(oneDayEnclosed);
      setThreeDayTotalCompanyTariffEnclosed(threeDayEnclosed);
      setFiveDayTotalCompanyTariffEnclosed(fiveDayEnclosed);
      setSevenDayTotalCompanyTariffEnclosed(sevenDayEnclosed);

      setOneDayTotalCompanyTariffOpen(oneDayOpen);
      setThreeDayTotalCompanyTariffOpen(threeDayOpen);
      setFiveDayTotalCompanyTariffOpen(fiveDayOpen);
      setSevenDayTotalCompanyTariffOpen(sevenDayOpen);
    }
  }, [quote]);

  return (
    <div className="pricing-detail">
      {!isWhiteGlove && (
        <div className="table table-header pricing-detail-table">
          <div className="table-item"></div>

          {settings.serviceLevels.map((serviceLevel) => {
            return (
              <div key={serviceLevel.name} className="table-item">
                {serviceLevel.name}
              </div>
            );
          })}
        </div>
      )}

      {!isWhiteGlove && (
        <div className="table table-row pricing-detail-table hide-white-glove">
          <div className="table-item"></div>
          <div className="table-item table-double-item">
            <div className={isOpen ? "bold" : ""}>Open</div>
            <div className={!isOpen ? "bold" : ""}>Encl.</div>
          </div>
          <div className="table-item table-double-item">
            <div className={isOpen ? "bold" : ""}>Open</div>
            <div className={!isOpen ? "bold" : ""}>Encl.</div>
          </div>
          <div className="table-item table-double-item">
            <div className={isOpen ? "bold" : ""}>Open</div>
            <div className={!isOpen ? "bold" : ""}>Encl.</div>
          </div>
          <div className="table-item table-double-item">
            <div className={isOpen ? "bold" : ""}>Open</div>
            <div className={!isOpen ? "bold" : ""}>Encl.</div>
          </div>
        </div>
      )}

      {commission > 0 && (
        <div
          className={`table table-row pricing-detail-table ${isWhiteGlove &&
            "white-glove"}`}
        >
          <div className="table-item">Vehicle Total</div>

          {!isWhiteGlove &&
            settings.serviceLevels.map((serviceLevel) => {
              let preDiscountOpen, preDiscountEncl;

              let openTotalPrice =
                quote.totalPricing[serviceLevel.value]
                  .totalOpenTransportPortal - totalCommission;

              let enclTotalPrice =
                quote.totalPricing[serviceLevel.value]
                  .totalEnclosedTransportPortal - totalCommission;

              if (discount) {
                preDiscountOpen = openTotalPrice + discount * numVehicles;
                preDiscountEncl = enclTotalPrice + discount * numVehicles;
              }

              return (
                <div
                  key={serviceLevel.value}
                  className="table-item table-double-item"
                >
                  <div className={isOpen ? "open-price bold" : "open-price"}>
                    {preDiscountOpen && (
                      <span className="strikethrough">
                        ${Math.ceil(preDiscountOpen)}
                      </span>
                    )}
                    ${Math.ceil(openTotalPrice)}
                  </div>
                  <div className={!isOpen ? "encl-price bold" : "encl-price"}>
                    {preDiscountEncl && (
                      <span className="strikethrough">
                        ${Math.ceil(preDiscountEncl)}
                      </span>
                    )}
                    ${Math.ceil(enclTotalPrice)}
                  </div>
                </div>
              );
            })}

          {isWhiteGlove && (
            <div className="table-item">
              {discount > 0 && (
                <span className="strikethrough">
                  $
                  {quote.totalPricing[1].totalWhiteGloveTransportPortal +
                    discount * numVehicles -
                    totalCommission}
                </span>
              )}
              $
              {quote.totalPricing[1].totalWhiteGloveTransportPortal -
                totalCommission}
            </div>
          )}
        </div>
      )}

      {oneDayTotalCompanyTariff > 0 && commission > 0 && (
        <div
          className={`table table-row pricing-detail-table ${isWhiteGlove &&
            "white-glove"}`}
        >
          <div className="table-item">McCollister's Rate</div>

          {!isWhiteGlove &&
            settings.serviceLevels.map((serviceLevel) => {
              return (
                <div
                  key={serviceLevel.value}
                  className="table-item table-double-item"
                >
                  <div className={isOpen ? "open-price bold" : "open-price"}>
                    $
                    {
                      quote.totalPricing[serviceLevel.value]
                        .totalOpenTransportSD
                    }
                  </div>
                  <div className={!isOpen ? "encl-price bold" : "encl-price"}>
                    $
                    {Math.ceil(
                      quote.totalPricing[serviceLevel.value]
                        ?.totalEnclosedTransportSD
                    )}
                  </div>
                </div>
              );
            })}

          {isWhiteGlove && (
            <div className="table-item">
              ${Math.ceil(quote.totalPricing[1]?.totalWhiteGloveTransportSD)}
            </div>
          )}
        </div>
      )}

      {oneDayTotalCompanyTariff > 0 ||
        (oneDayTotalCompanyTariffEnclosed > 0 && (
          <div
            className={`table table-row pricing-detail-table ${quote.transportType ===
              "WHITEGLOVE" && "white-glove"}`}
          >
            <div className="table-item">Company Tariff</div>

            <div key="1" className="table-item table-double-item">
              <div className={isOpen ? "open-price bold" : "open-price"}>
                $
                {quote.portal.hasVariableCompanyTariff
                  ? Math.ceil(oneDayTotalCompanyTariffOpen)
                  : Math.ceil(oneDayTotalCompanyTariff)}
              </div>

              {quote.transportType !== "WHITEGLOVE" && (
                <div className={!isOpen ? "encl-price bold" : "encl-price"}>
                  $
                  {quote.portal.hasVariableCompanyTariff
                    ? Math.ceil(oneDayTotalCompanyTariffEnclosed)
                    : Math.ceil(oneDayTotalCompanyTariff)}
                </div>
              )}
            </div>

            <div
              key="3"
              className="table-item table-double-item hide-white-glove"
            >
              <div className={isOpen ? "open-price bold" : "open-price"}>
                {/* {discount > 0 && (
              <span className="strikethrough">
                ${discount + threeDayTotalCompanyTariff}
              </span>
            )} */}
                $
                {quote.portal.hasVariableCompanyTariff
                  ? Math.ceil(threeDayTotalCompanyTariffOpen)
                  : Math.ceil(threeDayTotalCompanyTariff)}
              </div>

              <div className={!isOpen ? "encl-price bold" : "encl-price"}>
                $
                {quote.portal.hasVariableCompanyTariff
                  ? Math.ceil(threeDayTotalCompanyTariffEnclosed)
                  : Math.ceil(threeDayTotalCompanyTariff)}
              </div>
            </div>

            <div
              key="5"
              className="table-item table-double-item hide-white-glove"
            >
              <div className={isOpen ? "open-price bold" : "open-price"}>
                {" "}
                $
                {quote.portal.hasVariableCompanyTariff
                  ? Math.ceil(fiveDayTotalCompanyTariffOpen)
                  : Math.ceil(fiveDayTotalCompanyTariff)}
              </div>
              <div className={!isOpen ? "encl-price bold" : "encl-price"}>
                $
                {quote.portal.hasVariableCompanyTariff
                  ? Math.ceil(fiveDayTotalCompanyTariffEnclosed)
                  : Math.ceil(fiveDayTotalCompanyTariff)}
              </div>
            </div>

            <div
              key="7"
              className="table-item table-double-item hide-white-glove"
            >
              <div className={isOpen ? "open-price bold" : "open-price"}>
                $
                {quote.portal.hasVariableCompanyTariff
                  ? Math.ceil(sevenDayTotalCompanyTariffOpen)
                  : Math.ceil(sevenDayTotalCompanyTariff)}
              </div>
              <div className={!isOpen ? "encl-price bold" : "encl-price"}>
                $
                {quote.portal.hasVariableCompanyTariff
                  ? Math.ceil(sevenDayTotalCompanyTariffEnclosed)
                  : Math.ceil(sevenDayTotalCompanyTariff)}
              </div>
            </div>
          </div>
        ))}

      {commission !== 0 && (
        <div
          className={`table table-row pricing-detail-table ${quote.transportType ===
            "WHITEGLOVE" && "white-glove"}`}
        >
          <div className="table-item">Commission</div>
          <div className="table-item table-double-item">
            <div className={isOpen ? "bold" : ""}>
              ${commission ? totalCommission : 0}
            </div>
            {quote.transportType !== "WHITEGLOVE" && (
              <div className={!isOpen ? "bold" : ""}>
                ${commission ? totalCommission : 0}
              </div>
            )}
          </div>

          {Array.from({ length: 3 }).map((_, index) => (
            <div
              key={index}
              className="table-item hide-white-glove table-double-item"
            >
              <div className={isOpen ? "bold" : ""}>
                ${commission ? totalCommission : 0}
              </div>
              <div className={!isOpen ? "bold" : ""}>
                ${commission ? totalCommission : 0}
              </div>
            </div>
          ))}
        </div>
      )}

      <div
        className={`table table-row pricing-detail-table ${isWhiteGlove &&
          "white-glove"}`}
      >
        <div className="table-item">Total</div>

        {!isWhiteGlove &&
          settings.serviceLevels.map((serviceLevel) => {
            let preDiscountOpen, preDiscountEncl;

            let openTotalPrice =
              quote.totalPricing[serviceLevel.value].totalOpenTransportPortal;
            let enclTotalPrice =
              quote.totalPricing[serviceLevel.value]
                .totalEnclosedTransportPortal;

            if (discount) {
              preDiscountOpen = openTotalPrice + discount * numVehicles;
              preDiscountEncl = enclTotalPrice + discount * numVehicles;
            }

            return (
              <div
                key={serviceLevel.value}
                className="table-item table-double-item"
              >
                <div className={isOpen ? "open-price bold" : "open-price"}>
                  {preDiscountOpen && (
                    <span className="strikethrough">
                      ${Math.ceil(preDiscountOpen)}
                    </span>
                  )}
                  ${Math.ceil(openTotalPrice)}
                </div>
                <div className={!isOpen ? "encl-price bold" : "encl-price"}>
                  {preDiscountEncl && (
                    <span className="strikethrough">
                      ${Math.ceil(preDiscountEncl)}
                    </span>
                  )}
                  ${Math.ceil(enclTotalPrice)}
                </div>
              </div>
            );
          })}

        {isWhiteGlove && (
          <div className="table-item">
            {discount > 0 && (
              <span className="strikethrough">
                $
                {quote.totalPricing[1].totalWhiteGloveTransportPortal +
                  discount * numVehicles}
              </span>
            )}
            ${quote.totalPricing[1].totalWhiteGloveTransportPortal}
          </div>
        )}
      </div>
    </div>
  );
}

export default PricingDetail;
