import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components";

import { sendEmail } from "../../actions/emails";

import { useForm, ErrorMessage } from "react-hook-form";

const Logo = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 15px;

  img {
    max-width: 250px;
  }
`;

const Form = styled.form`
  background-color: white;
  border-radius: 5px;
  max-width: 750px;
  margin: 0 auto;
  padding: 25px;
  margin-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
`;

const Label = styled.label`
  color: #00274c;
  font-weight: bold;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 47%;
  @media (max-width: 750px) {
    width: 100%;
  }
`;

const InputContainerFull = styled(InputContainer)`
  width: 100%;
`;

const Input = styled.input`
  background-color: #e1e1e1;
  border: none;
  border-radius: 5px;
  padding: 5px;
  &:hover {
    text-decoration: none;
    border: none;
  }
`;

const ContainerFull = styled.div`
  width: 100%;
`;

const Button = styled.button`
  cursor: pointer;
  background-color: #238ac9;
  color: white;
  width: 100%;
  border-radius: 5px;
  height: 40px;
  font-size: 2rem;
  text-transform: unset;
`;

const FormError = styled.p`
  text-align: center;
  color: #b92525;
  font-style: italic;
`;

const ListItem = styled.li`
  font-style: italic;
  margin-bottom: 5px;
`;

function CarriersForm(props) {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors } = useForm();
  const [showConfirmation, setShowConfirmation] = useState(false);

  const onSubmit = (formData) => {
    formData.templateId = "Carriers";

    props.dispatch(
      sendEmail(formData, () => {
        setShowConfirmation(true);
      })
    );
  };

  const logo =
    "https://res.cloudinary.com/dq27r8cov/image/upload/v1616097775/McCollister%27s/mccollisters-auto-logistics.png";
  const sdlogo =
    "https://res.cloudinary.com/dq27r8cov/image/upload/v1693437899/McCollister%27s/super-dispatch-logo.png";

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Logo>
        <img src={logo} alt="logo" />
      </Logo>
      {!showConfirmation && (
        <ContainerFull>
          <div>
            <p
              style={{
                fontWeight: "bold",
              }}
            >
              We’re looking for Blue Chip carriers to join our Carrier Network.
            </p>
            <p>What is a Blue Chip carrier? We’re glad you asked!</p>
            <p>
              If you are dependable, ultra-communicative, accurate with arrival
              estimates, and transparent with any issues that might arise…
              congratulations, you are a Blue Chip carrier! We want to build a
              partnership with you. We will do our best to provide you with
              high-quality loads, internal support, and a team dedicated to
              filling your trucks fast so you can concentrate on running your
              business.
            </p>
            <p>
              Apply now by completing the form below, and we will be in touch!
            </p>
          </div>
        </ContainerFull>
      )}

      {showConfirmation && (
        <ContainerFull>
          <p>
            Thank you for your interest! Someone from the McCollister's team
            will reach out to you shortly.
          </p>
        </ContainerFull>
      )}

      {!showConfirmation && (
        <>
          <InputContainer>
            <Label htmlFor="firstName">First Name</Label>
            <Input
              placeholder="First Name"
              type="text"
              name="firstName"
              ref={register}
            />
          </InputContainer>
          <InputContainer>
            <Label htmlFor="lastName">Last Name</Label>
            <Input
              placeholder="Last Name"
              type="text"
              name="lastName"
              ref={register}
            />
          </InputContainer>
          <InputContainer>
            <Label htmlFor="email">Email</Label>
            <Input
              placeholder="Email"
              type="text"
              name="email"
              ref={register({
                required: "An email address is required.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: "Please enter a valid email address.",
                },
              })}
            />
            <ErrorMessage errors={errors} name="email">
              {({ message }) => <p className="form-error-message">{message}</p>}
            </ErrorMessage>
          </InputContainer>
          <InputContainer>
            <Label htmlFor="phone">Phone</Label>
            <Input
              placeholder="Phone"
              type="text"
              name="phone"
              ref={register}
            />
          </InputContainer>
          <InputContainerFull>
            <Label htmlFor="company">Company</Label>
            <Input
              placeholder="Company"
              type="text"
              name="company"
              ref={register({ required: "Company name is required." })}
            />
            <ErrorMessage errors={errors} name="company">
              {({ message }) => <p className="form-error-message">{message}</p>}
            </ErrorMessage>
          </InputContainerFull>
          <ContainerFull>
            <FormError>{props.emails.error && props.emails.error}</FormError>
          </ContainerFull>
          <ContainerFull>
            <Button type="submit">Submit</Button>
          </ContainerFull>
        </>
      )}

      {!showConfirmation && (
        <ContainerFull>
          <div>
            <p>
              We make it easy to work with us: no app to download, fast payment,
              and exclusive loads.
            </p>
            <p>
              Our preferred Carrier Technology is{" "}
              <a
                style={{
                  color: "#238ac9",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
                href="https://get.superd.co/ati-show"
                target="_blank"
                rel="nofollow noreferrer"
              >
                Super Dispatch.
              </a>
            </p>
            <img style={{ width: "200px" }} src={sdlogo} />
            <p>
              Take control of your Carrier operations so you can move cars
              faster, easier and smarter.
            </p>
            <p style={{ fontStyle: "italic" }}>
              Here are the most significant benefits for you to use Super
              Dispatch:
            </p>
            <ol>
              <ListItem>
                Invoice within three clicks, no need to send separate paperwork.
              </ListItem>
              <ListItem>
                Avoid false damage claims with geotagged inspection pictures.
              </ListItem>
              <ListItem>
                Work easier with better communication through automatic status
                updates.
              </ListItem>
              <ListItem>
                Book loads 24/7 with free access to the Super Loadboard.
              </ListItem>
              <ListItem>
                Eliminate paperwork with the industry-leading eBOL/ePOD.
              </ListItem>
              <ListItem>
                Quickly get loads from McCollister's and assign them to your
                drivers in seconds.
              </ListItem>
            </ol>
            <p style={{ textAlign: "center" }}>
              <a
                style={{
                  color: "#238ac9",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
                href="https://get.superd.co/ati-show"
                target="_blank"
                rel="nofollow noreferrer"
              >
                Click here to sign up for Super Dispatch.
              </a>
            </p>
          </div>
        </ContainerFull>
      )}
    </Form>
  );
}

// Provides access to newQuote state
const mapStateToProps = (state) => {
  return {
    emails: state.emails,
  };
};

export default connect(mapStateToProps)(CarriersForm);
