import { CREATE_USER, CLEAR_USER_ERROR, USER_ERROR } from "../actions/types";

export default (
  state = { users: [], user: {}, userToEdit: null, error: null },
  action
) => {
  switch (action.type) {
    case "GET_USER":
      return { ...state, users: [...state.users], user: action.user };
    case "ADMIN_GET_USER":
      return { ...state, userToEdit: action.user };
    case CREATE_USER:
      return {
        ...state,
        users: [...state.users, action.user],
        user: action.user,
      };
    case "UPDATE_USER":
      let updatedUsers = state.users.map((user) => {
        if (user._id === action.user._id) {
          return action.user;
        } else {
          return user;
        }
      });
      return { ...state, users: updatedUsers, userToEdit: action.user };
    case "DELETE_USER":
      break;

    case "GET_USERS":
      return { ...state, users: action.users };
    case "UNAUTHORIZE_USER":
      return { users: [], user: {}, userToEdit: null, error: null };
    case USER_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_USER_ERROR:
      return { ...state, error: "" };
    default:
      return state;
  }
};
