import React from "react";

import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { loginEmail2fa } from "../../actions/auth";

import { Form, Input, Button } from "antd";

function AuthForm(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  if (props.auth.authenticated) {
    history.push("/get-quote");
  }

  if (!props.email) {
    props.returnToLogin();
    history.push("/login");
  }

  const onFinish = (values) => {
    values.email = props.email;
    dispatch(loginEmail2fa(values, () => history.push("/get-quote")));
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="auth-form"
      requiredMark={false}
      onFinish={onFinish}
    >
      {props.auth.error && (
        <p className="auth-error-message">{props.auth.error}</p>
      )}
      <Form.Item
        label="Enter the verification code emailed to you."
        name="code"
        rules={[
          {
            required: true,
            message: "Please enter code.",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <Button
          style={{
            borderRadius: "2px",
            cursor: "pointer",
            width: "100%",
          }}
          type="primary"
          htmlType="submit"
        >
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AuthForm);
