import {
  FILTER_USERS,
  FILTER_QUOTES,
  FILTER_ORDERS,
  FILTER_PORTALS,
  RESET_FILTERS,
} from "../actions/types";

export default (
  state = { users: null, quotes: null, orders: null, portals: null },
  action
) => {
  switch (action.type) {
    case FILTER_USERS:
      return { ...state, users: action.users };
    case FILTER_QUOTES:
      return { ...state, quotes: action.quotes };
    case FILTER_ORDERS:
      return { ...state, orders: action.orders };
    case FILTER_PORTALS:
      return { ...state, portals: action.portals };
    case RESET_FILTERS:
      return {
        ...state,
        users: null,
        quotes: null,
        orders: null,
        portals: null,
      };
    default:
      return state;
  }
};
