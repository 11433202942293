// 3rd Party Libraries
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { getQuote, getQuotePublic } from "../../actions/quotes";
import { getSettings } from "../../actions/settings";
import { Card } from "antd";

import OrderForm from "./OrderForm.js";
import OrderFormPublic from "./OrderFormPublic.js";
import QuoteDetail from "../QuoteDetail/QuoteDetail.js";
import QuoteDetailPublic from "../QuoteDetail/QuoteDetailPublic.js";
import Spinner from "../../common/components/Spinner/Spinner.js";

function BookOrder({ quote, auth, settings, error }) {
  let { quoteId } = useParams();
  let history = useHistory();
  const [showSpinner, setShowSpinner] = useState(false);
  const [quoteToBook, setQuoteToBook] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSettings());
  }, []);

  useEffect(() => {
    if (auth.authenticated) {
      dispatch(getQuote(quoteId));
    } else {
      dispatch(getQuotePublic(quoteId));
    }
  }, [quoteId]);

  useEffect(() => {
    if (quote) {
      setQuoteToBook(quote);

      if (quote.status === "Booked") {
        setShowConfirmation(true);
      }
    }
  }, [quote]);

  if (!quoteId || quoteId == "undefined") {
    history.push("/quote");
  }

  return (
    <div className="book-order-container view-content-container">
      {auth.authenticated && (
        <div className="view-header">
          <h1>Book Order</h1>
        </div>
      )}

      {showSpinner && <Spinner />}

      {showConfirmation && (
        <Card
          title="Your order has been booked successfully."
          bordered={false}
          style={{ width: "100%" }}
        >
          <div>
            Thank you for your order! Please check your email for an order
            confirmation.
          </div>
        </Card>
      )}

      {settings && auth.authenticated && <QuoteDetail isOrder={true} />}

      {settings && !auth.authenticated && <QuoteDetailPublic isOrder={true} />}

      {quoteToBook && settings && auth.authenticated && !showConfirmation && (
        <OrderForm
          showSpinner={() => setShowSpinner(true)}
          quote={quoteToBook}
        />
      )}

      {quoteToBook && settings && !auth.authenticated && !showConfirmation && (
        <OrderFormPublic
          showSpinner={() => setShowSpinner(true)}
          quote={quoteToBook}
          orderComplete={() => setShowConfirmation(true)}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    quote: state.quotes.quote,
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(BookOrder);
