import {
  CREATE_PORTAL,
  ADMIN_GET_PORTAL,
  GET_PORTAL,
  UPDATE_PORTAL,
  GET_PORTALS,
  PORTAL_ERROR,
  CLEAR_PORTAL_ERROR,
  UNAUTHORIZE_USER,
  GET_REGIONS,
} from "../actions/types";

export default (
  state = {
    portals: [],
    portal: {},
    regions: [],
    portalToEdit: null,
    error: null,
  },
  action
) => {
  switch (action.type) {
    case CREATE_PORTAL:
      return {
        ...state,
        portals: [...state.portals, action.portal],
        portal: action.portal,
      };
    case ADMIN_GET_PORTAL:
      return { ...state, portalToEdit: action.portal };
    case GET_PORTAL:
      return { ...state, portals: [...state.portals], portal: action.portal };
    case GET_REGIONS:
      return { ...state, regions: action.regions };

    case UPDATE_PORTAL:
      let updatedPortals = state.portals.map((portal) => {
        if (portal._id === action.portal._id) {
          return action.portal;
        } else {
          return portal;
        }
      });
      return { ...state, portals: updatedPortals, portalToEdit: action.portal };
    case GET_PORTALS:
      let alphaPortals = action.portals.sort(function(a, b) {
        return a.companyName < b.companyName
          ? -1
          : a.companyName > b.companyName
          ? 1
          : 0;
      });

      return { ...state, portals: alphaPortals };
    case PORTAL_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_PORTAL_ERROR:
      return { ...state, error: "" };
    case UNAUTHORIZE_USER:
      return { portals: [], portal: {}, portalToEdit: null, error: null };
    default:
      return state;
  }
};
