import {
  AUTHORIZE_USER,
  UNAUTHORIZE_USER,
  AUTH_ERROR,
  CLEAR_AUTH_ERROR,
  AUTH_MESSAGE,
  CLEAR_AUTH_MESSAGE,
} from "../actions/types";

export default function(state = { error: "", authenticated: false }, action) {
  switch (action.type) {
    case AUTHORIZE_USER:
      return {
        ...state,
        error: "",
        authenticated: true,
        role: action.role,
        userId: action.userId,
        portalId: action.portalId,
      };
    case UNAUTHORIZE_USER:
      return {
        ...state,
        authenticated: false,
        role: "",
        userId: "",
        error: "",
        portalId: null,
      };
    case AUTH_ERROR:
      return {
        error: action.payload,
        authenticated: false,
        role: "",
        userId: "",
        portalId: null,
      };
    case CLEAR_AUTH_ERROR:
      return { ...state, error: "" };
    case AUTH_MESSAGE:
      return { ...state, message: action.payload };
    case CLEAR_AUTH_MESSAGE:
      return { ...state, message: "" };
    default:
      return state;
  }
}
