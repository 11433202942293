import axios from "axios";
import {
  GET_SETTINGS,
  UPDATE_SETTINGS,
  GET_MAKES,
  FILTER_PORTAL,
  SETTINGS_ERROR,
  CLEAR_SETTINGS_ERROR,
} from "./types";

import { authError } from "./auth";
import { rootUrl } from "../config/keys";

const config = () => {
  return {
    headers: { Authorization: "JWT " + localStorage.getItem("token") },
  };
};

export const filterPortal = (data) => ({
  type: FILTER_PORTAL,
  portal: data,
});

export const getMakes = () => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/makes`)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getMakesSuccess(data));
        dispatch(clearSettingsError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(settingsError(err.response.data.message));
          }
        } else {
          dispatch(
            settingsError("There was an error loading makes and models.")
          );
        }
      });
  };
};

export const getSettings = () => {
  return (dispatch) => {
    return axios
      .get(`${rootUrl}/settings`, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(getSettingsSuccess(data));
        dispatch(clearSettingsError());
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(settingsError(err.response.data.message));
          }
        } else {
          dispatch(settingsError("There was an error getting settings."));
        }
      });
  };
};

export const updateSettings = (formData, role, callback) => {
  return (dispatch) => {
    let uri = `${rootUrl}/settings`;
    formData.role = role;

    return axios
      .put(uri, formData, config())
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(updateSettingsSuccess(data));
        dispatch(clearSettingsError());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          if (err.response.status === 401) {
            dispatch(authError(err.response.data.message));
          } else {
            dispatch(settingsError(err.response.data.message));
          }
        } else {
          dispatch(settingsError("There was an error updating settings."));
        }
      });
  };
};

export const getMakesSuccess = (data) => ({
  type: GET_MAKES,
  makes: data,
});

export const getSettingsSuccess = (data) => ({
  type: GET_SETTINGS,
  settings: data,
});

export const updateSettingsSuccess = (data) => ({
  type: UPDATE_SETTINGS,
  settings: data,
});

export function settingsError(error) {
  return {
    type: SETTINGS_ERROR,
    payload: error,
  };
}

export function clearSettingsError() {
  return {
    type: CLEAR_SETTINGS_ERROR,
  };
}
