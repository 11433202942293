import { Helmet } from "react-helmet";
import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { MinusCircleOutlined } from "@ant-design/icons";
import { MaskedInput } from "antd-mask-input";
import Button from "../../common/components/Button/Button";

import {
  Form,
  Card,
  Input,
  Divider,
  Select,
  Switch,
  Checkbox,
  DatePicker,
} from "antd";

// Redux Actions
import { createOrder } from "../../actions/orders";
import { updateQuote } from "../../actions/quotes";
import { addMessage } from "../../actions/message";

function OrderFormPublic({ auth, settings, quote, orderComplete, error }) {
  const [form] = Form.useForm();
  const [isProcessing, setIsProcessing] = useState(false);
  const dispatch = useDispatch();
  let quotePortal;

  useEffect(() => {
    if (auth.authenticated) {
      history.push(`/orders/book/${quote._id}`);
    }
  }, [auth]);

  let defaultPickupAddress,
    defaultDeliveryAddress,
    defaultPickupZip,
    defaultDeliveryZip,
    defaultDeliveryNotes,
    defaultPickupNotes;

  // For testing, delete later
  // defaultPickupAddress = "123 Test St.";
  // defaultDeliveryAddress = "ABC Test Rd";
  // defaultPickupZip = "80465";
  // defaultDeliveryZip = "02492";
  // defaultDeliveryNotes = "Here are some delivery notes.";
  // defaultPickupNotes = "Pick up at the front.";

  let defaultQuotes = quote.vehicleQuotes.map((v) => {
    return {
      make: v.make,
      model: v.model,
      operableBool: v.operableBool === true ? "Yes" : "No",
      pricingClass: v.pricingClass,
    };
  });

  const pickupCopyCustomer = Form.useWatch("pickupCopyCustomer", form);
  const deliveryCopyCustomer = Form.useWatch("deliveryCopyCustomer", form);

  if (pickupCopyCustomer) {
    form.setFieldsValue({
      pickupContactName: form.getFieldValue("customerFullName"),
      pickupEmail: form.getFieldValue("customerEmail"),
      pickupPrimaryPhone: form.getFieldValue("customerPrimaryPhone"),
      pickupAltPhone: form.getFieldValue("customerAltPhone"),
      pickupPrimaryPhoneIsMobile: form.getFieldValue(
        "customerPrimaryPhoneIsMobile"
      ),
      pickupAltPhoneIsMobile: form.getFieldValue("customerAltPhoneIsMobile"),
    });
  }

  if (deliveryCopyCustomer) {
    form.setFieldsValue({
      deliveryContactName: form.getFieldValue("customerFullName"),
      deliveryEmail: form.getFieldValue("customerEmail"),
      deliveryPrimaryPhone: form.getFieldValue("customerPrimaryPhone"),
      deliveryAltPhone: form.getFieldValue("customerAltPhone"),
      deliveryPrimaryPhoneIsMobile: form.getFieldValue(
        "customerPrimaryPhoneIsMobile"
      ),
      deliveryAltPhoneIsMobile: form.getFieldValue("customerAltPhoneIsMobile"),
    });
  }

  const onFinish = (values) => {
    values.quoteId = quote._id;
    values.portalId = quote.portalId || quote.portal;

    let msg = "Your order has been booked.";

    setIsProcessing(true);

    dispatch(
      createOrder(values, history, () => {
        dispatch(updateQuote({ status: "Booked" }, quote._id, null));
        dispatch(addMessage(msg));
        orderComplete();
        setIsProcessing(false);
        if (typeof window !== "undefined") {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "formSubmission",
            formID: "Book Order",
            quoteID: quote.uniqueId,
          });
        }
      })
    );
  };

  quotePortal = quote.portal;

  let pZip, pState, pCity, dZip, dState, dCity;
  let quotePickupArr = quote.pickup.split(",");

  quotePickupArr.forEach((item) => {
    if (item.match(/[^a-z]{2}\s[0-9]{5}/)) {
      let newArr = item.match(/[^a-z]{2}\s[0-9]{5}/)[0].split(" ");
      pState = newArr[0];
      pZip = newArr[1];
    } else if (item.match(/[0-9]{5}/)) {
      pZip = item.match(/\d+/)[0];
    } else if (item.match(/[A-Z]{2}/) && item.match(/[A-Z]{2}/) !== "US") {
      pState = item.match(/[A-Z]{2}/)[0];
    } else {
      pCity = item;
    }
  });

  let quoteDeliveryArr = quote.delivery.split(",");

  quoteDeliveryArr.forEach((item) => {
    if (item.match(/[^a-z]{2}\s[0-9]{5}/)) {
      let newArr = item.match(/[^a-z]{2}\s[0-9]{5}/)[0].split(" ");
      dState = newArr[0];
      dZip = newArr[1];
    } else if (item.match(/[0-9]{5}/)) {
      dZip = item.match(/\d+/)[0];
    } else if (item.match(/[A-Z]{2}/) && item.match(/[A-Z]{2}/) !== "US") {
      dState = item.match(/[A-Z]{2}/)[0];
    } else {
      dCity = item;
    }
  });

  const [pickupCity] = useState(pCity);
  const [pickupState] = useState({ value: pState, label: pState });

  const [deliveryCity] = useState(dCity);
  const [deliveryState] = useState({ value: dState, label: dState });

  let history = useHistory();

  let states_list = [];
  let states = [
    "AL",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FM",
    "FL",
    "GA",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MH",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PW",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  states.forEach((item) => {
    states_list.push(<Select.Option value={item}>{item}</Select.Option>);
  });

  let holidayDates = settings.holidays.map((holiday) => {
    let date = new Date(holiday.date);
    return date;
  });

  const getDisabledDates = () => {
    return (d) => {
      let dAsDate = d.toDate();
      let year = dAsDate.getFullYear();
      let month = dAsDate.getMonth();
      let date = dAsDate.getDate();

      let isHoliday;
      holidayDates.forEach((hd) => {
        if (
          hd.getFullYear() === year &&
          hd.getMonth() === month &&
          hd.getDate() === date
        ) {
          isHoliday = true;
        }
      });

      return (
        !d || d < getMinDate() || d.day() === 0 || d.day() === 6 || isHoliday
      );
    };
  };

  const getMinDate = () => {
    let todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);
    let minDate = new Date(todayDate);
    minDate.setHours(0, 0, 0, 0);
    let count = 0;

    while (count < 3) {
      minDate.setDate(minDate.getDate() + 1);
      let holidayDatesStrings = holidayDates.map((holiday) =>
        holiday.toString()
      );
      let isHoliday = holidayDatesStrings.includes(minDate.toString());
      let day = minDate.getDay();
      // Count Mon - Fri non-holidays only
      if (day !== 0 && day !== 6 && !isHoliday) {
        count++;
      }
    }

    return minDate;
  };

  return (
    <>
      <Helmet>
        <title>Book Order</title>
      </Helmet>
      <div className="view-content-container">
        <Card title="Book Order" bordered={false} style={{ width: "100%" }}>
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 10 }}
            initialValues={{
              customerEmail: quote.customerEmail,
              customerFullName: quote.customerFullName,
              customerPrimaryPhone: quote.customerPhone,
              pickupAddress: defaultPickupAddress,
              pickupCity,
              pickupState: { value: pickupState.value, name: pickupState },
              pickupState: pState,
              pickupZip: pZip || defaultPickupZip,
              pickupNotes: defaultPickupNotes,
              deliveryAddress: defaultDeliveryAddress,
              deliveryCity,
              deliveryState: {
                value: deliveryState.value,
                name: deliveryState,
              },
              deliveryState: dState,
              deliveryZip: dZip || defaultDeliveryZip,
              quotes: defaultQuotes,
              pickupLocationType: null,
              deliveryNotes: defaultDeliveryNotes,
              paymentType: {
                value: "COD",
                name: "COD",
              },
            }}
            className="order-form"
            onFinish={onFinish}
          >
            <Divider>Customer Details</Divider>

            <Form.Item
              label="Full Name"
              name="customerFullName"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="customerEmail"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Primary Phone"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
              name="customerPrimaryPhone"
            >
              <MaskedInput mask={"(000) 000-0000"} />
            </Form.Item>
            <Form.Item
              label="Primary # is Mobile"
              name="customerPrimaryPhoneIsMobile"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item label="Alternative Phone" name="customerAltPhone">
              <MaskedInput mask={"(000) 000-0000"} />
            </Form.Item>
            <Form.Item
              label="Alt # is Mobile"
              name="customerAltPhoneIsMobile"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Divider>Order Details</Divider>

            <Form.Item
              label="Transport Type"
              name="transportType"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Select>
                <Select.Option value="OPEN">Open</Select.Option>
                <Select.Option value="ENCLOSED">Enclosed</Select.Option>
                <Select.Option value="WHITEGLOVE">
                  Enclosed White Glove
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Service Level (Pickup Window)"
              name="serviceLevel"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
            >
              <Select>
                <Select.Option value={1}>1 Day Pickup</Select.Option>
                <Select.Option value={3}>3 Day Pickup</Select.Option>
                <Select.Option value={5}>5 Day Pickup</Select.Option>
                <Select.Option value={7}>7 Day Pickup</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Select 1st Available Pickup Date"
              name="pickupStartDate"
              required
            >
              <p className="form-input-info">
                <strong>Please note:</strong> We require 2 business days between
                the date of booking the order and the 1st available pick up date
                selected. If you have a rush order that needs to be serviced
                less than 2 business days out from the date of booking, please
                contact us directly to confirm we can service the order which
                may be subject to a $200 RUSH fee.
              </p>
              <DatePicker
                onChange={(e) =>
                  form.setFieldsValue({ pickupStartDate: new Date(e) })
                }
                disabledDate={getDisabledDates()}
              />
            </Form.Item>
            <Divider>Pickup Details</Divider>

            <Form.Item
              label="Pickup Address"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
              name="pickupAddress"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Pickup City"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
              name="pickupCity"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="State"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
              name="pickupState"
            >
              <Select>{states_list}</Select>
            </Form.Item>
            <Form.Item
              label="Pickup Zip"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
              name="pickupZip"
            >
              <Input />
            </Form.Item>

            {/* <Form.Item label="Business Name" name="pickupBusinessName">
              <Input />
            </Form.Item>
            <Form.Item label="Contact Name" name="pickupContactName">
              <Input />
            </Form.Item>
            <Form.Item label="Contact Email" name="pickupEmail">
              <Input />
            </Form.Item>
            <Form.Item label="Contact Phone" name="pickupPrimaryPhone">
              <MaskedInput mask={"(000) 000-0000"} />
            </Form.Item>
            <Form.Item
              label="Primary # is Mobile"
              name="pickupPrimaryPhoneIsMobile"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item label="Contact Alternative Phone" name="pickupAltPhone">
              <MaskedInput mask={"(000) 000-0000"} />
            </Form.Item>
            <Form.Item
              label="Alt # is Mobile"
              name="pickupAltPhoneIsMobile"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item> */}
            <Form.Item label="Pickup Notes" name="pickupNotes">
              <Input />
            </Form.Item>
            <Divider>Delivery Details</Divider>

            <Form.Item
              label="Delivery Address"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
              name="deliveryAddress"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Delivery City"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
              name="deliveryCity"
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="State"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
              name="deliveryState"
            >
              <Select style={{ width: "200px" }}>{states_list}</Select>
            </Form.Item>
            <Form.Item
              label="Delivery Zip"
              rules={[
                {
                  required: true,
                  message: "This field is required.",
                },
              ]}
              name="deliveryZip"
            >
              <Input />
            </Form.Item>

            <Form.Item label="Delivery Notes" name="deliveryNotes">
              <Input />
            </Form.Item>

            <Form.List name="quotes">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <div
                      style={{
                        maxWidth: "500px",
                        margin: "50px auto",
                      }}
                      key={field.key}
                    >
                      <Form.Item label="Make" name={[index, "make"]} disabled>
                        <Input disabled type="text" />
                      </Form.Item>

                      <Form.Item label="Model" name={[index, "model"]} disabled>
                        <Input disabled type="text" />
                      </Form.Item>

                      <Form.Item
                        name={[index, "calculatedQuotes"]}
                        hidden={true}
                      >
                        <Input type="text" />
                      </Form.Item>

                      <Form.Item
                        label="Operable"
                        name={[index, "operableBool"]}
                      >
                        <Input disabled type="text" />
                      </Form.Item>

                      <Form.Item name={[index, "price"]} hidden={true}>
                        <Input type="text" />
                      </Form.Item>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "This field is required.",
                          },
                        ]}
                        label="VIN"
                        name={[index, "vin"]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: "This field is required.",
                          },
                        ]}
                        label="Year"
                        name={[index, "year"]}
                      >
                        <Input />
                      </Form.Item>

                      <Form.Item
                        name={[index, "pricingClass"]}
                        hidden={true}
                      ></Form.Item>
                    </div>
                  ))}
                </>
              )}
            </Form.List>

            {quote.vehicleQuotes.map((quote, idx) => {
              return <div key={idx}></div>;
            })}
            <Form.Item
              value={quote.customerFullName}
              name="customerFullName"
              hidden={true}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item value={quote.miles} name="miles" hidden={true}>
              <Input type="text" />
            </Form.Item>
            <Form.Item
              value={quote.transitTime}
              name="transitTime"
              hidden={true}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item value={quote.uniqueId} name="uniqueId" hidden={true}>
              <Input type="text" />
            </Form.Item>
            <Form.Item
              value={quote.transportType}
              name="transportType"
              hidden={true}
            >
              <Input type="text" />
            </Form.Item>
            <Form.Item value={quote.portalId} name="portalId" hidden={true}>
              <Input type="text" />
            </Form.Item>
            <Form.Item
              value={quote.totalPricing}
              name="totalPricing"
              hidden={true}
            >
              <Input type="text" />
            </Form.Item>

            <Form.List name="agents">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <div
                      style={{
                        maxWidth: "500px",
                        margin: "50px auto",
                      }}
                      key={field.key}
                    >
                      <Form.Item name={[index, "name"]} noStyle>
                        <Input
                          style={{
                            width: "90%",
                            marginBottom: "5px",
                          }}
                          placeholder="Name"
                        />
                      </Form.Item>

                      <Form.Item
                        noStyle
                        name={[index, "email"]}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address.",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Email Address"
                          style={{
                            width: "90%",
                            marginBottom: "5px",
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        name={[index, "pickup"]}
                        valuePropName="checked"
                      >
                        <Checkbox>Pick Up Notifications</Checkbox>
                      </Form.Item>

                      <Form.Item
                        name={[index, "delivery"]}
                        valuePropName="checked"
                      >
                        <Checkbox>Delivery Notifications</Checkbox>
                      </Form.Item>

                      {fields.length > 1 ? (
                        <MinusCircleOutlined
                          className="dynamic-delete-button"
                          onClick={() => remove(field.name)}
                        />
                      ) : null}
                    </div>
                  ))}
                </>
              )}
            </Form.List>

            {error && (
              <div className="form-server-response-error">
                {typeof error === "string" && <h3>{error}</h3>}
              </div>
            )}

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                label={
                  isProcessing && !error ? "Processing..." : "Submit Order"
                }
                type="submit"
                style="dark"
              />
            </div>
          </Form>
        </Card>
        )
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    settings: state.settings.settings,
    auth: state.auth,
    quotes: state.quotes,
    error: state.orders.error,
  };
};

export default connect(mapStateToProps)(OrderFormPublic);
