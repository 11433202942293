import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  containier: {
    flex: 1,
    flexDirection: "column",
  },
  priceCard: {
    borderWidth: 1,
    marginTop: 6,
    marginBottom: 3,
    backgroundColor: "#F5F5F5",
    borderColor: "#A9A9A9",
  },
  tableRow: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    height: 40,
  },

  TableColA: {
    width: "10%",
    paddingLeft: 15,
    fontSize: 10,
    color: "#505050",
  },
  TableColB: {
    width: "60%",
    fontSize: 12,
    color: "#202020",
    paddingLeft: 15,
  },
  TableColC: {
    width: "30%",
    justifySelf: "flex-end",
    fontSize: 12,
    fontStyle: "bold",
    textAlign: "right",
    paddingRight: 15,
    color: "#202020",
  },
  discount: {
    width: "30%",
    justifySelf: "flex-end",
    fontSize: 12,
    fontStyle: "bold",
    textAlign: "right",
    paddingRight: 15,
    color: "#202020",
    textDecoration: "line-through",
  },
});

export const PDFPricingTableWhiteGlove = (props) => {
  let price = props.quote.totalPricing?.["1"].totalPortal;
  return (
    <View style={styles.container}>
      <Text style={styles.TableColB}>${price?.toFixed(2)}</Text>
    </View>
  );
};
