import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  containier: {
    flex: 1,
    flexDirection: "column",
  },
  priceCard: {
    borderWidth: 1,
    marginTop: 6,
    marginBottom: 3,
    backgroundColor: "#F5F5F5",
    borderColor: "#A9A9A9",
  },
  tableRow: {
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    height: 20,
  },

  TableColA: {
    width: "10%",
    paddingLeft: 15,
    fontSize: 10,
    color: "#505050",
  },
  TableColB: {
    width: "60%",
    fontSize: 12,
    color: "#202020",
    paddingLeft: 15,
  },
  TableColC: {
    width: "30%",
    justifySelf: "flex-end",
    fontSize: 12,
    fontStyle: "bold",
    textAlign: "right",
    paddingRight: 15,
    color: "#202020",
  },
  discount: {
    width: "30%",
    justifySelf: "flex-end",
    fontSize: 12,
    fontStyle: "bold",
    textAlign: "right",
    paddingRight: 15,
    color: "#202020",
    textDecoration: "line-through",
  },
});

export const PDFPricingTableRows = (props) => {
  let prices = props.prices;
  let priceArray = [];

  for (let price in prices) {
    priceArray.push(prices[price]);
  }

  const totals = priceArray.map((item, i) => {
    return (
      <View style={styles.priceCard} key={i + 1}>
        <View style={styles.tableRow}>
          <Text style={styles.TableColB}>{item.name}</Text>
          <Text style={styles.TableColC}></Text>
        </View>

        <View style={styles.tableRow}>
          <Text style={styles.TableColB}>Open</Text>
          <Text style={styles.TableColC}>${item.totalOpenTransportPortal}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.TableColB}>Enclosed</Text>
          <Text style={styles.TableColC}>
            ${item.totalEnclosedTransportPortal}
          </Text>
        </View>
      </View>
      // <View style={styles.priceCard} key={i + 1}>
      //   {props.quote && props.quote.portalAdminDiscount > 0 && (
      //     <View style={styles.tableRow}>
      //       <Text style={styles.TableColB}>{item.name}</Text>
      //       <Text style={styles.discount}>
      //         ${props.quote.portalAdminDiscount + item.totalPortal}
      //       </Text>
      //     </View>
      //   )}

      //   <View style={styles.tableRow}>
      //     <Text style={styles.TableColB}>
      //       {props.quote.portalAdminDiscount ? "Discounted Price" : item.name}
      //     </Text>
      //     <Text style={styles.TableColC}>${item.totalPortal}</Text>
      //   </View>
      // </View>
    );
  });

  return <View style={styles.container}>{totals}</View>;
};
