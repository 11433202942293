const SurveySingleOrder = ({ orderId, results }) => {
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ fontWeight: "bold", color: "#00274D" }}>
          Order ID #{orderId}
        </h2>
      </div>

      {results &&
        results.map((r) => {
          return (
            <div
              key={r._id}
              style={{
                maxWidth: "500px",
                padding: "10px",
                margin: "0 auto",
                marginBottom: "50px",
              }}
            >
              <div style={{ color: "#00274D", fontWeight: "bold" }}>
                {r.question?.question}
              </div>
              <div
                style={{
                  color: "#00274D",
                  fontWeight: "bold",
                  fontSize: "2.5rem",
                  textAlign: "center",
                }}
              >
                {r.rating}
              </div>
              <div style={{}}>{r.explanation}</div>
            </div>
          );
        })}

      {!results && <div>There were no responses for this order.</div>}
    </div>
  );
};

export default SurveySingleOrder;
