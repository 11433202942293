import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import CODTable from "./CODTable.js";

function COD(props) {
  return (
    <div className="view-orders view-content-container">
      <div className="view-header">
        <h1>
          COD Collection
          <span>
            {props.settings.portalName &&
              props.settings.portalName !== "all" && (
                <span className="view-header-tag">
                  {props.settings.portalName}
                </span>
              )}
          </span>
        </h1>
      </div>

      <CODTable />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    settings: state.settings,
  };
};

export default connect(mapStateToProps)(COD);
