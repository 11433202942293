import { ADD_ALERT, CLEAR_ALERT } from '../actions/types';

export default function (state = null, action) {
    switch (action.type) {
        case ADD_ALERT:
            return action.alert;
        case CLEAR_ALERT:
            return null;
        default:
            return state;
    }
}