import {
  GET_EMAIL_TEMPLATES,
  SEND_EMAIL,
  GET_EMAIL_LOGS,
  EMAIL_ERROR,
  CLEAR_EMAIL_ERROR,
  UNAUTHORIZE_USER,
} from "../actions/types";

export default (
  state = { templates: [], sentEmail: {}, error: "" },
  action
) => {
  switch (action.type) {
    case GET_EMAIL_TEMPLATES:
      return { ...state, templates: action.templates };
    case SEND_EMAIL:
      return { ...state, sentEmail: action.payload, error: "" };
    case GET_EMAIL_LOGS:
      return { ...state, logs: action.payload };
    case EMAIL_ERROR:
      return { ...state, error: action.payload };
    case CLEAR_EMAIL_ERROR:
      return { ...state, error: "" };
    case UNAUTHORIZE_USER:
      return { ...state, templates: [], sentEmail: {}, error: "" };
    default:
      return state;
  }
};
