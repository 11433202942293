import axios from "axios";
import {
  AUTHORIZE_USER,
  UNAUTHORIZE_USER,
  AUTH_ERROR,
  CLEAR_AUTH_ERROR,
  AUTH_MESSAGE,
  CLEAR_AUTH_MESSAGE,
} from "./types";
import { getPortals, getUserPortal } from "./portals";
import { getSettings, getMakes } from "./settings";
import { getUser, getUsers, getPortalUsers } from "./users";
import { getQuotes } from "./quotes";
import { getOrders } from "./orders";

import { rootUrl } from "../config/keys";

const config = {
  headers: { Authorization: "JWT " + localStorage.getItem("token") },
};

export function createUser({ email, password }) {
  return function(dispatch) {
    axios
      .post(`${rootUrl}/user`, { email, password }, config)
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch({ type: AUTHORIZE_USER });
        localStorage.setItem("token", data.token);
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data?.message));
        } else {
          dispatch(authError("There was an error creating this account."));
        }
      });
  };
}

export function loginWithSocial({ clientId, token }, callback) {
  return function(dispatch) {
    // Submits email and password to the server
    axios
      .post(`${rootUrl}/login-social`, { clientId, token })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        localStorage.setItem("token", data.token);
        dispatch({
          type: AUTHORIZE_USER,
          role: data.role,
          userId: data.userId,
          portalId: data.portalId,
        });

        dispatch(getSettings());
        dispatch(getMakes());
        // dispatch(getUser());
        // dispatch(getUserPortal());
        // dispatch(getQuotes());
        // dispatch(getOrders());
        if (data.role === "MCAdmin") {
          dispatch(getPortals());
          dispatch(getUsers());
        }

        // if (data.role === "MCAdmin") {
        //   dispatch(getAllPortals());
        //   dispatch(getAllUsers());
        // } else if (data.role === "Admin") {
        //   dispatch(getPortalUsers());
        // }
        callback();
      })
      .catch((err) => {
        console.log(err);
        if (err && err.response) {
          localStorage.removeItem("token");
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(
            authError(
              "Sorry, there was an error accessing the server. Please try logging in again."
            )
          );
        }
      });
  };
}

export function verifyEmail2fa({ email, password }, callback) {
  return function(dispatch) {
    // Submits email and password to the server
    axios
      .post(`${rootUrl}/verify-email-2fa`, { email, password })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          localStorage.removeItem("token");
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(
            authError(
              "Sorry, there was an error accessing the server. Please try logging in again."
            )
          );
        }
      });
  };
}

export function loginEmail2fa({ email, code }, callback) {
  return function(dispatch) {
    // Submits email and password to the server
    axios
      .post(`${rootUrl}/login-email-2fa`, { email, code })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        localStorage.setItem("token", data.token);
        dispatch({
          type: AUTHORIZE_USER,
          role: data.role,
          userId: data.userId,
          portalId: data.portalId,
        });

        dispatch(getSettings());
        dispatch(getMakes());
        // dispatch(getUser());
        // dispatch(getUserPortal());
        // dispatch(getQuotes());
        // dispatch(getOrders());

        if (data.role === "MCAdmin") {
          dispatch(getPortals());
          dispatch(getUsers());
        }

        // if (data.role === "MCAdmin") {
        //   dispatch(getPortals());
        //   dispatch(getUsers());
        // } else if (data.role === "Admin") {
        //   dispatch(getPortalUsers());
        // }
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          localStorage.removeItem("token");
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(
            authError(
              "Sorry, there was an error accessing the server. Please try logging in again."
            )
          );
        }
      });
  };
}

export function login({ email, password }, callback) {
  return function(dispatch) {
    // Submits email and password to the server
    axios
      .post(`${rootUrl}/login`, { email, password })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        dispatch(clearAuthError());
        localStorage.setItem("token", data.token);

        dispatch({
          type: AUTHORIZE_USER,
          role: data.role,
          userId: data.userId,
          portalId: data.portalId,
        });

        dispatch(getSettings());
        dispatch(getMakes());

        if (data.role === "MCAdmin") {
          dispatch(getPortals());
          dispatch(getUsers());
        }

        // dispatch(getUser());
        // dispatch(getUserPortal());
        // dispatch(getQuotes());
        // dispatch(getOrders());

        // if (data.role === "MCAdmin") {
        //   dispatch(getPortals());
        //   dispatch(getUsers());
        // } else if (data.role === "Admin") {
        //   dispatch(getPortalUsers());
        // }
        callback();
      })
      .catch((err) => {
        if (err && err.response) {
          localStorage.removeItem("token");
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(
            authError(
              "Sorry, there was an error accessing the server. Please try logging in again."
            )
          );
        }
      });
  };
}

export function forgotPassword({ email }, callback) {
  return function(dispatch) {
    axios
      .post(`${rootUrl}/forgotpassword`, { email })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(
            authError("There was an error sending a password reset link.")
          );
        }
      });
  };
}

export function resetPassword({ password, token }, callback) {
  return (dispatch) => {
    return axios
      .post(
        `${rootUrl}/resetpassword`,
        { password },
        {
          headers: { Authorization: "JWT " + token },
        }
      )
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        if (callback) {
          callback();
        }
      })
      .catch((err) => {
        if (err && err.response) {
          dispatch(authError(err.response.data.message));
        } else {
          dispatch(authError("There was an error resetting this password."));
        }
      });
  };
}

export function unauthorizeUser(callback) {
  localStorage.removeItem("token");
  callback();
  return { type: UNAUTHORIZE_USER };
}

export function authMessage(message) {
  return {
    type: AUTH_MESSAGE,
    payload: message,
  };
}

export function clearAuthMessage() {
  return {
    type: CLEAR_AUTH_MESSAGE,
  };
}

export function authError(error) {
  return {
    type: AUTH_ERROR,
    payload: error,
  };
}

export function clearAuthError() {
  return {
    type: CLEAR_AUTH_ERROR,
  };
}
