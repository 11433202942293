import React, { useState } from "react";
import { useParams } from "react-router";
import { connect, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { resetPassword } from "../../actions/auth";

import { Form, Input, Button } from "antd";

function ResetPasswordForm(props) {
  const [success, setSuccess] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  let { token } = useParams();

  if (props.auth.authenticated) {
    history.push("/get-quote");
  }

  const onFinish = (values) => {
    values.token = token;
    dispatch(
      resetPassword(values, () => {
        setSuccess(true);
      })
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="auth-form"
      requiredMark={false}
      onFinish={onFinish}
    >
      {props.auth.error && <p class="auth-error-message">{props.auth.error}</p>}

      {success && (
        <p>
          Your password has been updated.
          <Link
            style={{ marginLeft: "5px" }}
            to="/login"
            onClick={() => {
              props.returnToLogin();
              setSuccess(false);
            }}
          >
            Click here to log in.
          </Link>
        </p>
      )}

      {!success && (
        <>
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Please enter a password.",
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirm"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords must match."));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button
              style={{
                borderRadius: "3px",
                cursor: "pointer",
                width: "100%",
              }}
              type="primary"
              htmlType="submit"
            >
              Update Password
            </Button>
          </Form.Item>
        </>
      )}
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(ResetPasswordForm);
