import React from "react";

import { connect, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import { loginWithSocial, authError, verifyEmail2fa } from "../../actions/auth";

import { Form, Input, Button } from "antd";

function LoginForm(props) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  if (props.auth.authenticated) {
    history.push("/get-quote");
  }

  const onFinish = (values) => {
    let isValidEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
      values.email
    );

    if (!isValidEmail) {
      dispatch(authError("Please enter a valid email address."));
      return;
    }

    values.email = values.email.toLowerCase();
    props.setEmail(values.email);

    dispatch(
      verifyEmail2fa(values, () => {
        props.goToAuth();
        history.push("/auth");
      })
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="auth-form"
      requiredMark={false}
      onFinish={onFinish}
    >
      {props.auth.error && (
        <p className="auth-error-message">{props.auth.error}</p>
      )}
      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Please enter an email address.",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Password"
        name="password"
        rules={[
          {
            required: true,
            message: "Please enter a password.",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button
          style={{
            borderRadius: "2px",
            cursor: "pointer",
            width: "100%",
          }}
          type="primary"
          htmlType="submit"
        >
          Log In
        </Button>
      </Form.Item>
      {process.env.NODE_ENV === "development" && (
        <Form.Item>
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              if (credentialResponse.credential) {
                dispatch(
                  loginWithSocial(
                    {
                      clientId: credentialResponse.clientId,
                      token: credentialResponse.credential,
                    },
                    () => history.push("/get-quote")
                  )
                );
              }
            }}
            onError={(err) => {
              console.log("Login Failed", err);
            }}
          />
        </Form.Item>
      )}
      <div className="auth-links-container">
        <Link to="/forgot-password" onClick={() => props.goToForgotPassword()}>
          Forgot your password?
        </Link>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(LoginForm);
