import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";
import {
  MinusCircleFilled,
  PlusCircleFilled,
  UploadOutlined,
} from "@ant-design/icons";

import {
  Form,
  Card,
  Input,
  Row,
  Col,
  Divider,
  Select,
  InputNumber,
  Switch,
  Upload,
  Checkbox,
} from "antd";

import Button from "../../common/components/Button/Button";

import { MaskedInput } from "antd-mask-input";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { createPortal, getPortalRegions } from "../../actions/portals";
import { uploadLogo, clearFiles } from "../../actions/files";

function PortalMCAdminForm(props) {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const [previewVisible, setPreviewVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewImage, setPreviewImage] = useState(null);
  const [dealershipList, setDealershipList] = useState([]);
  const [regionList, setRegionList] = useState([]);

  const hasCustomRatesOn = Form.useWatch("hasCustomRates", form);
  const hasWhiteGloveOverride = Form.useWatch("hasWhiteGloveOverride", form);

  const isDealership = Form.useWatch("isDealership", form);
  const region = Form.useWatch("region", form);

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = (file) => {
    setPreviewImage(file.thumbUrl);
    setPreviewVisible(true);
  };

  const handleUpload = ({ fileList }) => {
    setFileList({ fileList });

    let uploadInput = fileList[0].originFileObj;
    let file = uploadInput;
    let fileParts = file.name.split(".");
    props.dispatch(uploadLogo(file, fileParts));
  };

  const onFinish = (values) => {
    props.dispatch(createPortal(values, props.files.logo, redirect));
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const onReset = () => {
    form.resetFields();
  };

  const redirect = () => {
    history.push("/portals");
  };

  let states_list = [];
  let states = [
    "AL",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "DC",
    "FM",
    "FL",
    "GA",
    "ID",
    "IL",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MH",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PW",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];

  states.forEach((item) => {
    states_list.push(<Select.Option value={item}>{item}</Select.Option>);
  });

  useEffect(() => {
    dispatch(getPortalRegions("all"));
  }, []);

  useEffect(() => {
    if (props.portals && props.portals.portals) {
      let dl = props.portals.portals.filter((p) => {
        return p.isDealership === true;
      });

      setDealershipList(
        dl.map((d) => {
          return <Select.Option value={d._id}>{d.companyName}</Select.Option>;
        })
      );

      let allRegions = dl.map((d) => {
        if (d.region && d.region._id) {
          return { value: d.region._id, name: d.region.name };
          // return (
          //   <Select.Option value={d.region._id}>{d.region.name}</Select.Option>
          // );
        }
      });
    }

    if (props.portals.regions) {
      setRegionList(
        props.portals.regions.map((r) => {
          return <Select.Option value={r._id}>{r.name}</Select.Option>;
        })
      );
    }
  }, [props.portals]);

  return (
    <div>
      <Helmet>
        <title>Create New Portal</title>
      </Helmet>
      <div className="view-portals view-content-container">
        <Card
          title="Create New Portal"
          bordered={false}
          style={{ width: "100%" }}
        >
          <Form
            form={form}
            layout="horizontal"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 10 }}
            initialValues={{
              m1to250: 650,
              m251to500: 810,
              m501to750: 947,
              m751to1000: 947,
              m1001to1250: 1198,
              m1251to1500: 1300,
              m1501to1750: 1500,
              m1751to2000: 1500,
              m2001to2500: 1650,
              m2501to3000: 1800,
              m3001to3500: 2150,
            }}
            className="portal-form"
            onFinish={onFinish}
          >
            <Divider>Company Details</Divider>
            <Form.Item label="Company Name" name="companyName" required>
              <Input />
            </Form.Item>

            <Form.Item
              label="Dealership"
              name="isDealership"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            {isDealership && (
              <Form.Item label="Parent Dealership" name="parentPortal">
                <Select>
                  <Select.Option value={null}>None</Select.Option>
                  {dealershipList}
                </Select>
              </Form.Item>
            )}

            {isDealership && (
              <Form.Item label="Region" name="region">
                <Select>
                  <Select.Option value={null}>None</Select.Option>
                  {regionList}
                  <Select.Option value="other">Other</Select.Option>
                </Select>
              </Form.Item>
            )}

            {isDealership && region === "other" && (
              <Form.Item label="Region (Other)" name="regionOther">
                <Input />
              </Form.Item>
            )}

            <Form.Item label="Address" name="companyAddress">
              <Input />
            </Form.Item>

            <Form.Item label="City" name="companyCity">
              <Input />
            </Form.Item>
            <Form.Item label="State" name="companyState">
              <Select>{states_list}</Select>
            </Form.Item>
            <Form.Item label="Zip" name="companyZip">
              <Input />
            </Form.Item>
            <Form.Item label="Company Phone" name="companyPhone">
              <MaskedInput mask={"(000) 000-0000"} />
            </Form.Item>
            <Form.Item label="Company Fax" name="companyFax">
              <MaskedInput mask={"(000) 000-0000"} />
            </Form.Item>
            <Divider>Company Contact</Divider>

            <Form.Item label="Contact Full Name" name="contactFullName">
              <Input />
            </Form.Item>
            <Form.Item label="Contact Email" name="contactEmail">
              <Input />
            </Form.Item>
            <Form.Item label="Contact Phone" name="contactPhone">
              <MaskedInput mask={"(000) 000-0000"} />
            </Form.Item>
            <Form.Item label="Contact Mobile" name="contactMobilePhone">
              <MaskedInput mask={"(000) 000-0000"} />
            </Form.Item>
            <Divider>Notification Contacts</Divider>
            <Form.List name="emails">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      key={field.key}
                    >
                      <Form.Item
                        name={[index, "email"]}
                        validateTrigger={["onChange", "onBlur"]}
                        rules={[
                          {
                            type: "email",
                            message: "Please enter a valid email address.",
                          },
                        ]}
                        noStyle
                      >
                        <Input
                          placeholder="Email Address"
                          style={{
                            maxWidth: "300px",
                            marginBottom: "5px",
                          }}
                        />
                      </Form.Item>

                      <Form.Item
                        style={{ marginBottom: "0px" }}
                        name={[index, "emailPickUp"]}
                        valuePropName="checked"
                      >
                        <Checkbox
                          style={{
                            width: "125px",
                            marginBottom: "5px",
                          }}
                        >
                          Pick Up
                        </Checkbox>
                      </Form.Item>

                      <Form.Item
                        name={[index, "emailDelivery"]}
                        valuePropName="checked"
                      >
                        <Checkbox
                          style={{
                            width: "125px",
                            marginBottom: "5px",
                          }}
                        >
                          Delivery
                        </Checkbox>
                      </Form.Item>

                      <MinusCircleFilled
                        onClick={() => remove(field.name)}
                        style={{
                          fontSize: "20px",
                          color: "#00274c",
                        }}
                      />
                    </div>
                  ))}

                  <Form.Item
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Button
                      label="Add Email"
                      style="light"
                      action={() => add()}
                    >
                      <PlusCircleFilled
                        style={{
                          fontSize: "20px",
                          color: "#fff",
                        }}
                      />
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>

            <Divider>Portal Settings</Divider>

            <Form.Item
              label="Discount Amount"
              tooltip="Set by McCollister's - per vehicle, applied to base rate"
              name="discount"
            >
              <InputNumber />
            </Form.Item>

            <Form.Item label="Company Tariff" name="companyTariff">
              <InputNumber />
            </Form.Item>

            <Form.Item
              label="Tariff is Percent"
              name="companyTariffIsPercent"
              tooltip="Disable if company tariff is a fixed dollar amount"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="White Glove Override"
              name="hasWhiteGloveOverride"
              tooltip="Overrides per mile multiplier in Settings"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            {hasWhiteGloveOverride && (
              <Form.Item
                label="White Glove Override Amount"
                tooltip="$ Per Mile"
                name="whiteGloveOverride"
              >
                <InputNumber />
              </Form.Item>
            )}

            <Form.Item
              label="Days Until Quotes Expire"
              name="portalQuoteExpirationDays"
            >
              <InputNumber />
            </Form.Item>

            <Form.Item
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
              name="upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
            >
              <Upload
                beforeUpload={() => false}
                name="logo"
                onPreview={handlePreview}
                onChange={handleUpload}
                listType="picture"
              >
                <Button label="Click to Upload" style="light">
                  <UploadOutlined
                    style={{
                      fontSize: "20px",
                      color: "#fff",
                    }}
                  />
                </Button>
              </Upload>
            </Form.Item>

            <Divider>Portal Display</Divider>

            <Form.Item
              label="McCollister's Logo in PDFs"
              name="displayMCLogo"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Agent Name"
              name="displayAgent"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Billing vs. COD Option"
              name="displayPaymentType"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Commission Per Vehicle"
              name="displayCommissionPerVehicle"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Discount Option"
              name="displayDiscountOption"
              valuePropName="checked"
              tooltip="Set by portal admins"
            >
              <Switch />
            </Form.Item>

            <Form.Item
              label="Portal-Wide Commission"
              name="displayPortalCommission"
              valuePropName="checked"
              tooltip="Set by portal admins"
            >
              <Switch />
            </Form.Item>

            <Divider>Custom Rate Sheet</Divider>

            <Form.Item
              label="Use Custom Rate Sheet"
              name="hasCustomRates"
              valuePropName="checked"
              tooltip="Set by portal admins"
            >
              <Switch />
            </Form.Item>

            {hasCustomRatesOn && (
              <Input.Group className="custom-rate-sheet-fields">
                <Row gutter={0}>
                  <Col span={12} xs={12}>
                    <Form.Item name="m1to250" label="1-250 mi">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={12}>
                    <Form.Item name="m251to500" label="251-500 mi">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={12}>
                    <Form.Item name="m501to750" label="501-750 mi">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={12}>
                    <Form.Item name="m751to1000" label="751-1000 mi">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} xs={12}>
                    <Form.Item name="m1001to1250" label="1001-1250 mi">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={12}>
                    <Form.Item name="m1251to1500" label="1251-1500 mi">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={12}>
                    <Form.Item name="m1501to1750" label="1501-1750 mi">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={12}>
                    <Form.Item name="m1751to2000" label="1751-2000 mi">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} xs={12}>
                    <Form.Item name="m2001to2500" label="2001-2500 mi">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={12}>
                    <Form.Item name="m2501to3000" label="2501-3000 mi">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={12}>
                    <Form.Item name="m3001to3500" label="3001-3500 mi">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={12}>
                    <Form.Item name="suvClassSurcharge" label="SUV Surcharge">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} xs={12}>
                    <Form.Item name="vanClassSurcharge" label="VAN Surcharge">
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={12}>
                    <Form.Item
                      name="pickUp4DoorClassSurcharge"
                      label="PU 4-Door Surcharge"
                    >
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={12}>
                    <Form.Item
                      name="enclosedSurcharge"
                      label="Enclosed Surcharge"
                    >
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={12}>
                    <Form.Item
                      name="enclosedSurchargeOver1500"
                      label="Enclosed Surcharge Over 1500mi"
                    >
                      <InputNumber
                        formatter={(value) =>
                          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        }
                        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} xs={12}>
                    <Form.Item name="fuelSurcharge" label="Fuel Surcharge (%)">
                      <InputNumber />
                    </Form.Item>
                  </Col>
                </Row>
              </Input.Group>
            )}

            <Divider>Reports Available</Divider>
            <Form.Item
              label="Order Volume"
              name="orderVolume"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Average Cost by Distance"
              name="avgCostByDistance"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Average Cost by Dealership"
              name="avgCostByDealership"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button label="Submit" style="dark" type="submit" />

              <Button
                label="Reset"
                style="light"
                type="button"
                action={onReset}
              />
            </div>
          </Form>
        </Card>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    files: state.files,
    portals: state.portals,
  };
};

export default connect(mapStateToProps)(PortalMCAdminForm);
